import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Link,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { headerMenu } from "../../../components/Menu/helper/headerMenu";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const TopTab = () => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const bg = useColorModeValue("light.100", "dark.100");
  const menuBorder = useColorModeValue("gray.300", "dark.100");

  const { colorMode } = useColorMode();
  const location = useLocation();

  return (
    <Flex
      height="44px"
      width="100%"
      wrap="nowrap"
      overflowX="scroll"
      overflowY="hidden"
      bg={bg}
      className="no-scrollbar"
      borderBottom={`1px solid`}
      borderBottomColor={menuBorder}
    >
      {/* {TAB?.map((item) => {
        return (
          <NavLink
            key={item.value}
            to={`/${item.value}`}
            className={({ isActive, isPending }) =>
              isPending
                ? "pending"
                : isActive
                ? "flex min-w-[27%] px-[5px] items-center justify-center text-base cursor-pointer relative font-medium text-base-gray dark:text-main-color common-tab-bottom after:bg-main-color"
                : "flex min-w-[27%] px-[5px] items-center justify-center text-base cursor-pointer relative font-medium text-[#b7b7b7] dark:text-base-white"
            }
          >
            <Text fontSize="md" className="text-overflow">
              {i18n_common(item.label)}
            </Text>
          </NavLink>
        );
      })} */}
      {headerMenu
        ?.filter((item) => !item.hidden)
        ?.map((item) => {
          return (
            <Link
              to={`/${item.link}`}
              as={NavLink}
              _hover={{
                textDecoration: "none",
              }}
            >
              {({ isActive }) => {
                return (
                  <Box
                    display="flex"
                    minW="100px"
                    h="100%"
                    px="10px"
                    alignItems="center"
                    justifyContent="center"
                    cursor={"pointer"}
                    position="relative"
                    fontWeight={isActive ? "medium" : "normal"}
                    color={isActive ? "brand.500" : "gray.400"}
                    className={`${isActive && "common-tab-bottom"}`}
                    _after={
                      isActive && {
                        bg: "brand.500",
                      }
                    }
                  >
                    <Text
                      fontSize="md"
                      className="text-overflow"
                      color={isActive ? "main-color" : "text-gray-400"}
                    >
                      {i18n_common(item.text?.toLowerCase())}
                    </Text>
                  </Box>
                );
              }}
            </Link>
          );
        })}
    </Flex>
  );
};

export default TopTab;
