import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  Heading,
  VStack,
  useColorModeValue,
  Divider,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { appName } from "../../config";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
const TermsAndService = () => {
  return (
    <VStack
      w="full"
      mt="10px"
      rounded="5px"
      px="10px"
      py="16px"
      bg="base.light.white"
      color="base.deep.dark"
      _dark={{ bg: "base.light.dark", color: "base.light.white" }}
      mb="15px"
      spacing="15px"
      align="center"
      fontSize="medium"
    >
      <Flex w="100%" justifyContent="flex-start">
        <Link to={-1}>
          <Icon as={FaArrowLeft} fontSize="2xl" />
        </Link>
      </Flex>
      <Heading
        my="2"
        mt="2"
        color="brand.500"
        fontSize={{ base: "xl", md: "3xl" }}
        fontWeight="semibold"
      >
        Terms And Service
      </Heading>
      <Divider my="10px" />

      <Box
        fontSize="lg"
        w="100%"
        color="special.1"
        borderRadius={`10px`}
        p="15px"
      >
        <Text>
          1. This promotion cannot be claimed at the same time with the other
          promotions.
        </Text>
        <br />
        <Text>
          2. The promotion bonus needs to meet the turn over requirements and
          limit.
        </Text>
        <br />{" "}
        <Text>
          3. This promotion is limited to a single player, single account,
          single contact information, single payment method account, single IP
          address and a single IP device (gadget) to participate in. Multiple
          accounts are forbidden or other methods to defraud bonuses.{" "}
        </Text>
        <br />{" "}
        <Text>
          4. If Player is caught violating this rule, who gains and take
          advantage of the bonus is considered illegal means, {appName} has the
          right to disqualify the player, terminate the account and cashout or
          withdrawal will be forfeited and considered null and void.{" "}
        </Text>
        <br />{" "}
        <Text>
          5. {appName} has the right to adjust the content of the event, and can
          modify and stop this promotion at any time without prior notice.{" "}
        </Text>
        <br />{" "}
        <Text>
          6. Arbitrage betting and Cross Betting are strictly prohibited.{" "}
        </Text>
        <br />{" "}
        <Text>
          7. For players who violate the rules, {appName} has the right to
          refuse to provide any rewards, cancel the distribution of the award
          and claim back the profit generated by the award.{" "}
        </Text>
        <br />{" "}
        <Text>
          8. The final interpretation right of the above offers belongs to{" "}
          {appName}.{" "}
        </Text>
      </Box>
    </VStack>
  );
};

export default TermsAndService;
