import {
  Avatar,
  Button,
  Flex,
  Grid,
  Text,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { appName } from "../../../config";
import TelegramLogin from "../../Singin/components/telegramLogin";
import { useNavigate } from "react-router-dom";
import GoogleLoginV2 from "../../Singin/components/GoogleLogin_v2";

const ProfileCardNotLogin = () => {
  const cardBg = useColorModeValue("light.100", "dark.100");
  const iconBg = useColorModeValue("light.300", "dark.300");
  const textColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();

  return (
    <Flex
      direction={`column`}
      gap="5px"
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      p="10px"
    >
      <Flex gap="10px" mb="5px" justifyContent={`space-between`}>
        <Flex gap="10px">
          <WrapItem h="55px" p="3px" bg={iconBg} borderRadius={`full`}>
            <Avatar
              name="Header Icon"
              src="https://web-res-ccc.afunimg8.com/cdn-cgi/image/format=auto/C02/userinfo/header/h_default.png?t=20230925"
            />
          </WrapItem>
          <Flex direction={`column`} py="3px" justifyContent={`space-between`}>
            <Text
              color={textColor}
              fontWeight={`bold`}
              fontSize="lg"
              as="p"
              my="0"
            >
              Welcome to {appName}
            </Text>
            <Flex gap="3px" alignItems={`center`}>
              <Text color={`special.1`} fontSize="xs" as="p" my="0">
                Please Sign up or Sign in first.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Grid>
        {" "}
        <Button
          onClick={() => {
            navigate("/signin");
          }}
          variant={`brandPrimary`}
        >
          Sign In
        </Button>
      </Grid>
      {/* <Grid
        templateColumns="repeat(2, minmax(0, 1fr))"
        gap="10px"
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        <GoogleLoginV2 />
        <TelegramLogin />
      </Grid> */}
    </Flex>
  );
};

export default ProfileCardNotLogin;
