import React from "react";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { isValidMotionProp, motion } from "framer-motion";
import { openChat } from "../../redux/action/member/memberAction";
import { COMMON_ALT, COMMON_WEB_PATH } from "../../constant";

import { Base64, btoa } from "js-base64";
import ReferQrcode from "../ReferCode/components/referQrcode";
import {
  Box,
  Flex,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import useCheckLogin from "../../hook/useCheckLogin";

const appLink = [
  {
    label: "Line",
    link: "#",
    background: "line-bg-n.png",
    qrcode: "",
  },
  {
    label: "Telegram",
    link: "#",
    background: "telegram-bg-n.png",
    qrcode: "",
  },
  {
    label: "Facebook",
    link: "#",
    background: "fb-bg-n.png",
    qrcode: "",
  },
];

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const ServicePage = () => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const i18n = (key) => t(`service.${key}`);

  const boxBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { isLogin } = useCheckLogin();

  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function decodeBase64(base64Data) {
    try {
      const decodedData = Base64.decode(base64Data);
      return decodedData;
    } catch (error) {
      return null;
    }
  }

  return (
    <CommonOpacityBackground path={-1} className="!items-center">
      <ChakraBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        w={{
          base: "100%",
          md: "650px",
        }}
        h={{
          base: "100%",
          md: "80vh",
        }}
        pb={`20px`}
        overflowY={`scroll`}
        bg={boxBg}
        borderRadius={{
          base: "0",
          md: "10px",
        }}
        px={"10px"}
        className="no-scrollbar"
      >
        <CloseDetailPage
          title={i18n_common("serviceInformation")}
          hasPrev={
            <HiChevronLeft
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer"
            />
          }
          customClose={<p></p>}
        />
        <Box as="section" px="20px">
          <Box
            as="section"
            borderRadius={`30px`}
            px="30px"
            py="20px"
            minH="150px"
            position="relative"
            display="flex"
            justifyContent={"flex-end"}
            alignItems={"center"}
            bgGradient="linear(to-r, brand.300, brand.700)"
          >
            {" "}
            <Image
              position="absolute"
              w="148px"
              bottom="0"
              left={{
                base: "15px",
                md: "35px",
              }}
              src={`${COMMON_WEB_PATH}/service-group/911woman.png`}
            />
            <Flex direction={`column`} gap="20px" alignItems={`center`}>
              <Text
                as="span"
                fontSize="2xl"
                fontWeight={`bold`}
                color="white"
                letterSpacing={`tighter`}
              >
                Online Service
              </Text>
              <Box
                onClick={() => {
                  if (isLogin) {
                    dispatch(openChat());
                  } else {
                    navigate("/signin");
                  }
                }}
                py="5px"
                px="20px"
                cursor={"pointer"}
                borderRadius={`full`}
                boxShadow={`md`}
                color="white"
                fontWeight={`bold`}
                bgGradient="linear(to-r, gray.500, gray.300)"
              >
                CALL NOW
              </Box>
            </Flex>
          </Box>
        </Box>
        {/* <Divider /> */}
        <Box
          color={textColor}
          fontSize="xl"
          fontWeight={`medium`}
          px="20px"
          my="20px"
          textAlign={`start`}
        >
          {i18n("mobileAppService")}
        </Box>
        <Box
          display="grid"
          gap={{
            base: "20px",
            md: "10px",
          }}
          className="grid-cols-2 md:grid-cols-3"
        >
          {appLink.map((item) => {
            const xml = decodeBase64(
              window?.communityInfo?.[item?.label]?.qrcode
            );
            return (
              <Flex
                direction={`column`}
                alignItems={`center`}
                gap="5px"
                color={textColor}
                w="160px"
                h="160px"
                mx="auto"
                position="relative"
                className="bg-cover"
                style={{
                  backgroundImage: `url(${COMMON_WEB_PATH}/service-group/${item.background})`,
                }}
              >
                <Box
                  bg="light.200"
                  position="absolute"
                  top="15px"
                  right="15px"
                  p="10px"
                  borderRadius="15px"
                >
                  <a
                    href={window?.communityInfo?.[item?.label]?.info}
                    target="_blank"
                  >
                    <ReferQrcode
                      className={{
                        w: "50px",
                        h: "50px",
                      }}
                      value={window?.communityInfo?.[item?.label]?.info}
                    />
                  </a>
                </Box>
              </Flex>
            );
          })}
        </Box>
        {/* <Box
          color={textColor}
          fontSize={"xl"}
          fontWeight={"medium"}
          px="20px"
          my="20px"
          textAlign={"start"}
        >
          {i18n("contactInformation")}
        </Box>
        <Box
          gap="20px"
          color={textColor}
          px="20px"
          className="grid grid-cols-1 md:grid-cols-2"
        >
          <Flex
            direction={`column`}
            textAlign={`start`}
            borderLeft={`1px solid`}
            borderColor={`brand.500`}
            pl="10px"
          >
            <Text
              as="p"
              fontWeight={`semibold`}
              color="brand.500"
              fontSize="sm"
              mb="0px"
            >
              {i18n("chineseVoiceService")}
            </Text>
            <Flex direction={`column`} textAlign={`start`}>
              <Text
                as="span"
                fontWeight={`bold`}
                fontSize="xl"
                color="brand.500"
              >
                {window?.communityInfo?.Zalo?.phone
                  ? window?.communityInfo?.Zalo?.phone
                  : "+855 87862496"}
              </Text>
              <Text as="span" fontSize="sm">
                {i18n("hint1")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={`column`}
            alignItems={`start`}
            borderLeft={`1px solid`}
            borderColor={`brand.500`}
            pl="10px"
          >
            <Text
              as="p"
              fontWeight={`semibold`}
              color="brand.500"
              fontSize="sm"
              mb="0px"
            >
              {i18n("emailService")}
            </Text>
            <Flex direction={`column`} textAlign={`start`}>
              <Text
                as="span"
                fontWeight={`bold`}
                fontSize="xl"
                color="brand.500"
              >
                911win6688@gmail.com
              </Text>
              <Text as="span" fontSize="sm">
                {i18n("hint2")}
              </Text>
            </Flex>
          </Flex>
        </Box> */}
      </ChakraBox>
    </CommonOpacityBackground>
  );
};

export default ServicePage;
