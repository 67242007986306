import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Box, Text, Flex, useColorModeValue } from "@chakra-ui/react";

import { useNavigate } from "react-router";
import LazyImage from "../../../components/Loading/lazyImage";
import { VipList } from "../vipList";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { COMMON_WEB_PATH } from "../../../constant";
import useVipInfo from "../../../hook/useVipInfo";

const VipListCard = ({ customNavigate }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const navigate = useNavigate();

  const textColor = useColorModeValue("dark.100", "light.100");
  const bg = useColorModeValue("light.100", "dark.100");

  const vipList = useSelector((state) => state.isVipDetail);
  const memberData = useSelector((state) => state.isMemberInfo);
  const { vipInfo } = memberData;

  const splideRef = useRef();

  const { current_wager, current_level } = useVipInfo();

  const slideToNext = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex + 1;

      splide.go(newIndex);
    }
  };
  const slideToBack = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex - 1;

      splide.go(newIndex);
    }
  };

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[currentDate.getMonth()];
  //獲取當月月份，把他轉成英文

  return (
    <Flex direction="column" gap={4}>
      <Flex justify="between" align="center" gap={2}>
        <Text fontSize="lg" fontWeight={`medium`} color={textColor}>
          {i18n(currentMonth)} {i18n("progress")}
        </Text>
        <Flex gap={4} align="center">
          <Box
            bg={bg}
            w="20px"
            h="20px"
            borderRadius={`common`}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={textColor}
            cursor="pointer"
            fontSize="lg"
            onClick={() => {
              slideToBack();
            }}
          >
            <IoIosArrowBack />
          </Box>
          <Box
            onClick={() => {
              slideToNext();
            }}
            bg={bg}
            w="20px"
            h="20px"
            borderRadius={`common`}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={textColor}
            cursor="pointer"
            fontSize="lg"
          >
            <IoIosArrowForward />
          </Box>
        </Flex>
      </Flex>
      <Box as="section">
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            perPage: 3,
            perMove: 1,
            gap: 15,
            lazyLoad: "nearby",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
          }}
        >
          {vipList.map((vip, index) => {
            return (
              <SplideSlide>
                <Box
                  onClick={() => {
                    if (customNavigate) {
                      navigate(`${customNavigate}/${vip?.level}`);
                    } else {
                      navigate(`${vip?.level}`);
                    }
                  }}
                  bg={bg}
                  borderRadius={`common`}
                  display={"flex"}
                  flexDirection={`column`}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={textColor}
                  cursor="pointer"
                  fontSize="lg"
                  py="10px"
                  px="6px"
                  gap="4px"
                >
                  <LazyImage
                    className="w-full !h-[92px] object-scale-down"
                    src={`${COMMON_WEB_PATH}/vip/vip${vip.level}_l.avif`}
                    loadingClassName={`!rounded-full`}
                  />
                  {/* <span className="text-xl font-semibold text-transaction-card-text dark:text-base-light-white text-lg">
                    {i18n(vip.label.toLowerCase())}
                  </span> */}
                  <Text
                    as="span"
                    fontSize="sm"
                    color={textColor}
                    letterSpacing={`tighter`}
                    className=""
                  >
                    {" "}
                    {current_level >= index
                      ? i18n("completed")
                      : i18n("inProgress")}
                  </Text>
                </Box>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </Flex>
  );
};

export default VipListCard;
