import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import { useEffect, useMemo, useState } from "react";
import DateSelect from "../../components/TimePicker/DateSelect";
import NotFound from "../../components/ProgressBlock/NotFound";
import DetailWrapper from "../../components/detailWrapper";
import { formatTime, getToday } from "../../utils/getDay";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import RewardList from "./components/rewardList";
import { COMMON_WEB_PATH } from "../../constant";
import { formatNumber } from "../../utils/formatNumber";
import { HiChevronRight } from "react-icons/hi";
import BonusWallet from "./components/bonusWallet";
import useBonusAmount from "../../hook/useBonusAmount";
import FreezeWrapper from "../StatusModal/FreezeWrapper";

const SimpleRewards = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`reward.${key}`);

  const textColor = useColorModeValue("dark.100", "light.100");
  const rewardBg = useColorModeValue("light.100", "dark.100");

  const bonus_amount = useBonusAmount();

  const TAB = [
    { label: i18n("unUse"), value: "unUse" },
    { label: i18n("used"), value: "used" },
  ];

  const [tab, setTab] = useState(TAB[0].value);
  const [datePicker, setDatePicker] = useState({
    str_date: getToday(),
    end_date: getToday(),
  });
  const [openBonusWallet, setOpenBonusWallet] = useState(false);

  const globalTrigger = useSelector((state) => state.isGlobalTrigger);

  return (
    <>
      <DetailWrapper>
        <CloseDetailPage title={i18n("myReward")} />
        <Box
          py="5px"
          px="common-x"
          mb="10px"
          bg={rewardBg}
          borderRadius={`common`}
        >
          <Flex alignItems={`center`} justifyContent={`space-between`}>
            <Flex gap="3px" alignItems={`center`}>
              <Image
                w="34px"
                src={`${COMMON_WEB_PATH}/reward_icon/bonus_icon.avif`}
              />
              <Text color={textColor} fontWeight={`bold`}>
                {i18n("BONUS")}
              </Text>
            </Flex>
            <Flex gap="3px" alignItems={`center`}>
              <Text fontWeight={`semibold`}>{formatNumber(bonus_amount)}</Text>
              <Icon
                onClick={() => {
                  setOpenBonusWallet(true);
                }}
                cursor="pointer"
                color="special.1"
                as={HiChevronRight}
              />
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Text
            as="p"
            mb="10px"
            fontWeight={`bold`}
            fontSize="md"
            color={textColor}
          >
            {i18n("General bonus")}
          </Text>
          <FreezeWrapper>
            <RewardList datePicker={datePicker} />
          </FreezeWrapper>
        </Box>
      </DetailWrapper>
      {openBonusWallet && (
        <BonusWallet
          isOpen={openBonusWallet}
          onClose={() => {
            setOpenBonusWallet(false);
          }}
        />
      )}
    </>
  );
};

export default SimpleRewards;
