import React, { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import LoadingBlock from "../components/Loading/LoadingBlock";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import LoaderGameList from "../components/Loading/LoadingGameList";

const ObserveInView = ({ children, observeFalse, props }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "0px 0px 0px 0px",
  });
  const { itemClass, swiperClassName } = props;

  const { customPerView = "normal-layout" } = props.swiperProps || {};

  const customLayoutView = useMemo(() => {
    switch (customPerView) {
      case "normal-layout":
        return {
          xs: 3,
          md: 5,
          lg: 5,
          xl: 7,
        };
      case "new-game-layout":
        return {
          xs: 3,
          md: 5,
          lg: 5,
          xl: 6,
        };
      case "live-layout":
        return {
          xs: 1,
          md: 3,
          lg: 3,
          xl: 3,
        };
      case "promotion-layout":
        return {
          xs: 1,
          md: 2,
          lg: 3,
          xl: 3,
        };
      case "confirm-layout":
        return {
          xs: 3,
          md: 3,
          lg: 3,
          xl: 3,
        };
      case "game-box-layout":
        return {
          xs: 3,
          md: 5,
          lg: 5,
          xl: 8,
        };

      default:
        return {
          xs: 3,
          md: 5,
          lg: 5,
          xl: 7,
        };
    }
  }, [customPerView]);

  return (
    <div ref={ref}>
      {observeFalse ? (
        children
      ) : inView ? (
        children
      ) : (
        <Splide
          ref={ref}
          className={` h-full w-full ${swiperClassName}`}
          options={{
            type: "slide",
            pagination: false,
            arrows: false,
            drag: "free",
            perPage: customLayoutView?.xl,
            perMove: 1,
            gap: 10,
            lazyLoad: "nearby",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            preloadPages: 2,
            keyboard: false,
            fixedWidth: customPerView === "live-layout" ? "30%" : false,
            breakpoints: {
              640: {
                perPage: customLayoutView?.xs,
                grid: {
                  dimensions: [[2, 2]],
                  gap: {
                    row: 10,
                    col: 10,
                  },
                },
                fixedWidth: false,
              },
              768: {
                perPage: customLayoutView?.md,
              },
              1024: {
                perPage: customLayoutView?.lg,
              },
              1280: {
                perPage: customLayoutView?.xl,
              },
            },
          }}
        >
          {Array.from({ length: 8 }, (_, i) => (
            <SplideSlide key={`loading-${i}`}>
              <LoaderGameList
                commonLoading
                // className={`${itemClass} lg:h-[185px] md:h-[160px] h-[130px]`}
              />
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};

export default ObserveInView;
