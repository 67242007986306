import { useMemo } from "react";
import { useSelector } from "react-redux";

const UseGetGameBoxBackPath = () => {
  const gameConfig = useSelector((state) => state.isGameConfig);

  const { game_type } = gameConfig;

  const gameBoxBackPath = useMemo(() => {
    if (game_type === "LIVE") {
      return "live-casino";
    } else if (
      game_type === "SLOT" ||
      game_type === "FH" ||
      game_type === "TABLE" ||
      game_type === "EGAME" ||
      game_type === "BLOCKCHAIN"
    ) {
      return "casino";
    } else if (game_type === "SPORT") {
      return "sports";
    } else if (game_type === "LOTTERY") {
      return "lottery";
    } else if (game_type === "BINGO") {
      return "bingo";
    } else {
      return "casino";
    }
  }, [gameConfig]);
  return gameBoxBackPath;
};

export default UseGetGameBoxBackPath;
