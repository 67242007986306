import React, { Fragment, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../../components/Carousel";
import GameCatalog from "../../components/GameCatalog";
import { useTranslation } from "react-i18next";
import SupplierList from "../../components/GameSelect/SupplierList";
import { useLocation, useNavigation } from "react-router";
import MobileSearch from "../Search/mobile-search";
import useSubTypeList from "../../hook/useSubTypeList";
import { filterType } from "../../hook/useGameListFilter";
import useCasinoType from "../../hook/useCasinoType";
import UserClickGameCatalog from "../../components/UserClickGameCatalog";
import MarqueeList from "../../components/marquee/marqueeList";
import LobbyGame from "../Live/components/lobbyGame";
import useGetGamePlatform from "../../hook/useGetGamePlatform";
import Ranking from "../../components/Ranking/Ranking";
import { CURRENCY_MENU } from "../../i18n/config";
import { Box } from "@chakra-ui/react";
import useCurrentCurrencyIsCrypto from "../../hook/useCurrentCurrencyIsCrypto";
import TopTab from "../Home/components/topTab";
import { Helmet } from "react-helmet";
import Seo from "./seo";
import EventCarousel from "../Home/components/event_carousel";
import useCheckLogin from "../../hook/useCheckLogin";

const Slot = ({}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const platformList = useGetGamePlatform();

  const subList = useSubTypeList();

  const { isLogin } = useCheckLogin();

  const casinoType = useCasinoType();

  const dispatch = useDispatch();
  const handleAllGame = useSelector((state) => state.isHandleAllGame); //redux純取值用useSelector就好 不用dispatch
  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  //把Redux store 中的 isHandleAllGame 属性，儲存在 handleAllGame 變量中
  const currentCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isNowCurrency的值 用來判斷幣別

  const {
    platform,
    topGameList,
    newGameList,
    categorizedGames,
    exclusiveBrandGames,
  } = handleAllGame; //從handleAllGame中提取了 platform、gameList 和 gameType 属性。可以在组件中直接使用这些属性，而不必每次都通过handleAllGame 来訪問它们。

  const location = useLocation();
  const { pathname } = location;

  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();

  const topGames = filterType({
    gameList: topGameList,
    casinoType: casinoType,
  });

  const newGames = filterType({
    gameList: newGameList,
    casinoType: casinoType,
  })?.reverse();

  const lobbyGames = useMemo(() => {
    return filterType({
      gameList: categorizedGames?.Lobby,
      casinoType: "live-casino",
    }); //從redux取categorizedGames裡面的Lobby 記得問號防呆
  }, [categorizedGames]);

  const subGames = useMemo(() => {
    return subList
      ?.filter((item) => item !== "MyFavorite" && item !== "ClickGames")
      ?.map((subType, index) => {
        const subGamelist = filterType({
          gameList: categorizedGames?.[subType],
          casinoType: casinoType,
        });

        return (
          subGamelist?.length > 10 && (
            <Fragment key={subType}>
              <GameCatalog
                list={subGamelist}
                wrapperClassName={subGamelist?.length === 0 && "hidden"}
                mainTitle={subType}
              />
            </Fragment>
          )
        );
      });
  }, [categorizedGames, subList, currentCurrency, currentCurrencyIsCrypto]);

  return (
    <Fragment>
      <Seo />
      <Box as="section" display={{ md: "none", base: "block" }}>
        <TopTab />
      </Box>
      <Box as="section" py="common-y" px="common-y">
        <Carousel />
      </Box>
      <Box as="section" pb={{ md: "0", base: "common-y" }} px="common-y">
        <MarqueeList />
      </Box>
      <Box as="section" display={{ md: "none", base: "block" }}>
        <MobileSearch />
      </Box>
      <Box>
        <GameCatalog
          list={topGames}
          wrapperClassName={topGames?.length === 0 && "hidden"}
          mainTitle={"Top"}
          itemClass="2xl:h-[200px] xl:h-[180px]"
          swiperProps={{
            customPerView: "popular-layout",
          }}
          observeFalse
        />
        {isLogin ? (
          <>
            <UserClickGameCatalog />
          </>
        ) : null}
        <GameCatalog
          list={newGames}
          wrapperClassName={newGames?.length === 0 && "hidden"}
          mainTitle={"News"}
          itemClass="2xl:h-[215px] xl:h-[265px] h-[180px]"
          swiperProps={{
            customPerView: "new-game-layout",
          }}
          observeFalse
        />
        <Box as="section" px="common-x" mb="20px">
          <SupplierList
            LIST={platformList}
            customNavigate="/casino/category/"
          />
        </Box>
        <EventCarousel />

        {/* {loadingStatus ? (
          <LoadingForLiveLayout itemClass="md:!h-[300px] h-[180px]" />
        ) : (
          <GameCatalog
            list={lobbyGames}
            mainTitle={"Lobby"}
            itemClass="md:!h-[300px] h-[180px]"
            swiperProps={{
              delay: 1,
              speed: 25000,
              swiperClassName: "custom-swiper-wrapper",
              customPerView: "live-layout",
            }}
            customGameList={<LobbyGame />}
            //lobbyGames裡的WM EVO會寫死是因為他們不是真的大廳遊戲 底下都有很多遊戲
            //如果真人類別的遊戲全部打開 這兩隻也不會出現 需要額外寫出來 所以即使真人裡面沒有遊戲 這兩隻還是會顯示
          />
        )} */}
        <GameCatalog
          list={exclusiveBrandGames}
          wrapperClassName={exclusiveBrandGames?.length === 0 && "hidden"}
          mainTitle={"ExclusiveGame"}
          itemClass="xl:!h-[250px]"
          swiperProps={{
            customPerView: "new-game-layout",
          }}
        />
      </Box>
      <Box>{subGames}</Box>
      {/* <Ranking /> */}
    </Fragment>
  );
};

export default React.memo(Slot);
