import { FaChess, FaChessBoard, FaRocket } from "react-icons/fa";
import { FaFishFins } from "react-icons/fa6";
import { GiEightBall } from "react-icons/gi";
import { IoGameController } from "react-icons/io5";
import { MdCasino, MdSportsSoccer } from "react-icons/md";
import { PiNumberSquareSevenFill } from "react-icons/pi";

export const menuIconV2 = (casino) => {
  switch (casino) {
    case "slots":
    case "SLOT":
      return {
        icon: PiNumberSquareSevenFill,
      };
    case "fish":
    case "FH":
      return {
        icon: FaFishFins,
      };
    case "table":
    case "TABLE":
      return {
        icon: FaChess,
      };
    case "block-chain":
    case "BLOCKCHAIN":
      return {
        icon: FaRocket,
      };
    case "arcade":
    case "EGAME":
      return {
        icon: IoGameController,
      };
    case "sports":
    case "SPORT":
      return {
        icon: MdSportsSoccer,
      };
    case "live-casino":
    case "LIVE":
      return {
        icon: MdCasino,
      };
    case "bingo":
    case "BINGO":
      return {
        icon: GiEightBall,
      };
    default:
      return {
        icon: null,
      };
  }
};
