import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ResultPage from "../../../components/ResultPage/ResultPage";

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const navigate = useNavigate();
  return (
    <ResultPage
      buttonProps={{
        content: i18n_common("backToHome"),
        path: "/",
      }}
      resultContent={`${i18n("youCanGo")} ${i18n("resetPasswordRoute")}，${i18n(
        "change"
      )}
          ${i18n("bindData")}`}
      resultText={i18n("resetPasswordSuccess")}
      pageType="success"
      pageTitle={i18n("resetPasswordSuccess")}
    />
  );
};

export default ResetPasswordSuccess;
