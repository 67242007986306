import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useCookiesAgentName = () => {
  const [cookiesFromAgent, setCookiesFromAgent] = useState(
    Cookies.get("ag") || ""
  );
  let [searchParams, setSearchParams] = useSearchParams();

  const searchAgent = searchParams.get("ag") || "";

  useEffect(() => {
    if (searchAgent) {
      setCookiesFromAgent(searchAgent);
    }
  }, [searchAgent]);

  return cookiesFromAgent;
};

export default useCookiesAgentName;
