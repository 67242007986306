import { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { useTranslation } from "react-i18next";
import { formatTime, getToday, getYesterday } from "../../utils/getDay";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import {
  startLoading,
  stopLoading,
} from "../../redux/action/loading/loadingAction";
import { formatNumber } from "../../utils/formatNumber";
import { capitalizeFirstLetter } from "../../replace_str_constant";
import DateSelect from "../../components/TimePicker/DateSelect";
import NotFound from "../../components/ProgressBlock/NotFound";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import DetailWrapper from "../../components/detailWrapper";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import { getTransactionRecord } from "../../api/getApi";
import LoadingBetRecord from "../../components/Loading/LoadingBetRecord";
import { Box, Divider, Flex } from "@chakra-ui/react";
import DepositTransaction from "./components/depositTransaction";
import WithdrawalTransaction from "./components/withdrawalTransaction";
import EventTransaction from "./components/eventTransaction";
import { useSearchParams } from "react-router-dom";
import SendPointsTransaction from "./components/sendPointsTransaction";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const Transaction = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);

  const TAB = [
    { label: i18n("deposit"), value: "deposit" },
    { label: i18n("withdraw"), value: "withdraw", hidden: isCredit },
    { label: i18n("event"), value: "event" },
    { label: i18n("send-point"), value: "send-point" },
  ];

  const [datePicker, setDatePicker] = useState({
    str_date: getYesterday(),
    end_date: getToday(),
  });

  const scrollContainerRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || 1;
  const tab = searchParams.get("tab")
    ? searchParams.get("tab")
    : isCredit
    ? "send-point"
    : "deposit";

  const tabComponents = useMemo(() => {
    switch (tab) {
      case "deposit":
        return <DepositTransaction datePicker={datePicker} />;
      case "withdraw":
        return <WithdrawalTransaction datePicker={datePicker} />;
      case "event":
        return <EventTransaction datePicker={datePicker} />;
      case "send-point":
        return <SendPointsTransaction datePicker={datePicker} />;
      default:
        return <></>;
    }
  }, [tab, datePicker]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        scrollContainerRef.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [page]);

  return (
    <DetailWrapper closeAnimate>
      <Box ref={scrollContainerRef}>
        <CloseDetailPage title={i18n("title")} />
        <Box as="section" py="common-y">
          <Flex alignItems={`center`} mb="10px">
            <ButtonTab TABS={TAB} tab={tab} />
          </Flex>
          <Divider my="10px" />
          <Box as="section" mb="10px">
            <DateSelect trigger={tab} setDatePicker={setDatePicker} />
          </Box>
          <Box as="section">{tabComponents}</Box>
        </Box>
      </Box>
    </DetailWrapper>
  );
};

export default Transaction;
