import { useMemo } from "react";
import { useSelector } from "react-redux";
import { routeMapping } from "../components/v2Layout/GameTypeSelector/utils/routeMapping";

const UseGetGameBoxBackPathV2 = () => {
  const gameConfig = useSelector((state) => state.isGameConfig);

  const { game_type } = gameConfig;

  const gameBoxBackPath = useMemo(() => {
    return routeMapping(game_type)?.link;
  }, [gameConfig]);
  return gameBoxBackPath;
};

export default UseGetGameBoxBackPathV2;
