import React, { useMemo } from "react";
import MenuV2 from "./Menu_v2";
import Menu from "./Menu";
import { WEB_LAYOUT } from "../../constant";

const MenuVersionSelector = () => {
  const menu = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <MenuV2 />;
      case "v3":
        return <MenuV2 />;
      default:
        return <Menu />;
    }
  }, [WEB_LAYOUT]);
  return menu;
};

export default MenuVersionSelector;
