import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { actionSignIn, actionSignUp } from "../../api/postApi";
import { waitTime } from "../../utils/waitTime";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaMobileAlt, FaUserFriends } from "react-icons/fa";
import { RiUser3Fill, RiUserSmileFill } from "react-icons/ri";
import { BiKey } from "react-icons/bi";
import { MdMail } from "react-icons/md";
import GoogleLoginButton from "../Singin/components/googleLogin";
import TelegramLogin from "../Singin/components/telegramLogin";
import CustomInput from "../../components/InputComponents/customInput";
import {
  accountExpressForInput,
  cantSpecialSymbol,
  disabledSpaceForInput,
  passwordExpressForInput,
  phoneExpressForInput,
} from "../../regex_constanst";
import GetParams from "../../utils/getParams";
import { api } from "../../api/baseApi";
import { notice } from "../../redux/action/action";
import { TbPassword } from "react-icons/tb";
import GoogleLoginV2 from "../Singin/components/GoogleLogin_v2";
import { CgPassword } from "react-icons/cg";
import useGetAgentName from "../../hook/useGetAgentName";

const iconStyle = "text-gray-300";

const SignupForm = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`header.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_common_form = (key) => t(`settings.form.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const toast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const agent_name = useGetAgentName();

  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    real_name: "",
    mobile: "",
    email: "",
    verifiedCode: "",
    code: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSendStatus, setEmailSendStatus] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const { user_name, password, real_name, mobile, email, verifiedCode, code } =
    formData;

  const unlimit_code = GetParams("uac");
  const invite = Cookies.get("invite");

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSeePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleVerifyEmail = async () => {
    setEmailLoading(true);
    await api
      .post("/getCode", {
        email: email,
      })
      .then((data) => {
        setSeconds(60);
        setEmailSendStatus(true);
        dispatch(
          notice({
            title: data?.message,
            type: "success",
          })
        );
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const open_registration = window?.open_registration == 1;
    if (!open_registration) {
      dispatch(
        notice({
          title: i18n("registration_closed"),
          type: "error",
        })
      );
      return;
    }

    setButtonLoading(true);

    await actionSignUp({
      memId: user_name,
      passwd: password,
      true_name: real_name,
      mobile: mobile,
      email: email,
      unlimit_code: unlimit_code,
      invite: invite,
      code: code,
    })
      .then((data) => {
        Cookies.remove("token");
        toast({
          title: i18n_backend_response("Sign up success"),
          description: "We will login for you now, please wait a moment.",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
      })
      .finally(() => {
        setButtonLoading(false);
      });

    await actionSignIn({
      account: user_name,
      passwd: password,
    })
      .then((data) => {
        if (Cookies.get("token")) {
          Cookies.remove("token");
        }
        Cookies.set("token", data.token, { expires: 3 });

        dispatch({
          type: "INITIALIZE_API",
          navigate: navigate,
        });

        navigate("/");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={`7px`}>
        <FormControl isRequired>
          <FormLabel>{i18n_common_form("account")}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <RiUser3Fill className={iconStyle} />
            </InputLeftElement>
            <Input
              type="text"
              variant={`brandPrimary`}
              name="user_name"
              value={user_name}
              onChange={handleInputChange}
              placeholder={i18n_formHint("includeSixEnglishAndNumber")}
              title={i18n_formHint("includeSixEnglishAndNumber")}
              pattern={accountExpressForInput}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{i18n_common_form("password")}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiKey className={iconStyle} />
            </InputLeftElement>
            <Input
              type={showPassword ? "text" : "password"}
              variant={`brandPrimary`}
              name="password"
              value={password}
              onChange={handleInputChange}
              placeholder={i18n_formHint("includeEightEnglishAndNumber")}
              title={i18n_formHint("includeEightEnglishAndNumber")}
              pattern={passwordExpressForInput}
            />
            <InputRightElement>
              {showPassword ? (
                <FaEyeSlash
                  className="cursor-pointer"
                  onClick={handleSeePassword}
                />
              ) : (
                <FaEye className="cursor-pointer" onClick={handleSeePassword} />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {unlimit_code && (
          <FormControl>
            <FormLabel>{i18n_common_form("refer_code")}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaUserFriends className={iconStyle} />
              </InputLeftElement>
              <Input
                type={`text`}
                variant={`brandPrimary`}
                name="unlimit_code"
                isDisabled={true}
                value={unlimit_code}
              />
            </InputGroup>
          </FormControl>
        )}{" "}
        {window?.email_verification == 1 ? (
          <Flex gap="10px" w="100%" alignItems={`flex-end`}>
            <FormControl isRequired>
              <FormLabel>{i18n_common_form("email")}</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdMail className={iconStyle} />
                </InputLeftElement>
                <Input
                  type="email"
                  variant={`brandPrimary`}
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder={`${i18n_common(
                    "pleaseInput"
                  )} ${i18n_common_form("email")}`}
                />
              </InputGroup>
            </FormControl>
            <Button
              type="button"
              onClick={() => {
                handleVerifyEmail();
              }}
              isDisabled={emailLoading || seconds > 0}
              isLoading={emailLoading}
            >
              {emailSendStatus ? seconds : i18n_formHint("verify")}
            </Button>
          </Flex>
        ) : null}
        {emailSendStatus && (
          <FormControl isRequired>
            <FormLabel>{i18n_formHint("verify_code")}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CgPassword className={iconStyle} />
              </InputLeftElement>
              <Input
                type="text"
                variant={`brandPrimary`}
                name="code"
                value={code}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>
        )}
        {/* {agent_name && (
          <FormControl>
            <FormLabel>{i18n_common_form("agent_refer_code")}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaUserFriends className={iconStyle} />
              </InputLeftElement>
              <Input
                type={`text`}
                variant={`brandPrimary`}
                name="agent_name"
                isDisabled={true}
                value={agent_name}
              />
            </InputGroup>
          </FormControl>
        )} */}
        {invite && (
          <Flex gap="10px" w="100%" mt="10px" justifyContent={`flex-start`}>
            <Text fontSize={`sm`} fontWeight={`semibold`} color="brand.500">
              {i18n_common_form("agent_refer_code")}：{invite}
            </Text>
          </Flex>
        )}
        <Divider my="10px" />
        <Flex direction="column" gap="15px" w="100%">
          <Button
            variant={`brandPrimary`}
            isLoading={buttonLoading}
            disabled={buttonLoading}
            py="20px"
            type="submit"
            w="100%"
          >
            {i18n("signup")}
          </Button>
          {/* <GoogleLoginButton
            setButtonLoading={setButtonLoading}
            buttonLoading={buttonLoading}
          /> */}
          {/* <GoogleLoginV2 />
          <TelegramLogin
            setButtonLoading={setButtonLoading}
            buttonLoading={buttonLoading}
          /> */}
        </Flex>
      </VStack>
    </form>
  );
};

export default SignupForm;
