import { Fragment, useCallback, useMemo } from "react";
import MobileSearch from "../Search/mobile-search";
import TopTab from "../Home/components/topTab";
import { useSelector } from "react-redux";
import GameCatalog from "../../components/GameCatalog";
import useCasinoType from "../../hook/useCasinoType";
import { filterType } from "../../hook/useGameListFilter";
import LoadingForLiveLayout from "../../components/Loading/LoadingForLiveLayout";
import useGetGamePlatform from "../../hook/useGetGamePlatform";
import SupplierList from "../../components/GameSelect/SupplierList";
import { Box } from "@chakra-ui/react";

const Lottery = () => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  const { allGames, categorizedGames } = isHandleAllGame;

  const casinoType = useCasinoType();

  const lotteryGameList = useMemo(() => {
    return filterType({
      gameList: allGames,
      casinoType: casinoType,
    });
  }, [allGames]);

  const platformList = useGetGamePlatform(); //調用 useGetGamePlatform 這個 Hook，並將返回的值賦值給 platformList

  return (
    <Fragment>
      <Box
        display={{
          base: "block",
          md: "none",
        }}
      >
        <TopTab />
      </Box>
      <Box py="common-y"></Box>

      <Box
        display={{
          base: "block",
          md: "none",
        }}
      >
        <MobileSearch />
      </Box>
      <Box>
        {loadingStatus ? (
          <LoadingForLiveLayout itemClass="md:!h-[300px] h-[180px]" />
        ) : (
          <GameCatalog
            hiddenButtonNavigator
            mainTitle={"Lottery"}
            list={lotteryGameList}
            itemClass="md:!h-[300px] h-[180px]"
            swiperProps={{
              customPerView: "live-layout",
            }}
          />
        )}
        <Box px="common-x" mb="20px">
          <SupplierList
            LIST={platformList}
            customNavigate="/lottery/category/"
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Lottery;
