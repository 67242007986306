import { Box, chakra, shouldForwardProp } from "@chakra-ui/react";
import React from "react";
import Jackpot from "./Jackpot";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { useParams } from "react-router-dom";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const JackpotWrapper = ({ gameMainTypeFromProps }) => {
  const { gameMainType } = useParams();
  return (
    <ChakraBox
      key={gameMainTypeFromProps || gameMainType}
      {...gameListFromBottom}
    >
      <Box
        display="flex"
        w="100%"
        alignItems={`center`}
        justifyContent={`center`}
      >
        <Jackpot />
      </Box>
    </ChakraBox>
  );
};

export default JackpotWrapper;
