import React, { useMemo, useState } from "react";
import { GoSearch } from "react-icons/go";
import { IoAddOutline, IoCloseSharp } from "react-icons/io5";
import { RiHomeSmileFill } from "react-icons/ri";
import useSubTypeList from "../../../hook/useSubTypeList";
import CommonTab from "../../../components/TabComponent/CommonTab";
import GameCatalog from "../../../components/GameCatalog";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import VipBar from "./vipBar";
import { IoLogoGameControllerB } from "react-icons/io";
import ToggleFavorite from "../../../components/Favorite/toggleFavorite";
import UseGetGameBoxBackPath from "../../../hook/useGetGameBoxBackPath";
import { FaHeadphonesAlt, FaScroll } from "react-icons/fa";
import {
  openChat,
  openGameDescriptionPop,
} from "../../../redux/action/member/memberAction";
import {
  closeAccessTool,
  openAccessTool,
} from "../../../redux/action/game/gameAction";
import useGameIsHasDescription from "../gameDescription/gameIsHasDescription";
import useVipInfo from "../../../hook/useVipInfo";

const iconStyle =
  "min-w-[44px] min-h-[44px] rounded-full active:scale-[0.95] bg-[#DDE0E3] dark:bg-base-light-dark text-base-dark dark:text-base-white text-2xl flex items-center justify-center";

const GameBoxMore = ({ setShowGameBoxMore }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const subList = useSubTypeList();

  const [tab, setTab] = useState("AllGames");
  const [headerTab, setHeaderTab] = useState("Vip");

  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const {
    topGameList = [],
    newGameList = [],
    allGames = [],
    categorizedGames,
  } = handleAllGame;

  const gameListFilter = useMemo(() => {
    switch (tab) {
      case "News":
        return [...newGameList, ...allGames];
      case "Top":
        return [...topGameList, ...allGames];
      case "Home":
        return allGames;
      case "AllGames":
        return allGames;
      default:
        return categorizedGames?.[tab]?.length > 0
          ? categorizedGames?.[tab]
          : allGames;
    }
  }, [tab, handleAllGame]);

  const getPrevPath = UseGetGameBoxBackPath();

  const gameConfig = useSelector((state) => state.isGameConfig);

  const { game_platform, gameCode, game_subtype } = gameConfig;

  const gameIsHasDescription = useGameIsHasDescription({ game_platform });

  const { to } = useParams();

  const gameName = useMemo(() => {
    return gameConfig?.[
      `gameName${languageTransfer({ lang: i18next.language })}`
    ];
  }, [gameConfig, i18next.language]);

  const dispatch = useDispatch();

  const vipConfig = useVipInfo();
  const vipList = useSelector((state) => state.isVipDetail);
  const { current_level } = vipConfig;

  return (
    <main className="fixed top-0 left-0 w-full h-full flex flex-col justify-between overflow-y-scroll no-scrollbar">
      <div
        onClick={() => {
          dispatch(closeAccessTool());
        }}
        className="fixed top-0 left-0 w-full h-full opacity-[.3] z-[-2] bg-[#f1f2f4]"
      ></div>
      <section>
        <div className="flex items-center justify-between py-[10px] bg-base-white dark:bg-base-dark">
          <div className="flex items-center gap-[15px]">
            <div
              onClick={() => {
                dispatch(closeAccessTool());
              }}
              className="w-[36px] h-[44px] rounded-r-[5px] bg-[#DDE0E3] dark:text-base-light-white dark:bg-base-light-dark text-2xl flex items-center justify-center"
            >
              <IoCloseSharp className="" />
            </div>
            <section className="flex items-center gap-[15px] w-[250px] overflow-x-scroll no-scrollbar">
              <Link
                to={`/${getPrevPath}/category/${to}/all`}
                className={`${iconStyle}`}
              >
                <RiHomeSmileFill className="" />
              </Link>
              <div
                onClick={() => {
                  setHeaderTab("Vip");
                }}
                className={`${iconStyle} ${
                  headerTab === "Vip" && "border border-main-color"
                }`}
              >
                <img
                  alt={`vipIcon`}
                  src={vipList?.[current_level]?.icon}
                  className="w-[32px] h-[32px]"
                />
              </div>
              {/* <div
                onClick={() => {
                  setHeaderTab("Games");
                }}
                className={`${iconStyle} ${
                  headerTab === "Games" && "border border-main-color"
                }`}
              >
                <IoLogoGameControllerB />
              </div>
              <div
                onClick={() => {
                  dispatch(openChat());
                }}
                className={`${iconStyle}`}
              >
                <FaHeadphonesAlt />
              </div>

              <Link to="search" className={`${iconStyle}`}>
                <GoSearch className="" />
              </Link> */}
            </section>
          </div>
          <div className="flex items-center pr-[10px]">
            <Link
              to="profile/deposit"
              className="w-[44px] h-[44px] rounded-full text-white bg-green-500 text-2xl flex items-center justify-center"
            >
              <IoAddOutline className="" />
            </Link>
          </div>
        </div>
        {headerTab === "Games" && (
          <div className="py-[5px] bg-base-white dark:bg-base-dark">
            <div className="flex gap-[20px] overflow-y-hidden overflow-x-scroll no-scrollbar px-[10px]">
              <CommonTab
                textClassName={`!pb-[8px] !text-base`}
                setTab={setTab}
                tabList={subList}
              />
            </div>
            <div className="min-h-[215px]">
              <GameCatalog hiddenHeader list={gameListFilter} />
            </div>
          </div>
        )}
        {headerTab === "Vip" && <VipBar />}
      </section>
      <section>
        <div className="bg-base-white dark:bg-base-dark p-[18px] flex items-center justify-between ">
          <div className="flex flex-col justify-between">
            <span className="text-transaction-text dark:text-base-light-white font-semibold text-xl">
              {gameName}
            </span>
            <span className="text-light-text font-medium">
              {" "}
              {game_platform}-{i18n(game_subtype)}
            </span>
          </div>
          <section className="flex items-center gap-[10px]">
            {gameIsHasDescription && (
              <div className="w-[44px] h-[44px] rounded-full bg-[#DDE0E3] flex items-center justify-center text-xl">
                <FaScroll
                  onClick={() => {
                    dispatch(closeAccessTool());
                    dispatch(
                      openGameDescriptionPop({
                        game: game_platform,
                      })
                    );
                  }}
                />
              </div>
            )}
            <div className="w-[44px] h-[44px] rounded-full bg-[#DDE0E3] flex items-center justify-center">
              <ToggleFavorite />
            </div>
          </section>
        </div>
      </section>
    </main>
  );
};

export default GameBoxMore;
