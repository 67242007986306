import { Divider, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaUser } from "react-icons/fa";
import MainText from "./main_text";
import SubText from "./sub_text";
import { BiTransferAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { appName } from "../../../config";

const TransactionIssues = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`help-center.${key}`, { ...props });

  return (
    <VStack spacing={`10px`} alignItems={`flex-start`}>
      <Flex alignItems={`flex-end`} gap="10px">
        <Icon color="brand.500" as={BiTransferAlt} fontSize="40px" />
        <Text mb="0px" fontSize="xl" fontWeight={`semibold`} as="h5">
          {i18n("Deposit and Withdrawal Strategy")}
        </Text>
      </Flex>
      <SubText>{i18n("tranactionHint_1", { casino: appName })}</SubText>
      <Divider my="10px" />
      <MainText>💰 {i18n("Deposit")}</MainText>
      <SubText>{i18n("transactionHint_2")}</SubText>
      <Divider my="10px" />
      <MainText>💸 {i18n("Withdrawal")}</MainText>
      <SubText>{i18n("transactionHint_3")}</SubText>
      <Divider my="10px" />
      <MainText>📈 {i18n("View Transaction History")}</MainText>
      <SubText>
        {i18n(
          "Member Center > Transaction History You can find all operations on your account, and use filters to select a time period for transactions."
        )}
      </SubText>
      <Divider my="10px" />
      <MainText>{i18n("Cryptocurrency Deposit Not Yet Credited?")}</MainText>
      <SubText>
        {i18n(
          "When your cryptocurrency deposit is not immediately reflected in your account, don't worry, as there are some common reasons for this issue, and we will assist you in resolving it! Remember, you can check the status here - Member Center > Transaction History."
        )}
      </SubText>
      <MainText> {i18n("Delayed Deposits:")}</MainText>
      <SubText>
        {i18n(
          "One of the most common reasons for delayed cryptocurrency deposits is the network itself. Blockchain networks sometimes experience congestion, which can result in delays in processing transactions. These delays can range from minutes to hours, depending on the level of network congestion and the blockchain/cryptocurrency you are using."
        )}
      </SubText>
      <MainText> {i18n("Transaction Fees:")}</MainText>
      <SubText>
        {i18n(
          "Another reason for delays could be transaction fees. Some cryptocurrencies require fees to process transactions. If you paid a fee that was too low, the transaction may take longer to confirm and be processed by the network."
        )}{" "}
      </SubText>
      <MainText> {i18n("Wallet Address/Tag Error:")}</MainText>
      <SubText>
        {i18n(
          "You may also have accidentally sent the cryptocurrency to the wrong wallet address or missed the correct tag/identifier. In this case, the transaction may have been sent to the wrong address, or it may not be processed by the recipient's wallet. Before sending any cryptocurrency in the future, double-check the address and tag."
        )}
      </SubText>
      <MainText>
        {" "}
        {i18n("What Can You Do If Your Deposit Is Delayed?")}
      </MainText>
      <SubText>
        {i18n(
          "If your deposit is not credited within a reasonable time frame, please feel free to contact customer support at any time. Our team will be able to investigate the issue and provide you with the latest information on your deposit status."
        )}
      </SubText>
    </VStack>
  );
};

export default TransactionIssues;
