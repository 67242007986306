import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { formatNumber } from "./formatNumber";
import i18next from "i18next";
import { BALANCE_RELOAD } from "../constant";
import { Text, useColorModeValue } from "@chakra-ui/react";

const MyBalance = ({ balanceStyle }) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 存到walletCurrency變數中
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");

  const location = useLocation();

  const { pathname } = location;

  const { color, fontSize, fontWeight } = balanceStyle || {};

  useEffect(() => {
    const fetchDataPeriodically = () => {
      dispatch({ type: "GET_BALANCE" });
    };

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, BALANCE_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  const balance = useMemo(() => {
    return (
      isCurrencyExchange?.[walletCurrency]?.exchange_amount || "Loading..."
    );
  }, [walletCurrency, isCurrencyExchange]);

  return (
    <Text
      as="span"
      color={color || textColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {pathname.includes("gamebox")
        ? `(${i18next.t("common.playing")})`
        : formatNumber(balance)}
    </Text>
  );
};

export default MyBalance;
