import { AbsoluteCenter, Box, Spinner } from "@chakra-ui/react";
import React from "react";

const LoadingContainer = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      bg="blackAlpha.500"
      w="100%"
      h="100%"
      zIndex={1}
    >
      <AbsoluteCenter>
        <Spinner variant={`brandPrimary`} speed="0.65s" size="lg" />
      </AbsoluteCenter>
    </Box>
  );
};

export default LoadingContainer;
