import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

const LoadingBetRecord = ({ commonLoading, children, mapIndex = 5 }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  const cardBg = useColorModeValue("light.100", "dark.100");

  return commonLoading || loadingStatus
    ? Array.from({ length: mapIndex }, (_, i) => i).map((item) => {
        return (
          <>
            <Box padding="3" boxShadow="lg" borderRadius="common" bg={cardBg}>
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={2}
                spacing="4"
                skeletonHeight="2"
              />
            </Box>
          </>
        );
      })
    : children;
};

export default LoadingBetRecord;
