import Cookies from "js-cookie";
import { notice } from "./action/action";
import {
  getCarouselList,
  getCurrentOnlineGamePlayer,
  getEventList,
  getFavoriteGameList,
  getInboxList,
  getMarquee,
  getNowCurrencyExchange,
  getUserGameList,
  getUserInfo,
  getVipInfo,
  getVipList,
} from "../api/getApi";
import {
  cleanMemberInfo,
  resetInvalidAuth,
  storeBonus,
  storeCarouselList,
  storeCurrencyExchange,
  storeEventList,
  storeInboxList,
  storeMarqueeList,
  storeMemberInfo,
  storeVipDetail,
} from "./action/member/memberAction";
import { startLoading, stopLoading } from "./action/loading/loadingAction";
import {
  filterGameList,
  storeGameOnlinePlayer,
} from "./action/game/gameAction";
import i18next from "i18next";
import { push } from "@lagunovsky/redux-react-router";
import { WEB_LAYOUT } from "../constant";

export const apiErrorMessage = (store) => (next) => (action) => {
  if (action.type === "API_ERROR" && action.error) {
    const errorMessage = action?.error?.response?.data?.message;
    const errorParams = action?.error?.response?.data?.errData;

    store.dispatch(
      notice({
        // title: i18next.t(`backend_response.${errorMessage}`, errorParams),
        title: errorMessage,
        type: "error",
      })
    );
  }
  return next(action);
};

export const userLogout = (store) => (next) => (action) => {
  next(action);
  if (action.type === "USER_LOGOUT") {
    const { dispatch } = store;
    const { isCallbackInvalid } = store.getState();

    if (!isCallbackInvalid) {
      dispatch(
        notice({
          title: i18next.t(`backend_response.Invalid Authorization`),
          type: "error",
        })
      );
    }

    dispatch(cleanMemberInfo());
    Cookies.remove("token");
    dispatch(
      push(
        WEB_LAYOUT == "v2" || WEB_LAYOUT == "v3"
          ? `/${
              localStorage.getItem("i18nextLng") ||
              process.env.REACT_APP_DEFAULT_LANGUAGE
            }/slots`
          : `/${
              localStorage.getItem("i18nextLng") ||
              process.env.REACT_APP_DEFAULT_LANGUAGE
            }`
      )
    );
    window?.google?.accounts?.id?.disableAutoSelect();
  }
};

export const getBalance = (store) => (next) => (action) => {
  next(action);
  if (action.type === "GET_BALANCE") {
    const { dispatch, getState } = store;
    const getBalance = async () => {
      try {
        const [
          getNowCurrencyExchangeData,
          getVipInfoData,
          getCurrentOnlineGamePlayerData,
        ] = await Promise.all([
          getNowCurrencyExchange(),
          getVipInfo(),
          getCurrentOnlineGamePlayer(),
        ]);
        const { exchange, bonus } = getNowCurrencyExchangeData;
        const { isMemberInfo } = getState();
        dispatch(storeCurrencyExchange(exchange));
        dispatch(storeBonus(bonus));
        dispatch(
          storeMemberInfo({
            ...isMemberInfo,
            vipInfo: getVipInfoData.data,
          })
        );
        if (getVipInfoData?.data?.status == 1) {
          dispatch({ type: "GET_MEMBER_DATA" });
        }
        if (getCurrentOnlineGamePlayerData.code == 200) {
          dispatch(storeGameOnlinePlayer(getCurrentOnlineGamePlayerData.data));
        }
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          return;
        }
      }
    };
    getBalance();
  }
};

export const currencyExchanger = (store) => (next) => (action) => {
  next(action);
  if (action.type === "CURRENCY_EXCHANGER") {
    const { dispatch, getState } = store;
    const getExchanger = async () => {
      try {
        const [getNowCurrencyExchangeData] = await Promise.all([
          getNowCurrencyExchange(),
        ]);
        const { exchange, bonus } = getNowCurrencyExchangeData;
        dispatch(storeCurrencyExchange(exchange));
        dispatch(storeBonus(bonus));
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          return;
        }
      }
    };
    getExchanger();
  }
};

export const getMemberData = (store) => (next) => (action) => {
  next(action);
  if (action.type === "GET_MEMBER_DATA") {
    const { dispatch, getState } = store;
    const getMemberData = async () => {
      try {
        const [userInfoData] = await Promise.all([getUserInfo()]);
        if (userInfoData.code == 200) {
          const { isMemberInfo } = getState();
          dispatch(
            storeMemberInfo({
              ...isMemberInfo,
              ...userInfoData.memberInfo,
            })
          );
        }
      } catch (err) {}
    };
    getMemberData();
  }
};

export const updateEventList = (store) => (next) => (action) => {
  next(action);
  if (action.type === "UPDATE_EVENT_LIST") {
    const { dispatch } = store;
    const updateEventList = async () => {
      try {
        const [eventList] = await Promise.all([getEventList()]);
        dispatch(storeEventList(eventList?.data));
      } catch (err) {}
    };
    updateEventList();
  }
};

export const initializeApi = (store) => (next) => (action) => {
  next(action);
  if (action.type === "INITIALIZE_API") {
    const { dispatch, getState } = store;

    const initializeData = async () => {
      try {
        dispatch(startLoading());
        dispatch(resetInvalidAuth());

        getEventList().then((data) => {
          dispatch(storeEventList(data?.data));
        });
        getCarouselList().then((data) => {
          dispatch(storeCarouselList(data?.data));
        });
        getVipList().then((data) => {
          dispatch(storeVipDetail(data.data));
        });
        getMarquee().then((data) => {
          dispatch(storeMarqueeList(data.Marquees));
        });
        if (Cookies.get("token") !== undefined) {
          const [
            userInfoData,
            userFavoriteGame,
            userGameList,
            getVipInfoData,
            getInboxListData,
            getNowCurrencyExchangeData,
          ] = await Promise.all([
            getUserInfo(),
            getFavoriteGameList(),
            getUserGameList(),
            getVipInfo(),
            getInboxList(),
            getNowCurrencyExchange(),
          ]);

          if (userInfoData.code == 200) {
            dispatch(
              storeMemberInfo({
                ...userInfoData.memberInfo,
                vipInfo: getVipInfoData.data,
              })
            );
          }

          if (userGameList) {
            const process_platform = userGameList.platforms
              .map((item) => {
                if (item?.SubPlatformEnum?.length > 0) {
                  const origin_data = item;
                  return item.SubPlatformEnum.map((subItem) => {
                    return {
                      ...origin_data,
                      ...subItem,
                      platform: subItem.SubPlatformEnum,
                      platformEnum: subItem.SubPlatformEnum,
                    };
                  });
                } else {
                  return item;
                }
              })
              ?.flat();
            dispatch(
              filterGameList({
                allGameList: userGameList.gameTypeGroup,
                allGamePlatform: process_platform,
                allGameType: userGameList.gameType,
                allGame_subtype: userGameList.gameSubType,
                allNewGameList: userGameList.newGames,
                allTopGameList: userGameList.topGames,
                randomlySelectedGames: userGameList.randomlySelectedGames,
                userFavoriteGame: userFavoriteGame.userFavoriteGame,
                exclusiveBrandGames: userGameList.exclusiveBrandGames,
                origin_platform: userGameList.platforms,
              })
            );
          }

          if (getInboxListData) {
            dispatch(storeInboxList(getInboxListData.data));
          }

          if (getNowCurrencyExchangeData.code == 200) {
            dispatch(
              storeCurrencyExchange(getNowCurrencyExchangeData.exchange)
            );
            dispatch(storeBonus(getNowCurrencyExchangeData.bonus));
          }
        }
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          return;
        }

        // dispatch({
        //   type: "USER_LOGOUT",
        //   navigate: action.navigate,
        //   error: err,
        // });
        // dispatch(cleanMemberInfo());
        // Cookies.remove("token");
        // setTimeout(() => {
        //   action.navigate("/casino");
        // }, 1500);
      } finally {
        setTimeout(() => {
          dispatch(stopLoading());
        }, 1000);
      }
    };

    initializeData();
  }
};

export const periodicDataUpdater = (store) => (next) => (action) => {
  next(action);
  if (action.type === "UPDATE_DATA_PERIODICALLY") {
    const { dispatch } = store;

    const updaterData = async () => {
      try {
        if (Cookies.get("token") !== undefined) {
          const [
            userInfoData,
            // cryptoRatioData,
            getVipInfoData,
            // getUserBalanceData,
          ] = await Promise.all([
            getUserInfo(),
            // getCryptoRate(),
            getVipInfo(),
            // getUserBalance(),
          ]);
          // if (cryptoRatioData) {
          //   dispatch(StoreCryptoRatio(cryptoRatioData));
          // }

          if (userInfoData.code == 200) {
            dispatch(
              storeMemberInfo({
                ...userInfoData.memberInfo,
                vipInfo: getVipInfoData.data,
              })
            );
          }
        }
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          return;
        }
        // dispatch({
        //   type: "USER_LOGOUT",
        //   navigate: action.navigate,
        //   error: err,
        // });
        // dispatch(
        //   notice(
        //     "Your account has been logged in to another location",
        //     "error",
        //     15
        //   )
        // );
        // dispatch(cleanMemberInfo());
        // Cookies.remove("token");
        // setTimeout(() => {
        //   action.navigate("/casino");
        // }, 1500);
      }
    };
    updaterData();
  }
};
