import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const brandPrimary = definePartsStyle({
  // define the part you're going to style
  field: {
    border: "1px solid",
    borderColor: "gray.200",
    background: "white",
    borderRadius: "common",
    color: "black",
    // Let's also provide dark mode alternatives
    _dark: {
      background: "dark.200",
      borderColor: "gray.700",
      color: "white",
    },
    _focus: {
      borderColor: "brand.500",
    },
    _hover: {
      borderColor: "brand.500",
    },
  },
});

export const numberInputTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
});
