import React from "react";
import { CURRENCY_SYMBOL } from "../../../constant";
import {
  filterNumberColor,
  filterSign,
  formatNumber,
} from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Flex,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import TransactionTag from "./TransactionTag";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import useVipInfo from "../../../hook/useVipInfo";

const RecordDashboard = ({ method, props }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { current_symbol } = useVipInfo();

  const {
    deposit_number,
    creation_date,
    transfer_point,
    transfer_after,
    status,
    withdrawal_number,
    action, //轉點操作
    counterpart, //轉點來源
  } = props || {};

  return (
    <Box
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
    >
      <Flex justifyContent={`space-between`} alignItems={`start`}>
        <Text
          fontSize={`lg`}
          fontWeight={`semibold`}
          mb="7px"
          display="flex"
          alignItems={`center`}
          color="brand.500"
        >
          {method}{" "}
        </Text>
        <TransactionTag status={status} />
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n("transactionTime")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {creation_date}
        </Text>
      </Flex>
      <Flex gap="10px" alignItems={`center`}>
        <Text minW="60px" fontSize={`sm`} color="special.1">
          {i18n("orderNumber")}：
        </Text>
        <Text
          fontSize={`sm`}
          className="text-overflow"
          fontWeight={`bold`}
          color="special.1"
        >
          {deposit_number || withdrawal_number}
        </Text>
        <CopyIcon copyLink={deposit_number || withdrawal_number} />
      </Flex>
      {action && (
        <Flex alignItems={`center`}>
          <Text fontSize={`sm`} color="special.1">
            {i18n("action")}：
          </Text>
          <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
            {i18n(action)}
          </Text>
        </Flex>
      )}
      {counterpart && (
        <Flex alignItems={`center`}>
          <Text fontSize={`sm`} color="special.1">
            {i18n("transactionCounterpart")}：
          </Text>
          <Text
            className="text-overflow"
            fontSize={`sm`}
            fontWeight={`bold`}
            color="special.1"
          >
            {counterpart}
          </Text>
        </Flex>
      )}
      <Divider my="5px" />
      {status == 2 && (
        <Flex justifyContent={`space-between`}>
          <Flex direction={`column`} color="special.1">
            <Text fontSize={`sm`}>{i18n("amounts")}</Text>
            <Text color="special.1" fontWeight={`bold`}>
              {formatNumber(transfer_point)}
            </Text>
          </Flex>

          <Flex direction={`column`} color="special.1">
            <Text fontSize="sm">{i18n("lastBalance")}</Text>
            <Text color="special.1" fontWeight={`bold`}>
              {formatNumber(transfer_after)}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default RecordDashboard;
