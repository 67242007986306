import React, { useMemo } from "react";
import Signup from "./Signup";
import SignupV2 from "./Signup_v2";
import SignupV3 from "./Signup_v3";
import { WEB_LAYOUT } from "../../constant";

const SignupVersionSelector = () => {
  const signupPage = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <SignupV2 />;
      case "v3":
        return <SignupV3 />;
      default:
        return <Signup />;
    }
  }, [WEB_LAYOUT]);
  return signupPage;
};

export default SignupVersionSelector;
