const eventListReducers = (state = [], action) => {
  switch (action.type) {
    case "storeEventList":
      return (state = action.payload);
    case "clearEventList":
      return (state = []);
    default:
      return state;
  }
};
export default eventListReducers;
