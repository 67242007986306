import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getNewsList } from "../../api/getApi";
import i18next from "i18next";
import {
  Box,
  Divider,
  Skeleton,
  SkeletonText,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import LoadingNews from "../../components/Loading/LoadingNews";
import LoadingBlogDetail from "../../components/Loading/LoadingNews copy";
import { useTranslation } from "react-i18next";
import { languageMapping } from "../../i18n/config";
import "../../styles/jodit.css";
const BlogDetail = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`blog.${key}`);

  const [blogDetail, setBlogDetail] = useState({});
  const { title, content, start_time, end_time, type } = blogDetail;

  const bgColor = useColorModeValue("light.200", "dark.200");
  const textColor = useColorModeValue("dark.100", "light.100");

  const [isLoading, setIsLoading] = useState(true);

  const { route_link } = useParams();

  useEffect(() => {
    setIsLoading(true);

    if (route_link) {
      getNewsList({
        pathParams: route_link,
      })
        .then((res) => {
          const response = res?.data;
          const { news_language, start_time, end_time, uid, type } = response;
          const find_language =
            news_language?.find((item) => {
              return item.language === languageMapping(i18next.language);
            }) || news_language[0];
          setBlogDetail({
            ...find_language,
            start_time,
            end_time,
            uid,
            type,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [i18next, route_link]);

  return (
    <>
      <CloseDetailPage
        notBg
        className={`!my-0 !py-0 !h-[45px] !static`}
        hasPrev={
          <Link to={"/blog"}>
            <HiChevronLeft className="cursor-pointer" />
          </Link>
        }
        title={<></>}
        customClose={<></>}
      />
      <Box px="common-x">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={"15px"}
          py="common-y"
        >
          <SkeletonText
            skeletonHeight="25"
            minW="200px"
            noOfLines={1}
            isLoaded={!isLoading}
          >
            <Text
              as="h5"
              color={textColor}
              fontSize="2xl"
              fontWeight={`semibold`}
              textAlign={`center`}
            >
              {title || "-"}
            </Text>
          </SkeletonText>
          <Box
            display="flex"
            flexDirection={`column`}
            gap="5px"
            alignItems={`center`}
          >
            <Text as="span" color="brand.500">
              {i18n("time")}
            </Text>
            <SkeletonText
              skeletonHeight="5"
              minW="200px"
              noOfLines={1}
              isLoaded={!isLoading}
            >
              <Text as="span" color="brand.500">
                {start_time} ~ {end_time}
              </Text>
            </SkeletonText>
          </Box>
          <Divider mb="10px" />
          <LoadingBlogDetail commonLoading={isLoading}>
            <Box
              minH="300px"
              as="section"
              dangerouslySetInnerHTML={{ __html: content }}
              color={textColor}
              w="100%"
              p="15px"
              className="p-4 md:p-8 jodit-wysiwyg"
            ></Box>
          </LoadingBlogDetail>{" "}
          <Divider mb="10px" />
        </Box>
      </Box>
    </>
  );
};

export default BlogDetail;
