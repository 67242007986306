import { useMemo } from "react";
import { useLocation } from "react-router";

const useCasinoType = () => {
  const location = useLocation(); //用useLocation Hook 來獲取當前的 location 對象，並將其賦值給 location 變量
  const { pathname } = location; //從 location 對象中解構出 pathname 屬性。pathname 是當前 URL 的路徑

  const findCasinoOrLiveCasino = useMemo(() => {
    const pathArray = pathname.split("/");
    if (pathArray.find((item) => item === "casino")) {
      return "casino";
    } else if (pathArray.find((item) => item === "live-casino")) {
      return "live-casino";
    } else if (pathArray.find((item) => item === "sports")) {
      return "sports";
    } else if (pathArray.find((item) => item === "lottery")) {
      return "lottery";
    } else if (pathArray.find((item) => item === "bingo")) {
      return "bingo";
    } else if (pathArray.find((item) => item === "promotion")) {
      return "promotion";
    } else if (pathArray.find((item) => item === "terms")) {
      return "terms";
    }
  }, [pathname]);

  return findCasinoOrLiveCasino;
};

export default useCasinoType;
