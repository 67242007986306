import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import { useMemo, useState } from "react";
import { COMMON_WEB_PATH } from "../../constant";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import useSubTypeList from "../../hook/useSubTypeList";
import useSpecPath from "../../hook/useSpecPath";
import useCasinoType from "../../hook/useCasinoType";
import { filterType } from "../../hook/useGameListFilter";
import { BsTools } from "react-icons/bs";
import LazyImage from "../../components/Loading/lazyImage";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoLogoGameControllerB } from "react-icons/io";
import { Link } from "react-router-dom";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import useGetFullGamePlatform from "../../hook/useGetFullGamePlatform";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const CasinoGroups = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const boxBg = useColorModeValue("light.200", "dark.200");
  const textColor = useColorModeValue("dark.100", "light.100");
  const itemBg = useColorModeValue("light.100", "dark.100");

  const casinoType = useCasinoType();

  const {
    allGamePlatform = [],
    sortedByPlatformGames = {},
    sortedByPlatformAndCategoryGames = {},
    platform = [],
  } = isHandleAllGame;

  const subTypeList = useSubTypeList({ forceShowAll: true });

  const filterPlatformBySubType = useMemo(() => {
    const filterNullGame = Object.keys(sortedByPlatformAndCategoryGames).filter(
      (key) =>
        Object.keys(sortedByPlatformAndCategoryGames[key]).some((k) =>
          subTypeList.includes(k)
        )
    );
    const list = filterNullGame.map((item) => {
      const listLengthBigThanOne =
        filterType({
          gameList: sortedByPlatformGames[item],
          casinoType: casinoType,
        })?.length > 0;

      if (!listLengthBigThanOne) {
        return;
      } else {
        return item;
      }
      // return item;
    });

    return list;
  }, [subTypeList]);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const getPlatformPath = useGetFullGamePlatform();

  const casinoGroup = useMemo(() => {
    if (casinoType === "promotion") {
      return platform;
    }
    if (search) {
      return filterPlatformBySubType.filter((item) => {
        return item?.toLowerCase()?.includes(search);
      });
    } else {
      return filterPlatformBySubType;
    }
  }, [search, filterPlatformBySubType, casinoType]);

  return (
    <CommonOpacityBackground path={-1}>
      <ChakraBox
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        minH={{
          md: "248px",
        }}
        maxH={{
          md: "calc(100vh-92px)",
        }}
        w={{
          base: "100%",
          md: "720px",
        }}
        h="100%"
        overflowY="scroll"
        bg={boxBg}
        borderRadius={{
          md: "10px",
        }}
        gap="30px"
        px="20px"
        display="flex"
        flexDirection={`column`}
        className="no-scrollbar"
      >
        <CloseDetailPage
          title={`${i18n("Providers")}`}
          customClose={
            <IoCloseOutline
              className="cursor-pointer md:block hidden"
              onClick={() => {
                navigate(-1);
              }}
            />
          }
        />
        <Box as="section">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <IoLogoGameControllerB className="text-gray-300" />
            </InputLeftElement>
            <Input
              variant={`brandPrimary`}
              w="100%"
              icon
              placeholder={i18n("Search")}
            />
          </InputGroup>
        </Box>
        <Box display="grid" gap="20px" className="grid-cols-2 md:grid-cols-4">
          {!search && (
            <Link to={`/`}>
              <Box
                bg={itemBg}
                cursor={`pointer`}
                h="56px"
                borderRadius={`common`}
                py="18px"
                px="7px"
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
                _hover={{
                  border: "1px solid",
                  borderColor: "brand.500",
                }}
              >
                <Text color={textColor} className="underline">
                  {i18n("All providers")}
                </Text>
              </Box>
            </Link>
          )}
          {casinoGroup?.map((plat, index) => {
            // const platConfig = allGamePlatform?.find(
            //   (item) => item.platform === plat
            // );
            const platConfig = allGamePlatform;
            const isMaintain = platConfig?.isUnderMaintenance === true;

            return (
              plat && (
                <Box
                  onClick={() => {
                    if (isMaintain) {
                      return;
                    }
                    navigate(
                      `/${getPlatformPath[plat]}/category/${plat}/AllGames`
                    );
                  }}
                  key={`${plat}-${index}`}
                  bg={itemBg}
                  cursor={`pointer`}
                  h="56px"
                  borderRadius={`common`}
                  px="7px"
                  display="flex"
                  alignItems={`center`}
                  justifyContent={`center`}
                  _hover={{
                    border: "1px solid",
                    borderColor: "brand.500",
                  }}
                  position="relative"
                >
                  {isMaintain && (
                    <Box
                      zIndex="2"
                      position={`absolute`}
                      top="0"
                      left="0"
                      w="full"
                      h="full"
                      bg="rgba(0,0,0,0.7)"
                      display="flex"
                      alignItems={`center`}
                      justifyContent={`center`}
                      flexDirection={`column`}
                    >
                      {" "}
                      <BsTools className="text-2xl" />
                      <Text as="span" fontSize="sm" letterSpacing={`tighter`}>
                        {platConfig?.maintenanceStartTime || "-"} ~
                      </Text>
                      <Text as="span" fontSize="sm" letterSpacing={`tighter`}>
                        {platConfig?.maintenanceEndTime || "-"}
                      </Text>
                    </Box>
                  )}

                  <LazyImage
                    className="mx-auto w-[100px] h-[55px] object-contain"
                    src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                      plat,
                      colorMode === "dark"
                    )}.png`}
                    alt={plat}
                  />
                </Box>
              )
            );
          })}
        </Box>
      </ChakraBox>
    </CommonOpacityBackground>
  );
};

export default CasinoGroups;
