import { FaDollarSign } from "react-icons/fa";
import {
  FaRupiahSign,
  FaPesoSign,
  FaDongSign,
  FaEthereum,
} from "react-icons/fa6";
import { PiCurrencyJpy } from "react-icons/pi";
import { TbCurrencyDollar, TbCurrencyTugrik } from "react-icons/tb";

export const lngsList = ["tw", "en", "cn", "vi", "id", "jp"];

export const languageMapping = (language) => {
  switch (language) {
    case "cn":
      return "zh_CN";
    case "en":
      return "en_US";
    case "tw":
      return "zh_TW";
    case "id":
      return "id_ID";
    case "jp":
      return "ja_JP";
    // case "tag":
    //   return "Tagalog";
    case "vn":
      return "vi_VN";
    case "vi":
      return "vi_VN";
    default:
      return "zh_TW";
  }
};

export const LANGUAGES = [
  {
    text: "English",
    language: "en",
    value: "en",
    flag: "US",
    semantic_flag: "us",
  },
  {
    text: "简体",
    language: "zh_chs",
    value: "zh_chs",
    flag: "CN",
    semantic_flag: "cn",
  },
  {
    text: "繁體",
    language: "tw",
    value: "tw",
    flag: "TW",
    semantic_flag: "tw",
  },
  // {
  //   text: "Tagalog",
  //   language: "tag",
  //   value: "tag",
  //   flag: "PH",
  //   semantic_flag: "ph",
  // },
  {
    text: "Tiếng Việt",
    language: "viet",
    value: "viet",
    flag: "vn",
    semantic_flag: "vn",
  },
];
export const LANGUAGES_MENU = [
  {
    text: "简体",
    language: "zh_chs",
    value: "cn",
    flag: "cn",
    disabled: true,
  },
  {
    text: "English",
    language: "en",
    value: "en",
    flag: "us",
  },
  {
    text: "繁體",
    language: "tw",
    value: "tw",
    flag: "tw",
  },
  {
    text: "Tiếng Việt",
    language: "viet",
    value: "vi",
    flag: "vi",
    disabled: true,
  },
  {
    text: "Bahasa Indonesia",
    language: "id",
    value: "id",
    flag: "id",
    disabled: true,
  },
  {
    text: "日本語",
    language: "jp",
    value: "jp",
    flag: "jp",
    disabled: true,
  },
];

const currencyIcon = (IconComponent) => (textColor, backGroundColor) =>
  (
    <IconComponent
      style={{
        color: textColor,
        backgroundColor: backGroundColor,
        borderRadius: "50%",
        fontSize: "1rem",
        padding: "0.1rem",
      }}
    />
  );

const currencyIcon_Black = (IconComponent) => (textColor) =>
  (
    <IconComponent
      style={{
        color: textColor,
        fontSize: "12px",
      }}
    />
  );

const ICONS = {
  FaRupiahSign: currencyIcon(FaRupiahSign),
  FaDongSign: currencyIcon(FaDongSign),
  FaPesoSign: currencyIcon(FaPesoSign),
  TbCurrencyTugrik: currencyIcon(TbCurrencyTugrik),
  FaEthereum: currencyIcon(FaEthereum),
  PiCurrencyJpy: currencyIcon(PiCurrencyJpy),
  TbCurrencyDollar: currencyIcon(TbCurrencyDollar),
  TWCurrency: currencyIcon(FaDollarSign),
};

export const CURRENCY_MENU = [
  {
    text: "USDT",
    currency: "USDT",
    value: "USDT",
    label: "USDT",
    icon: ICONS.TbCurrencyTugrik("#FFF", "#009393"),
    iconBlack: "₮",
    textColor: "#FFF",
    backGroundColor: "#009393",
    type: "crypto",
  },
  // {
  //   text: "USD",
  //   currency: "USD",
  //   value: "USD",
  //   label: "USD",
  //   icon: ICONS.TbCurrencyDollar("#111", "#b3ee90"),
  //   iconBlack: "$",
  //   textColor: "#111",
  //   backGroundColor: "#b3ee90",
  //   type: "fiat",
  // },
  {
    text: "ETH",
    currency: "ETH",
    value: "ETH",
    label: "ETH",
    icon: ICONS.FaEthereum("#FFF", "#627eea"),
    iconBlack: "♦",
    textColor: "#FFF",
    backGroundColor: "#627eea",
    type: "crypto",
  },
  {
    text: "JPY",
    currency: "JPY",
    value: "JPY",
    label: "JPY",
    icon: ICONS.PiCurrencyJpy("#111", "#eac749"),
    iconBlack: "¥",
    textColor: "#111",
    backGroundColor: "#eac749",
    type: "fiat",
  },
  {
    text: "IDR",
    currency: "IDR",
    value: "IDR",
    label: "IDR",
    icon: ICONS.FaRupiahSign("#FFF", "#FF0000"),
    iconBlack: "Rp",
    textColor: "#FFF",
    backGroundColor: "#FF0000",
    type: "fiat",
  },
  {
    text: "VND",
    currency: "VND",
    value: "VND",
    label: "VND",
    icon: ICONS.FaDongSign("#ffff01", "#FF0000"),
    iconBlack: "₫",
    textColor: "#ffff01",
    backGroundColor: "#FF0000",
    type: "fiat",
  },
  {
    text: "PHP",
    currency: "PHP",
    value: "PHP",
    label: "PHP",
    icon: ICONS.FaPesoSign("#FFF", "#0038a8"),
    iconBlack: "₱",
    textColor: "#FFF",
    backGroundColor: "#0038a8",
    type: "fiat",
  },
  {
    text: "TWD",
    currency: "TWD",
    value: "TWD",
    label: "TWD",
    icon: ICONS.TWCurrency("#FFF", "#87BD48"),
    iconBlack: "$",
    textColor: "#FFF",
    backGroundColor: "#87BD48",
    type: "fiat",
  },
];
