import { useEffect, useState } from "react";
import {
  motion,
  isValidMotionProp,
  AnimatePresence,
  useWillChange,
} from "framer-motion";

import { useNavigate } from "react-router";
import { getFixedStyle } from "../../hook/useDeviceType";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  chakra,
  Button,
  shouldForwardProp,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SignupForm from "./signupForm";
import PromoCardMobile from "../Singin/components/promoCard_mobile";
import PromoCardPc from "../Singin/components/promoCard_pc";
import useCheckLogin from "../../hook/useCheckLogin";
import { resetInvalidAuth } from "../../redux/action/member/memberAction";
import { useDispatch } from "react-redux";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Signup = () => {
  const { t } = useTranslation();
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const navigate = useNavigate();
  const previousPage = usePreviousPagePath();

  const dispatch = useDispatch();

  const baseBg = useColorModeValue("light.100", "dark.100");

  const willChange = useWillChange();

  const { isLogin } = useCheckLogin();

  useEffect(() => {
    dispatch(resetInvalidAuth());

    if (isLogin) {
      navigate("/");
    }
  }, []);

  return (
    <ChakraBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ willChange }}
      display="flex"
      flexDirection={`column`}
      justifyContent={`start`}
      alignItems="center"
      zIndex="10000"
      py="10vh"
      px="10px"
      width={{
        base: "100%",
      }}
      height={{
        base: "100%",
      }}
      overflowY={`scroll`}
      position="fixed"
      top={`0px`}
      left={`0px`}
      className={`lg:backdrop-blur-none backdrop-blur-md no-scrollbar`}
    >
      <PromoCardPc />
      <Box
        width={{
          md: "400px",
          base: "100%",
        }}
        borderRadius={{
          base: "common",
        }}
        borderTopRadius={{
          md: "none",
        }}
        borderBottomRadius={{
          md: "common",
        }}
        shadow="md"
        bg={baseBg}
        py="10px"
      >
        <PromoCardMobile />
        <Box borderRadius={`common`} px="common-x">
          <Flex justifyContent={`space-between`} alignItems={`start`}>
            <Flex gap="5px">
              <Link to="../signin">
                <Button
                  variant={`brandPrimary`}
                  type="button"
                  bg="gray.300"
                  fontWeight={`bold`}
                  color={`white`}
                  minWidth={`55px`}
                  fontSize="md"
                >
                  {i18n_header("signin")}
                </Button>
              </Link>
              <Link to="../signup">
                <Button
                  variant={`brandPrimary`}
                  type="button"
                  bg="brand.500"
                  fontWeight={`bold`}
                  color={`white`}
                  minWidth={`55px`}
                  fontSize={`md`}
                >
                  {i18n_header("signup")}
                </Button>
              </Link>
            </Flex>
            <Link to={previousPage}>
              <Icon as={IoMdClose} mb="8px" fontSize="3xl" color="brand.500" />
            </Link>
          </Flex>
          <Box width="100%" px="common-x" py="10px" className="no-scrollbar">
            <SignupForm />
          </Box>
        </Box>
      </Box>
    </ChakraBox>
  );
};

export default Signup;
