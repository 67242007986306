export function flattenWalletObject(obj) {
  const result = {};

  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      for (const innerKey in obj[key]) {
        const innerKayName = innerKey.replace(key, "");
        const newKeyName = `${key}-${innerKayName}`;

        result[newKeyName] = obj[key][innerKey];
      }
    } else {
      result[key] = obj[key];
    }
  }

  return result;
}
