import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/action/action";
import {
  Box,
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

const ThemeTab = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const boxHoverBg = useColorModeValue("light.300", "dark.300");
  return (
    <Box
      as="section"
      py="20px"
      display="flex"
      alignItems={`center`}
      justifyContent={`center`}
      gap="30px"
    >
      <Flex
        direction={`column`}
        gap="10px"
        cursor="pointer"
        onClick={() => {
          if (colorMode === "dark") {
            return;
          }
          Cookies.set("color-mode", "dark", 3);
          toggleColorMode();
        }}
      >
        <Box
          w="36px"
          h="36px"
          borderRadius={`full`}
          border={colorMode === "dark" && `1px solid`}
          borderColor={colorMode === "dark" && `brand.500`}
          bg={`dark.200`}
        ></Box>
        <Text
          as="span"
          color={colorMode === "dark" ? "brand.500" : "special.1"}
        >
          Dark
        </Text>
      </Flex>
      <Flex
        direction={`column`}
        gap="10px"
        cursor="pointer"
        onClick={() => {
          if (colorMode === "light") {
            return;
          }
          Cookies.set("color-mode", "light", 3);
          toggleColorMode();
        }}
      >
        <Box
          w="36px"
          h="36px"
          borderRadius={`full`}
          border={colorMode === "light" && `1px solid`}
          borderColor={colorMode === "light" && `brand.500`}
          bg={`light.200`}
        ></Box>
        <Text
          as="span"
          color={colorMode === "light" ? "brand.500" : "special.1"}
        >
          Light
        </Text>
      </Flex>
    </Box>
  );
};

export default ThemeTab;
