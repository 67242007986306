import { GiEightBall, GiPokerHand } from "react-icons/gi";
import { IoLogoGameControllerB, IoMdFootball } from "react-icons/io";
import { IoTicket } from "react-icons/io5";
import { MdEventNote } from "react-icons/md";

const isCredit = process.env.REACT_APP_CREDIT == 1;

export const headerMenu = [
  { text: "SLOTS", link: "casino", icon: IoLogoGameControllerB },
  { text: "LIVE CASINO", link: "live-casino", icon: GiPokerHand },
  { text: "SPORTS", link: "sports", icon: IoMdFootball },
  { text: "BINGO", link: "bingo", icon: GiEightBall },
  // { text: "LOTTERY", link: "lottery", icon: IoTicket },
  { text: "PROMOTION", link: "promotion", icon: MdEventNote, hidden: isCredit },
];
