import React, { useEffect, useMemo, useState } from "react";
import { toggleFavoriteGame } from "../../api/putApi";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../../src/redux/action/action";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { getFavoriteGameList } from "../../api/getApi";
import { storeFavoriteGameList } from "../../redux/action/game/gameAction";
import { Box, Icon, useColorModeValue } from "@chakra-ui/react";
import useCheckLogin from "../../hook/useCheckLogin";

const ToggleFavorite = ({ fontSize, customGameUid, wrapperClass }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const dispatch = useDispatch();

  const { isLogin } = useCheckLogin();

  const gameConfig = useSelector((state) => state.isGameConfig);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { favoriteGameList } = isHandleAllGame;

  const { gameUid } = gameConfig;

  const findIsFavorite = useMemo(() => {
    if (customGameUid) {
      return favoriteGameList?.some((item) => item.gameUid === customGameUid);
    }
    return favoriteGameList?.some((item) => item.gameUid === gameUid);
  }, [gameUid, favoriteGameList, customGameUid]);

  const [isClicking, setIsClicking] = useState(false);
  const [favorite, setFavorite] = useState(findIsFavorite);

  const textColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();

  useEffect(() => {
    if (gameConfig) {
      setFavorite(findIsFavorite);
    }
  }, [gameConfig, findIsFavorite]);

  const handleToggleFavorite = (toggleCode) => {
    if (!isLogin) {
      navigate("signin");
      return;
    }
    setIsClicking(true);
    setFavorite(!favorite);
    toggleFavoriteGame({
      gameUid: customGameUid || gameUid,
      params: {
        status: toggleCode,
      },
    })
      .then((data) => {
        getFavoriteGameList().then((data) => {
          dispatch(storeFavoriteGameList(data.userFavoriteGame));
        });
      })
      .catch((err) => {})
      .finally(() => {
        setIsClicking(false);
      });
  };

  return (
    <Box display={"flex"} alignItems={"center"} {...wrapperClass}>
      {favorite ? (
        <Icon
          onClick={() => {
            if (isClicking) {
              return;
            }
            handleToggleFavorite(0);
          }}
          cursor="pointer"
          as={AiFillHeart}
          fontSize={fontSize || "2xl"}
          color={`red.600`}
        />
      ) : (
        <Icon
          onClick={() => {
            if (isClicking) {
              return;
            }
            handleToggleFavorite(1);
          }}
          cursor="pointer"
          as={AiOutlineHeart}
          fontSize={fontSize || "2xl"}
          color={textColor}
        />
      )}
    </Box>
  );
};

export default ToggleFavorite;
