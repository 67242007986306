const detectInvalidAuthReducers = (state = false, action) => {
  switch (action.type) {
    case "isDetectedInvalidAuth":
      return (state = true);
    case "resetInvalidAuth":
      return (state = false);
    default:
      return state;
  }
};
export default detectInvalidAuthReducers;
