import React, { useEffect, useMemo, useState } from "react";
import {
  formatTime,
  getCustomDays,
  getSevenDays,
  getThirtyDays,
  getToday,
  getYesterday,
} from "../../utils/getDay";
import { useTranslation } from "react-i18next";
import { Select } from "@chakra-ui/react";
import dayjs from "dayjs";

const DateSelect = ({ setDatePicker, className, trigger }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);

  const [current_option, setCurrentOption] = useState(1);

  const dateList = [
    {
      text: "Last 24 hours",
      value: 1,
      startDate: `${formatTime(getYesterday())} `,
      endDate: `${formatTime(getToday())} `,
    },
    {
      text: "Last 7 days",
      value: 7,
      startDate: `${formatTime(getSevenDays())}`,
      endDate: `${formatTime(getToday())}`,
    },

    {
      text: "Last 30 days",
      value: 30,
      startDate: `${formatTime(getThirtyDays())}`,
      endDate: `${formatTime(getToday())}`,
    },
    {
      text: "Last 60 days",
      value: 60,
      startDate: `${formatTime(getCustomDays(60))}`,
      endDate: `${formatTime(getToday())}`,
    },
  ];

  useEffect(() => {
    const find_option = dateList.find((item) => {
      return item.value == current_option;
    });
    setDatePicker({
      str_date: find_option.startDate,
      end_date: find_option.endDate,
    });
  }, [trigger, current_option]);

  return (
    <Select
      {...className}
      variant={`brandPrimary`}
      onChange={(e) => {
        setCurrentOption(e.target.value);
      }}
      size={`sm`}
      border="0px"
    >
      {dateList.map((option) => {
        return <option value={option.value}>{i18n(option.text)}</option>;
      })}
    </Select>
  );
};

export default DateSelect;
