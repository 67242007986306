import { Divider, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaUser } from "react-icons/fa";
import MainText from "./main_text";
import SubText from "./sub_text";
import { IoShieldCheckmark } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { appName } from "../../../config";

const SecurityConcern = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`help-center.${key}`, { ...props });

  return (
    <VStack spacing={`10px`} alignItems={`flex-start`}>
      <Flex alignItems={`flex-end`} gap="10px">
        <Icon color="brand.500" as={IoShieldCheckmark} fontSize="40px" />
        <Text mb="0px" fontSize="xl" fontWeight={`semibold`} as="h5">
          {i18n("Security Concerns")}
        </Text>
      </Flex>
      <Divider my="10px" />
      <MainText>{i18n("Is My Basic Information Secure？")}</MainText>
      <SubText>{i18n("Secure Hint", { casino: appName })}</SubText>
      <Divider my="10px" />
    </VStack>
  );
};
export default SecurityConcern;
