import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../utils/isEmptyObject";
import { getTransferWallet } from "../api/getApi";
import { storeTransferWallet } from "../redux/action/member/memberAction";
import { flattenWalletObject } from "../utils/flattenObject";

const useDetectedPlayerWalletBalance = ({ to } = {}) => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const dispatch = useDispatch();
  const { allGamePlatform } = handleAllGame;

  const singleWallet = useSelector(
    (state) => state.isMemberInfo.gameWalletInfo
  );
  const transferWallet = useSelector((state) => state.isTransferWallet);
  const gameConfig = useSelector((state) => state.isGameConfig);
  const { game_platform } = gameConfig;

  const isTransferMode = useMemo(() => {
    if (!game_platform) return false;
    const currentPlatform = game_platform;
    const findPlatformConfig = allGamePlatform.find(
      (item) => item.platform === currentPlatform
    );
    return findPlatformConfig?.platformMode === "transfer";
  }, [gameConfig]);

  const detectedPlayerWalletBalanceBiggestThanZero = useMemo(() => {
    //如果轉帳錢包的話 才會跑下面這些
    if (
      isTransferMode &&
      !isEmptyObject(gameConfig) &&
      !isEmptyObject(transferWallet)
    ) {
      const combineWallet = { ...singleWallet, ...transferWallet };
      if (!isEmptyObject(gameConfig)) {
        const walletName = `${to}${
          gameConfig?.extensionGameGroup
            ? `-${gameConfig?.extensionGameGroup.replace(to, "")}`
            : ""
        }`;
        return combineWallet[walletName] > 0;
      }
    } else {
      return true; //如果不是轉帳錢包的話 回傳false
    }
  }, [gameConfig, singleWallet, transferWallet, isTransferMode]);

  return detectedPlayerWalletBalanceBiggestThanZero;
};

export default useDetectedPlayerWalletBalance;
