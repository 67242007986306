import { isValidMotionProp, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { HiChevronLeft } from "react-icons/hi";

import { animatePage } from "../../animateConstant";
import { COMMON_WEB_PATH } from "../../constant";
import {
  AbsoluteCenter,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Skeleton,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { methodIcon } from "./components/methodIcon";
import CustomInput from "../../components/InputComponents/customInput";
import { AiFillDollarCircle } from "react-icons/ai";
import { waitTime } from "../../utils/waitTime";
import LoadingContainer from "../../components/Loading/LoadingContainer";
import { getThirdPartyPayment } from "../../api/getApi";
import DetailWrapper from "../../components/detailWrapper";
import GetParams from "../../utils/getParams";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { formatNumber } from "../../utils/formatNumber";
import { depositPayment } from "../../api/postApi";
import { FaAddressCard } from "react-icons/fa6";
import CopyIcon from "../../components/CopyComponent/CopyIcon";
import ReferQrcode from "../ReferCode/components/referQrcode";
import { openChat } from "../../redux/action/member/memberAction";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DepositStepNormalCrypto = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`deposit.${key}`, { ...props });

  const currency_code = useSelector((state) => state.isWalletCurrency);

  const [data, setData] = useState({
    method_type: "",
    amount: 0,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [paymentList, setPaymentList] = useState({});
  const { method_type, amount } = data || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const methodBoxBg = useColorModeValue("light.100", "dark.100");
  const iconColor = useColorModeValue("light.400", "light.200");
  const dividerBg = useColorModeValue("light.200", "dark.200");

  const { third_party, vip } = paymentList || {};
  const navigate = useNavigate();
  const location = useLocation();
  const { uid } = useParams();

  const dispatch = useDispatch();

  const method = GetParams("method");
  const cryptoAddress = GetParams("cryptoAddress");
  const chain = GetParams("chain");

  const find_payment_method = useMemo(() => {
    return third_party?.filter((item) => {
      return item.method_name?.toLowerCase() === method?.toLowerCase();
    });
  }, [method, paymentList]);

  const handleSubmit = async () => {
    dispatch(openChat());
  };

  const handleSelectMethod = (method) => {
    setData({ ...data, method_type: method, amount: 0 });
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiLoading(true);
      await getThirdPartyPayment({
        paramsData: {
          currency_code: currency_code,
        },
      }).then((data) => {
        const vip = data?.vip || [];
        const third_party = data?.third_party || [];
        setPaymentList({
          vip,
          third_party,
        });
      });
      setApiLoading(false);
    };

    fetchData();
  }, []);

  return (
    <DetailWrapper>
      <ChakraBox
        h="auto"
        display="flex"
        flexDirection={`column`}
        justifyContent={`space-between`}
        animate="visible"
        variants={animatePage}
      >
        <Box as="section">
          <CloseDetailPage
            hasPrev={
              <HiChevronLeft
                className="cursor-pointer"
                onClick={() => navigate("../deposit")}
              />
            }
            title={
              <Text
                as="span"
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
              >
                {method?.toUpperCase()}
              </Text>
            }
          />

          <Flex alignItems={`center`} justifyContent={`center`} mb="30px">
            <Image
              src={`${COMMON_WEB_PATH}/paymentIcon/${method?.toLowerCase()}pay.png`}
              className="!w-[50px] !h-[50px] object-cover"
            />
          </Flex>
          <Divider borderColor="gray.300" mt="5px" mb="10px" />
          <Box as="section">
            <Text
              as="p"
              fontSize="lg"
              fontWeight={`semibold`}
              color={textColor}
              mb="5px"
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={"green.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("deposit_address", { chain: chain })}
            </Text>
            <Flex alignItems={`center`} gap="10px">
              <CustomInput
                props={{
                  icon: <FaAddressCard color="gray.300" />,
                  value: cryptoAddress,
                  isDisabled: true,
                  isReadOnly: true,
                }}
              />
              <CopyIcon copyLink={cryptoAddress} />
            </Flex>
            {/* <Box my="10px" position={`relative`} p="5px">
              <Divider borderColor="gray.300" />
              <AbsoluteCenter bg={dividerBg} px="4">
                AND
              </AbsoluteCenter>
            </Box> */}
            <Divider my="10px" borderColor="gray.300" />
          </Box>
          <Box as="section">
            {/* <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
              mb="5px"
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={"green.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              QRCode
            </Text> */}
            <Box display="flex" justifyContent={`center`}>
              <Box mx="auto" p="5px" borderRadius={`common`} bg={methodBoxBg}>
                <ReferQrcode value={cryptoAddress} />
              </Box>
            </Box>
          </Box>
          <Divider borderColor="gray.300" my="10px" />
          <Alert mb="10px" status="warning">
            <AlertIcon />
            {i18n("crypto_deposit_hint")}{" "}
          </Alert>
          <Box as="section">
            <Button
              type="button"
              onClick={handleSubmit}
              variant={`brandPrimary`}
              w="100%"
              isLoading={buttonLoading}
              size="lg"
            >
              {i18n("contact_service")}
            </Button>
          </Box>
        </Box>
      </ChakraBox>
    </DetailWrapper>
  );
};

export default DepositStepNormalCrypto;
