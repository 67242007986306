import { AnimatePresence, motion, useWillChange } from "framer-motion";

import CloseComponent from "../../components/CloseComponent/CloseComponent";
import { appName } from "../../config";
import { popUpVariant } from "../../animateConstant";
import { useState } from "react";
import { getFixedStyle } from "../../hook/useDeviceType";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { COMMON_ALT } from "../../constant";
import { useSelector } from "react-redux";
import Iphone from "./components/iphone";
import Android from "./components/android";

const DownloadApp = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  const willChange = useWillChange();

  const isDarkMode = useSelector((state) => state.isTheme === "dark");

  const [isClosing, setIsClosing] = useState(false);
  const [activeItem, setActiveItem] = useState("IPHONE");

  const navigate = useNavigate();

  return (
    <AnimatePresence>
      {!isClosing && (
        <motion.main
          variants={popUpVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{ willChange }}
          transition={{
            duration: 0.2,
          }}
          className={`${getFixedStyle()} bg-[rgba(0, 0, 0, 0.5)] z-[9999] backdrop-blur-md flex items-center justify-center px-[10px]`}
        >
          <section className="max-h-[90vh] overflow-y-scroll no-scrollbar bg-base-white dark:bg-base-deep-black pb-[25px] pt-[10px] px-[15px] rounded-xl text-transaction-text dark:text-base-light-white max-w-[450px] w-full">
            <section className="flex items-center justify-end text-3xl">
              <IoCloseOutline
                className="cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </section>
            <p className="text-xl mb-0 text-main-color font-semibold">
              {i18n("DownloadNow")}
            </p>
            {/* <Menu pointing inverted={isDarkMode} secondary>
              <Menu.Item
                name="IPHONE"
                active={activeItem === "IPHONE"}
                onClick={() => {
                  setActiveItem("IPHONE");
                }}
              />
              <Menu.Item
                name="ANDROID"
                active={activeItem === "ANDROID"}
                onClick={() => {
                  setActiveItem("ANDROID");
                }}
              />
            </Menu> */}
            {activeItem === "IPHONE" && <Iphone />}
            {activeItem === "ANDROID" && <Android />}
          </section>
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default DownloadApp;
