import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useTransferMode = () => {
  const gameConfig = useSelector((state) => state.isGameConfig);
  const allGamePlatform = useSelector(
    (state) => state.isHandleAllGame.allGamePlatform
  );

  const { game_platform, gameCode, game_subtype } = gameConfig;

  const isTransferMode = useMemo(() => {
    if (!game_platform) return false;
    const currentPlatform = game_platform;
    const findPlatformConfig = allGamePlatform?.find(
      (item) => item.platform === currentPlatform
    );
    return findPlatformConfig?.platformMode === "transfer";
  }, [game_platform]);
  return isTransferMode;
};

export default useTransferMode;
