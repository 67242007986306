import { useMemo, useState, useRef, useEffect, memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import {
  openWalletSettingPop,
  storeWalletCurrency,
} from "../../../redux/action/member/memberAction";
import { IoCheckmarkCircleSharp, IoWallet } from "react-icons/io5";
import { CURRENCY_MENU } from "../../../i18n/config";
import useHideZeroAmountStatus from "../../../hook/useHideZeroAmountStatus";
import {
  Box,
  Divider,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoIosSearch } from "react-icons/io";
import { formatNumber } from "../../../utils/formatNumber";
import i18next from "i18next";
import Cookies from "js-cookie";

const WalletList = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const { pathname } = useLocation();

  const textColor = useColorModeValue("dark.200", "light.200");
  const boxHover = useColorModeValue("light.200", "dark.200");

  const currencyData = useSelector((state) => state.isCurrencyExchange); // 從redux store中取 isCurrencyChange 的值
  const memberInfo = useSelector((state) => state.isMemberInfo); // 從redux store中取 memberInfo 的值
  const wallet_currency_code = useSelector((state) => state.isWalletCurrency); //從redux store中取isNowCurrency的值 存到currency_code變數中

  const { currency_code } = memberInfo || {}; // 從memberInfo中取 currency_code 的值

  const hideZeroStatus = useHideZeroAmountStatus();

  return (
    <PopoverContent p="5px" w="250px">
      <PopoverArrow />
      <PopoverBody position="relative" p="3px">
        {/* <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={IoIosSearch} color="gray.300" />
          </InputLeftElement>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              setSearch(value?.toLowerCase());
            }}
            placeholder={i18n("Search")}
            variant={`brandPrimary`}
            type="tel"
          />
        </InputGroup> */}
        <Divider mb="3px" mt="10px" />
        {Object.values(currencyData)
          ?.filter((item) => {
            return item.status_front == 1;
          })
          ?.filter((item) => {
            return item.currency_code?.toLowerCase()?.includes(search);
          })
          ?.map((currency, index) => {
            // 找到對應的 CURRENCY_MENU 項目
            const currencyMenuItem = CURRENCY_MENU.find(
              (item) => item.currency === currency.currency_code
            );

            // 如果 isHideZeroAmount是true 且 currency.exchange_amount是0，則不渲染這個幣別錢包餘額
            if (hideZeroStatus && currency.exchange_amount == 0) {
              return null;
            }

            return (
              <>
                <Box as="section">
                  <Box
                    borderRadius={`common`}
                    as="section"
                    key={index}
                    onClick={async () => {
                      Cookies.set("wallet_currency", currency.currency_code, 3);
                      await dispatch(
                        storeWalletCurrency(currency.currency_code)
                      );
                      onClose();
                    }}
                    cursor={`pointer`}
                    py="7px"
                  >
                    <Flex
                      alignItems={`center`}
                      justifyContent={`start`}
                      fontWeight={`semibold`}
                      color={textColor}
                    >
                      <Flex alignItems={`center`} w="100%">
                        <Text as="span" w="24px">
                          {currency.symbol}
                        </Text>
                        <Text as="span">
                          {pathname.includes("gamebox")
                            ? `(${i18next.t("common.playing")})`
                            : formatNumber(currency.exchange_amount)}
                        </Text>
                      </Flex>
                      <Flex
                        w="120px"
                        alignItems="center"
                        justifyContent={`space-between`}
                      >
                        <Flex alignItems="center">
                          {/* 如果在CURRENCY_MENU找到對應的幣別，就渲染這個幣別的 icon */}
                          {currencyMenuItem && currencyMenuItem.icon}
                          <Text as="span" ml={1}>
                            {currency.currency_code}
                          </Text>
                        </Flex>
                        {wallet_currency_code === currency.currency_code && (
                          <Icon
                            as={IoCheckmarkCircleSharp}
                            color="green.500"
                            fontSize="1.5rem"
                            ml="5px"
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
                <Divider />
              </>
            );
          })}
        <Flex
          alignItems={`flex-end`}
          gap="5px"
          justifyContent={`center`}
          pt="8px"
          onClick={() => {
            dispatch(openWalletSettingPop());
          }}
          cursor={`pointer`}
        >
          <Text as="span" fontSize="md" fontWeight={`semibold`}>
            {i18n("walletSet")}
          </Text>

          <Icon as={IoWallet} fontSize="2xl" ml="5px" />
        </Flex>
      </PopoverBody>
    </PopoverContent>
  );
};

export default memo(WalletList);
