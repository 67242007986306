import React, { useMemo } from "react";
import SigninV2 from "./Signin_v2";
import Signin from "./Signin";
import SigninV3 from "./Signin_v3";
import { WEB_LAYOUT } from "../../constant";

const SigninVersionSelector = () => {
  const signinPage = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <SigninV2 />;
      case "v3":
        return <SigninV3 />;
      default:
        return <Signin />;
    }
  }, [WEB_LAYOUT]);
  return signinPage;
};

export default SigninVersionSelector;
