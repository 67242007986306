import i18next from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { languageMapping } from "../i18n/config";

const useMarqueeList = () => {
  const marqueeList = useSelector((state) => state.isMarqueeList);

  const process_marquee = useMemo(() => {
    const fitler_language = marqueeList?.map((item) => {
      const default_language_marquee = Object?.values(item)[0];
      const current_language_marquee =
        item[languageMapping(i18next.language)] || default_language_marquee;
      return current_language_marquee;
    });
    return fitler_language;
  }, [marqueeList, i18next]);
  return process_marquee;
};

export default useMarqueeList;
