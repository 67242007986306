import { useNavigate } from "react-router";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useMemo, useState } from "react";
import GameCatalog from "../../components/GameCatalog";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_WEB_PATH, ROULETTE_PATH } from "../../constant";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useDeviceType from "../../hook/useDeviceType";
import axios from "axios";
import Cookies from "js-cookie";

const Roulette = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_gamebox = (key) => t(`gamebox.${key}`);

  const navigate = useNavigate();

  const isMobile = useDeviceType() === "Mobile";

  const isWalletCurrency = useSelector((state) => state.isWalletCurrency);

  const [iframeIsLoading, setIframeIsLoading] = useState(true);

  const prcess_roulette_link = useMemo(() => {
    return `${ROULETTE_PATH}?oauth=${Cookies.get(
      "token"
    )}&currency=${isWalletCurrency}`;
  }, [isWalletCurrency]);

  return (
    <CommonOpacityBackground className={`!bg-[rgb(0,0,0,0.3)]`} path={-1}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        // 0A0D0F
        className="md:min-h-[248px] md:max-h-[calc(100vh-92px)] h-full w-full bg-black md:w-[500px] md:rounded-[10px] flex flex-col"
      >
        <CloseDetailPage
          title={i18n("LuckyRoulette")}
          className="!mb-0 px-[10px]" //傳className參數內容給CloseDetailPage
          titleClassName="text-white"
          customClose={
            <IoCloseOutline
              className="cursor-pointer hidden md:block"
              onClick={() => {
                navigate(-1);
              }}
            />
          }
        />
        <section className="overflow-y-scroll no-scrollbar md:min-h-[248px] bg-black md:max-h-[calc(100vh-92px)] h-full">
          <>
            {/* {iframeIsLoading && (
              <div className="h-full w-full flex flex-col gap-[5px] items-center bg-[#E0E0E0] justify-center">
                <img src={logo} className="w-[200px] h-[100px] object-cover" />
              </div>
            )} */}
            <section className="w-full overflow-hidden flex h-full">
              <iframe
                onLoad={() => {
                  setIframeIsLoading(false);
                }}
                src={prcess_roulette_link}
                className="h-full w-full"
              ></iframe>
            </section>
          </>
        </section>
      </motion.div>
    </CommonOpacityBackground>
  );
};

export default Roulette;
