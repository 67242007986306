import React from "react";
import { useTranslation } from "react-i18next";
// import PWAhintAndroid1 from "../../../images/App/PWAhintAndroid1.webp";
// import PWAhintAndroid2 from "../../../images/App/PWAhintAndroid2.webp";
import { COMMON_ALT } from "../../../constant";
import { appName } from "../../../config";

const Android = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  return (
    <>
      <img
        // src={PWAhintAndroid1}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt={COMMON_ALT}
      />
      <p>
        {i18n("You can add an icon to the")} Android{" "}
        {i18n("home screen to access")} {appName}.
      </p>
      <p>{i18n("HintAndroid")}</p>
      <img
        // src={PWAhintAndroid2}
        className="w-full object-cover rounded-[5px]"
        alt={COMMON_ALT}
      />
      <p>{i18n("HintAndroid2")}</p>
    </>
  );
};

export default Android;
