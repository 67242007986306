import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrentCurrencyIsCrypto = () => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const isCrypto = useMemo(() => {
    return isCurrencyExchange[walletCurrency]?.is_crypto === 1;
  }, [walletCurrency, isCurrencyExchange]);

  return isCrypto;
};

export default useCurrentCurrencyIsCrypto;
