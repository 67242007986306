import { motion, useWillChange, isValidMotionProp } from "framer-motion";
import { useNavigate } from "react-router";

import { useEffect, useState } from "react";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  chakra,
  Button,
  shouldForwardProp,
  useColorModeValue,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import PromoCardPc from "./components/promoCard_pc";
import PromoCardMobile from "./components/promoCard_mobile";
import { Link, NavLink } from "react-router-dom";
import SigninForm from "./signinForm";
import useCheckLogin from "../../hook/useCheckLogin";
import { resetInvalidAuth } from "../../redux/action/member/memberAction";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { MdOutlineArrowBack } from "react-icons/md";
import SigninFormV2 from "./signinForm_v2";
import { popFromBottom } from "../../animateConstant";
import CreditPage from "../../utils/credit/credit_page";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SigninV2 = () => {
  const { t } = useTranslation();
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const navigate = useNavigate();
  const previousPage = usePreviousPagePath();

  const baseBg = useColorModeValue("light.100", "dark.100");
  const mainBg = useColorModeValue("light.200", "dark.200");

  const dispatch = useDispatch();

  const willChange = useWillChange();

  const { isLogin } = useCheckLogin();

  useEffect(() => {
    dispatch(resetInvalidAuth());

    if (isLogin) {
      navigate("/");
    }
  }, []);

  return (
    <ChakraBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ willChange }}
      display="flex"
      flexDirection={`column`}
      justifyContent={`start`}
      alignItems="center"
      zIndex="10000"
      py={{
        base: "0px",
        md: "10vh",
      }}
      // px="10px"
      width={{
        base: "100%",
      }}
      height={{
        base: "100%",
      }}
      overflowY={`scroll`}
      position="fixed"
      top={`0px`}
      left={`0px`}
      className={`lg:backdrop-blur-none backdrop-blur-md no-scrollbar`}
    >
      <Box
        width={{
          md: "400px",
          base: "100%",
        }}
        height={{
          base: "100%",
          md: "auto",
        }}
        borderRadius={{
          base: "0px",
          md: "common",
        }}
        // borderTopRadius={{
        //   md: "none",
        // }}
        // borderBottomRadius={{
        //   md: "common",
        // }}
        shadow="md"
        bg={mainBg}
        pt="20px"
        // overflow="hidden"
      >
        <Box
          display="flex"
          justifyContent={`space-between`}
          mb="50px"
          px="20px"
        >
          <Link to={"../"}>
            <Icon as={MdOutlineArrowBack} color="light.100" fontSize="32px" />
          </Link>

          <Image
            src={stylesConfig.mainLogo}
            cursor={"pointer"}
            // mr={{
            //   base: "0px",
            //   lg: "45px",
            // }}
            // ml={{
            //   base: "0px",
            //   lg: "25px",
            // }}
            width={
              isLogin
                ? {
                    md: "80px",
                    base: "100px",
                  }
                : {
                    md: "105px",
                    base: "100px",
                  }
            }
            pointerEvents={"all"}
            alt={COMMON_ALT}
          />
        </Box>
        <Box>
          <Flex justifyContent={`center`} alignItems={`center`} mb="10px">
            <Flex gap="80px">
              <NavLink to="../signin">
                {({ isActive }) => {
                  return (
                    <Box
                      py="10px"
                      position="relative"
                      color={isActive ? "special.gold" : ""}
                      fontSize="20px"
                      _after={{
                        content: '""',
                        position: "absolute",
                        top: "90%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "120%",
                        height: "2px",
                        bg: isActive ? "special.gold" : "",
                      }}
                    >
                      <Text>{i18n_header("signin")}</Text>
                    </Box>
                  );
                }}
              </NavLink>
              {/* <CreditPage> */}
              {window.open_registration == 1 && (
                <NavLink to="../signup">
                  {({ isActive }) => {
                    return (
                      <Box
                        py="10px"
                        position="relative"
                        color={isActive ? "special.gold" : ""}
                        fontSize="20px"
                        _after={{
                          content: '""',
                          position: "absolute",
                          top: "90%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          width: "120%",
                          height: "2px",
                          bg: isActive ? "special.gold" : "",
                        }}
                      >
                        <Text>{i18n_header("signup")}</Text>
                      </Box>
                    );
                  }}
                </NavLink>
              )}

              {/* </CreditPage> */}
            </Flex>
          </Flex>
          <ChakraBox
            {...popFromBottom}
            width="100%"
            py="80px"
            px="50px"
            h={{
              base: "-webkit-fit-content",
              md: "auto",
            }}
            className="no-scrollbar"
            bg={baseBg}
            display="flex"
            flexDirection={`column`}
            borderTopLeftRadius={`120px`}
          >
            <SigninFormV2 />
          </ChakraBox>
        </Box>
      </Box>
    </ChakraBox>
  );
};

export default SigninV2;
