import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ResultPage from "../../components/ResultPage/ResultPage";
import { HiChevronLeft } from "react-icons/hi";
import { Box } from "@chakra-ui/react";

const WithdrawSuccess = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const navigate = useNavigate();

  return (
    <ResultPage
      buttonProps={{
        content: i18n("goToDepositData"),
        path: "/profile/transaction?tab=withdraw",
      }}
      resultContent={`${i18n("youCanGo")} ${i18n("depositRoute")}，${i18n(
        "view"
      )}
    ${i18n("depositData")}`}
      resultText={i18n("withdrawSuccess")}
      pageType="success"
      pageTitle={i18n("withdrawSuccess")}
      hasPrev={<Box></Box>}
    />
  );
};

export default WithdrawSuccess;
