import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { HiChevronLeft, HiCurrencyDollar } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RiUserFollowFill } from "react-icons/ri";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa";
import { waitTime } from "../../utils/waitTime";
import ConfirmModal from "./confirmModal";
import VerifyPage from "../../components/VerifyPage";

const SendPointsConfirm = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`settings.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);

  const navigate = useNavigate();

  const memId = localStorage.getItem("memId");
  const currency = localStorage.getItem("currency");

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const isMemberInfo = useSelector((state) => state.isMemberInfo);

  const [amount, setAmount] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const currency_balance = useMemo(() => {
    const exchange_amount = isCurrencyExchange?.[currency]?.exchange_amount;

    return exchange_amount;
  }, [isCurrencyExchange, currency]);

  const handleSubmit = async () => {
    setButtonLoading(true);

    await waitTime(1000);
    setConfirmModal(true);
    setButtonLoading(false);
  };

  return (
    <DetailWrapper
      closeAnimate
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("..")}
          />
        }
        title={i18n("send-points")}
      />

      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="10px"
        p="15px"
      >
        <VerifyPage verify={isMemberInfo?.is_turnover == 1}>
          <Flex alignItems={`center`} gap="5px">
            <Icon fontSize="xl" as={RiUserFollowFill} />
            {i18n("send_to")}
            <Text color="special.gold" as="u">
              {" "}
              {memId}
            </Text>
          </Flex>
          <Divider />
          <Flex direction={`column`}>
            <Text color="special.1" fontWeight={`bold`} fontSize="14px">
              {i18n("please_input_amount")}
            </Text>
            <Flex justifyContent={`space-between`} alignItems={`center`}>
              <NumberInput
                max={currency_balance}
                onChange={(v) => {
                  setAmount(v);
                  localStorage.setItem("amount", v);
                }}
                autoFocus
                value={amount}
                placeholder="100"
                bg="none"
                fontWeight={`bold`}
                my="5px"
              >
                <NumberInputField
                  border="none"
                  outline={`none`}
                  fontSize="36px"
                  px="0"
                  _focus={{
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  }}
                />
              </NumberInput>
              <Icon
                w="30px"
                fontSize="30px"
                color="special.gold"
                as={MdOutlineCurrencyExchange}
              />
            </Flex>
            <Text fontWeight={`bold`} fontSize="24px">
              {currency}
            </Text>
          </Flex>
          <Divider />
          <Flex alignItems={`center`} gap="5px">
            <Icon as={HiCurrencyDollar} color="special.gold" fontSize="25px" />
            <Flex direction={`column`}>
              <Text color="special.1" fontSize="14px">
                {i18n("your_currency_balance", { currency })}
              </Text>
              <Text fontSize="16px" fontWeight={`bold`}>
                {currency_balance}
              </Text>
            </Flex>
          </Flex>
          <Button
            variant={`brandPrimary`}
            rightIcon={<FaArrowRight />}
            isDisabled={!currency || !memId || amount <= 0}
            isLoading={buttonLoading}
            size="md"
            mt="10px"
            onClick={handleSubmit}
          >
            {i18n("input_transaction_password")}
          </Button>
        </VerifyPage>
      </Box>
      <ConfirmModal
        isOpen={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
      />
    </DetailWrapper>
  );
};

export default SendPointsConfirm;
