import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CURRENCY_MENU } from "../i18n/config";
import Cookies from "js-cookie";

const useCurrencyIcon = () => {
  const wallet_currency_code = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 存到wallet_currency_code變數中
  const memberInfo = useSelector((state) => state.isMemberInfo);
  const currencyList = useSelector((state) => state.isCurrencyExchange);

  const { currency_code } = memberInfo || {};

  const currency_icon = useMemo(() => {
    const find_current_symbol = currencyList?.[wallet_currency_code]?.symbol;
    const find_target = CURRENCY_MENU.find(
      (item) => item.iconBlack === find_current_symbol
    );
    const { icon } = find_target || {}; //解構賦值

    return icon || "";
  }, [wallet_currency_code, CURRENCY_MENU, currencyList]);

  return currency_icon;
};

export default useCurrencyIcon;
