import { useNavigate } from "react-router";

import { RiUserFill } from "react-icons/ri";
import { useEffect, useMemo, useRef, useState } from "react";
import useDeviceType from "../../hook/useDeviceType";
import IconCircleWrapper from "../Icon/IconCircleWrapper";
import { BiSolidBellRing } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Box, Flex, chakra, shouldForwardProp } from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";

const bellShakeAnimation = {
  rotate: [0, 20, -20, 20, -20, 0],
  transition: {
    duration: 0.5,
    ease: "easeInOut",
    times: [0, 0.2, 0.4, 0.6, 0.8, 1],
    repeat: Infinity,
    repeatDelay: 1,
  },
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SignInData = ({}) => {
  const deviceType = useDeviceType();

  const inboxList = useSelector((state) => state.isInboxList);

  const navigate = useNavigate();

  const hasUnRead = useMemo(() => {
    return inboxList?.filter((item) => item.status == "Unread")?.length > 0;
  }, [inboxList]);

  return (
    <Flex alignItems={`center`} gap="5px">
      <IconCircleWrapper
        divProps={{
          onClick: () => navigate("profile/inbox"),
        }}
      >
        {hasUnRead && (
          <Box
            position={`absolute`}
            top={`0px`}
            right={`0px`}
            width={`5px`}
            height={`5px`}
            borderRadius={`full`}
            bg="red"
          ></Box>
        )}
        <ChakraBox
          initial={{ rotate: 0 }}
          animate={hasUnRead && bellShakeAnimation}
          position="relative"
        >
          <BiSolidBellRing className="text-xl" />
        </ChakraBox>
      </IconCircleWrapper>

      <IconCircleWrapper
        divProps={{
          onClick: () => {
            if (deviceType === "Mobile") {
              navigate("profile");
            } else {
              navigate("profile/data");
            }
          },
          display: {
            base: "none",
            md: "flex",
          },
        }}
      >
        <RiUserFill className="text-xl" />
      </IconCircleWrapper>
    </Flex>
  );
};

export default SignInData;
