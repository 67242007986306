import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const CommonPopModal = ({ isOpen, onClose, title, children, size }) => {
  const { t } = useTranslation();
  const i18n_deposit = (key) => t(`deposit.${key}`);

  return (
    <Modal
      variant={`brandPrimary`}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button variant={`brandPrimary`} size="sm" onClick={onClose}>
            {i18n_deposit("iUnderstand")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommonPopModal;
