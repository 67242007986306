import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDeviceType from "../../hook/useDeviceType";
import { useNavigate } from "react-router";
import GameBoxHeader from "./components/gameBoxHeader";
import VipBar from "./components/vipBar";
import GameCatalog from "../../components/GameCatalog";
import { closeFullScreen } from "../../redux/action/game/gameAction";
import { getRandomSlice } from "../../utils/shuffle";
import {
  Box,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameWrap = ({ children }) => {
  const gameList = useSelector((state) => state.isGameList);
  const gameLink = useSelector((state) => state.isGameLink);
  const isOpenFullScreen = useSelector((state) => state.isOpenFullScreen);
  const isVipBarOpen = useSelector((state) => state.isVipBar);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const gameConfig = useSelector((state) => state.isGameConfig);

  const { randomlySelectedGames, topGameList } = isHandleAllGame;

  const vipBarBg = useColorModeValue("light.200", "dark.200");

  const topGameRandom = useMemo(() => {
    return getRandomSlice(topGameList);
  }, [gameConfig]);

  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [gameLink]);

  useEffect(() => {
    dispatch(closeFullScreen());
  }, []);

  return deviceType === "PC" || deviceType === "Tablet" ? (
    <>
      <Box
        as="section"
        pt={{
          xl: isOpenFullScreen ? "0px" : "20px",
        }}
        mb={{
          xl: "0px",
          md: "20px",
        }}
        position={`${isOpenFullScreen ? "fixed" : "relative"}`}
        top="0px"
        left="0px"
        w={isOpenFullScreen ? "100%" : ""}
        h={isOpenFullScreen ? "100%" : ""}
        zIndex={`${isOpenFullScreen ? "1000" : "0"}`}
      >
        <Box
          as="section"
          w={{
            lg: isOpenFullScreen ? "" : "820px",
            xl: isVipBarOpen ? "100%" : isOpenFullScreen ? "" : "1128px",
          }}
          h={isOpenFullScreen || "588px"}
          display={`flex`}
          mx="auto"
          borderRadius={`common`}
          overflow="hidden"
        >
          <Box as="section" w={isVipBarOpen ? "calc(100% - 280px)" : "100%"}>
            <GameBoxHeader />
            {children}
          </Box>
          {isVipBarOpen && (
            <ChakraBox
              as="section"
              w="280px"
              overflowY={{
                md: "scroll",
              }}
              bg={vipBarBg}
              className={`no-scrollbar`}
            >
              <VipBar />
            </ChakraBox>
          )}
        </Box>
        <Box mx="auto" p="10px">
          {/* <UserClickGameCatalog /> */}
          <GameCatalog
            wrapperClassName={`!my-0`}
            list={topGameRandom}
            mainTitle={`Top`}
            swiperProps={{
              customPerView: "game-box-layout",
            }}
          />
        </Box>
      </Box>
    </>
  ) : (
    children
  );
};

export default GameWrap;
