import React from "react";
import CommonPopModal from "../../../components/Common/commonPopModal";
import { useTranslation } from "react-i18next";

const WithdrawTerm = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);
  const i18n_deposit = (key) => t(`deposit.${key}`);
  return (
    <CommonPopModal
      title={i18n("terms.title")}
      isOpen={isOpen}
      onClose={onClose}
      children={
        <div className="max-w-4xl mx-auto p-4">
          <p className="mb-6">{i18n("terms.intro")}</p>
          <ol className="list-decimal list-inside space-y-2">
            <li>{i18n("terms.list.item1")}</li>
            <li>{i18n("terms.list.item2")}</li>
            <li>{i18n("terms.list.item3")}</li>
            <li>{i18n("terms.list.item4")}</li>
            <li>{i18n("terms.list.item5")}</li>
            <li>{i18n("terms.list.item6")}</li>
          </ol>
          <p className="mt-6 mb-4">{i18n("terms.warning")}</p>
          <p className="mb-4">{i18n("terms.verification")}</p>
          <p>{i18n("terms.termination")}</p>
        </div>
      }
    />
  );
};

export default WithdrawTerm;
