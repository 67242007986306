import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  chakra,
  shouldForwardProp,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NotFound from "../../ProgressBlock/NotFound";
import LoadingBlock from "../../Loading/LoadingBlock";
import useDeviceType from "../../../hook/useDeviceType";
import GAMEITEM from "../../GAMEITEM/GAMEITEM";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import GAMEITEMV2 from "../../GAMEITEM/GAMEITEM_v2";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { useTranslation } from "react-i18next";
import SubListSelector from "../SubListSelector/SubListSelector";
import TitleIcon from "../PlatformSelector/components/titleIcon";
import { menuIcon } from "../../Menu/helper/menuIcon";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { menuIconV2 } from "../../Menu/helper/menuIcon_v2";
import GAMEITEMV3 from "../../GAMEITEM/GAMEITEM_v3";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameSelector = ({
  subListSelector,
  hiddenTitle,
  gameMainTypeFromProps,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const scrollRef = useRef();

  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { gameMainType, gamePlatform, subType } = useParams();

  const isMobile = useDeviceType() === "Mobile";

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const {
    sortedByPlatformGames,
    sortedByPlatformAndCategoryGames,
    gameList,
    gameListByTypeAndSubType,
    allGames,
    favoriteGameList,
    randomlySelectedGames,
    newGameList,
    topGameList,
    allGameType,
  } = isHandleAllGame || {};
  const platformGamesList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const current_game_type = routeMapping(mainType)?.origin;

    const gameTypeCode = allGameType?.find(
      (t) => t.gameTypeName === current_game_type
    )?.gameTypeUid;
    if (subType) {
      const determineIsAllPlatform = (gameList) => {
        if (gamePlatform === "all-platform") {
          return (
            gameList?.filter((game) => game.gameTypeUid === gameTypeCode) || []
          );
        } else {
          return (
            gameList
              ?.filter((game) => game.gameTypeUid === gameTypeCode)
              ?.filter(
                (game) =>
                  game.game_platform === gamePlatform ||
                  game.SubPlatformEnum === gamePlatform
              ) || []
          );
        }
      };
      switch (subType) {
        case "AllGames":
          return determineIsAllPlatform(allGames);
        case "MyFavorite":
          return determineIsAllPlatform(favoriteGameList);
        case "ClickedGames":
          return determineIsAllPlatform(randomlySelectedGames);
        case "News":
          return determineIsAllPlatform(newGameList);
        case "Top":
          return determineIsAllPlatform(topGameList);
        default:
          if (gamePlatform === "all-platform") {
            return (
              gameListByTypeAndSubType?.[current_game_type]?.[subType]?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              ) || []
            );
          } else {
            return (
              sortedByPlatformAndCategoryGames?.[gamePlatform]?.[subType] || []
            );
          }
      }
    }
    if (gameList && gameMainType && gamePlatform) {
      return gameList?.[current_game_type]?.[gamePlatform] || [];
    } else {
      return Object?.values(gameListByTypeAndSubType?.[current_game_type] || [])
        ?.flat()
        ?.filter((game) => game.gameTypeUid === gameTypeCode)
        ?.slice(0, 150);
    }
  }, [
    gameList,
    gamePlatform,
    gameMainType,
    subType,
    sortedByPlatformAndCategoryGames,
    gameListByTypeAndSubType,
    gameMainTypeFromProps,
    allGameType,
  ]);

  const ITEMS_PER_PAGE = useBreakpointValue({
    base: 12,
    md: 25,
    lg: 35,
    xl: 32,
  });

  const loadMore = useCallback(() => {
    setTimeout(() => {
      const moreItems = platformGamesList?.slice(
        0,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
      if (moreItems?.length >= displayedItems?.length) {
        setDisplayedItems(moreItems);
        setIsLoading(false);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 300);
  }, [currentPage, displayedItems?.length, platformGamesList]);

  const hasMoreItems = useMemo(() => {
    return displayedItems?.length < platformGamesList?.length;
  }, [displayedItems?.length]);

  useEffect(() => {
    setDisplayedItems(platformGamesList?.slice(0, ITEMS_PER_PAGE));
  }, [platformGamesList, ITEMS_PER_PAGE]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
    setDisplayedItems(platformGamesList?.slice(0, ITEMS_PER_PAGE));
  }, [gamePlatform, subType, gameMainType]);

  useEffect(() => {
    if (scrollRef.current) {
      if (subType || gamePlatform) {
        const elementPosition = scrollRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 100;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [subType, gamePlatform]);

  return (
    <Box
      ref={scrollRef}
      // borderTopRadius={`common`}
      // borderTop="3px solid"
      // borderColor={`brand.400`}
      // bgGrdi="linear(to-b, transparent, dark.500 400%)"
      // px="common-y"
    >
      {hiddenTitle ? null : (
        <Box mb="10px">
          <Flex alignItems={`center`} gap="5px">
            {/* <TitleIcon icon={menuIcon(subType || "AllGames", "icon")} /> */}
            <TitleIcon
              icon={menuIconV2(gameMainTypeFromProps || gameMainType)?.icon}
            />
            <Box display="flex" alignItems={`center`}>
              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {i18n(gameMainTypeFromProps || gameMainType)}
                {/* {subType ? `｜${i18n(subType)}` : `｜${i18n("all-platform")}`}
            {subType ? `｜${i18n(subType)}` : ""} */}
              </Text>
              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {gamePlatform
                  ? gamePlatform === "all-platform"
                    ? `｜${i18n("all-platform")}`
                    : `｜${gamePlatform}`
                  : null}
              </Text>

              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {subType ? (
                  <>
                    ｜{/* <TitleIcon icon={menuIcon(subType, "icon")} /> */}
                    {i18n(subType)}
                  </>
                ) : (
                  <>
                    ｜
                    {/* <TitleIcon icon={menuIcon(subType || "AllGames", "icon")} /> */}
                    {i18n("all-platform")}
                  </>
                )}
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {subListSelector && (
        <Box mb="10px">
          <SubListSelector
            gameMainTypeFromProps={gameMainTypeFromProps}
            forceShowAll={!gamePlatform || gamePlatform == "all-platform"}
          />
        </Box>
      )}
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreItems}
        useWindow={false}
        loader={<LoadingBlock commonLoading={true} />}
      >
        <LoadingBlock commonLoading={isLoading}>
          {displayedItems?.length === 0 && <NotFound />}
          <ChakraBox {...gameListFromBottom}>
            <Grid
              templateColumns={{
                base: "repeat(3, minmax(0, 1fr))",
                md: "repeat(4, minmax(0, 1fr))",
                lg: "repeat(7, minmax(0, 1fr))",
                xl: "repeat(7, minmax(0, 1fr))",
                "2xl": "repeat(7, minmax(0, 1fr))",
              }}
              rowGap={"6px"}
              columnGap="8px"
            >
              {displayedItems?.map((item, index) => {
                const gameName =
                  item?.[
                    `gameName${languageTransfer({
                      lang: i18next.language,
                    })}`
                  ];
                const gameNameEn = item?.gameNameEn;
                const platform = item?.game_platform;
                const gameSubType = item?.game_subtype;

                const gameCode = item?.gameCode;
                const gameType = item?.gameType;
                return (
                  <GridItem
                    colSpan={index === 0 ? 2 : 1}
                    rowSpan={index === 0 ? 2 : 1}
                    pb="2.5px"
                    bg="brand.900"
                    borderRadius={"25px"}
                  >
                    <GAMEITEMV3
                      gameProps={{
                        gameName,
                        platform,
                        gameCode,
                        gameType,
                        gameNameEn,
                        gameSubType,
                      }}
                      originProps={item}
                      itemClass={`!h-[155px]`}
                      itemIndex={index}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </ChakraBox>
        </LoadingBlock>
      </InfiniteScroll>
    </Box>
  );
};

export default GameSelector;
