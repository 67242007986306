import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiQrCodeFill } from "react-icons/ri";
import { TbBrandTelegram } from "react-icons/tb";

export const methodIcon = (method) => {
  switch (method) {
    case "phcode":
    case "qrcode":
      return RiQrCodeFill;
    case "phlink":
    case "link":
      return TbBrandTelegram;
    default:
      return BsFillQuestionCircleFill;
  }
};
