import React, { useState, useEffect } from "react";

const isMobileUserAgent = () => {
  const userAgent =
    typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  return (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    ) && !/iPad|Tablet/i.test(userAgent)
  );
};

const isTabletUserAgent = () => {
  const userAgent =
    typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  return /iPad|Tablet/i.test(userAgent);
};

const useDeviceType = () => {
  if (isMobileUserAgent()) {
    return "Mobile";
  } else if (isTabletUserAgent()) {
    return "Tablet";
  } else {
    return "PC";
  }
};

export default useDeviceType;
// "w-[660px] h-[min(865px,70vh)] rounded-xl overflow-scroll no-scrollbar";

export const getFixedStyle = (deviceType) => {
  const commonStyle = "fixed top-0 left-0 w-full h-full px-[10px] z-[99999]";
  const pcStyle = "pr-[15px]";

  return `${
    isMobileUserAgent() ? commonStyle : pcStyle
  } pb-[15px] no-scrollbar`;
};
