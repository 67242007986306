import { FaArrowLeft } from "react-icons/fa";

import { useLocation, useNavigate } from "react-router";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import CloseComponent from "./CloseComponent";
import useDeviceType from "../../hook/useDeviceType";
import { HiChevronLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CloseDetailPage = ({
  title,
  hasPrev,
  customClose,
  className,
  titleClassName,
  notBg,
}) => {
  //className是用來接roulette.js傳來的
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const navigate = useNavigate();
  const previousPage = usePreviousPagePath();

  const titleColor = useColorModeValue("dark.100", "light.100");
  const headerColor = useColorModeValue("light.200", "dark.200");
  const deviceType = useDeviceType();

  return (
    <Box
      as="section"
      h="60px"
      mb="7px"
      py="common-y"
      top="0px"
      bg={notBg ? "" : headerColor}
      zIndex={1}
      position={"sticky"}
      display="flex"
      alignItems="center"
      justifyContent={`space-between`}
      className={`${className}`}
    >
      <Box
        display="flex"
        alignItems={`center`}
        color="special.100"
        fontSize="4xl"
      >
        {hasPrev ? (
          hasPrev
        ) : deviceType === "Mobile" ? (
          <Link to="../">
            <HiChevronLeft className="cursor-pointer" />
          </Link>
        ) : (
          <Box w="20px"></Box>
        )}
      </Box>
      <Text
        mb="0px"
        fontSize="xl"
        fontWeight="semibold"
        textAlign="center"
        position={`absolute`}
        w="70%"
        color={titleColor}
        className={`${titleClassName} text-overflow absolute-center`}
      >
        {title}
      </Text>
      <Flex alignItems={`center`} color="special.100" fontSize={`4xl`}>
        {customClose || <CloseComponent />}
      </Flex>
    </Box>
  );
};

export default CloseDetailPage;
