import { isValidMotionProp, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { IoCheckmarkCircleSharp, IoCloseOutline } from "react-icons/io5";
import { HiChevronLeft } from "react-icons/hi";

import { animatePage } from "../../animateConstant";
import GetParams from "../../utils/getParams";
import ErrorHint from "../../components/Hint/ErrorHint";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { formatNumber } from "../../utils/formatNumber";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Skeleton,
  Stack,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { methodIcon } from "./components/methodIcon";
import CustomInput from "../../components/InputComponents/customInput";
import { AiFillDollarCircle } from "react-icons/ai";
import { waitTime } from "../../utils/waitTime";
import LoadingContainer from "../../components/Loading/LoadingContainer";
import { getThirdPartyPayment } from "../../api/getApi";
import DetailWrapper from "../../components/detailWrapper";
import CopyIcon from "../../components/CopyComponent/CopyIcon";
import { openChat } from "../../redux/action/member/memberAction";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DepositStepVip = ({}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`deposit.${key}`, { ...props });

  const [data, setData] = useState({
    account_number: "",
    account_name: "",
    bank_name: "",
    currency_code: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [paymentList, setPaymentList] = useState({});
  const { account_number, account_name, bank_name, currency_code, image_path } =
    data || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const methodBoxBg = useColorModeValue("light.100", "dark.100");
  const iconColor = useColorModeValue("light.400", "dark.200");

  const { third_party, vip } = paymentList || {};

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const method = GetParams("method");
  const min = Number(GetParams("min"));
  const max = Number(GetParams("max"));
  const isCrypto = GetParams("isCrypto") == 1;
  const { uid } = useParams();

  const find_payment_method = useMemo(() => {
    return vip?.find((item) => {
      return item.uid == uid;
    });
  }, [method, paymentList, uid]);

  const handleSubmit = async () => {
    dispatch(openChat());
  };

  const handleSelectMethod = (method) => {
    setData({ ...data, method_type: method, amount: 0 });
  };

  const form_self_list = [
    {
      label: i18n("your_order_number"),
      name: "deposit_number",
      type: "text",
      value: localStorage.getItem("deposit_number"),
    },
    {
      label: i18n("deposit_currency"),
      name: "currency_code",
      type: "text",
      value: currency_code,
    },
    {
      label: i18n("deposit_amount"),
      name: "deposit_amount",
      type: "number",
      min: 100,
      value: localStorage.getItem("deposit_amount"),
    },
  ];

  const form_list = [
    {
      label: "account_name",
      type: "text",
      isDisabled: true,
    },
    {
      label: "account_number",
      type: "text",
      isDisabled: true,
    },
    {
      label: "bank_name",
      type: "text",
      isDisabled: true,
    },
    {
      label: "currency_code",
      type: "text",
      isDisabled: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setApiLoading(true);
      await getThirdPartyPayment().then((data) => {
        const vip = data?.vip || [];
        const third_party = data?.third_party || [];
        setPaymentList({
          vip,
          third_party,
        });
      });
      setApiLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setData(find_payment_method);
  }, [find_payment_method]);

  return (
    <DetailWrapper>
      <ChakraBox
        h="auto"
        display="flex"
        flexDirection={`column`}
        justifyContent={`space-between`}
        animate="visible"
        variants={animatePage}
      >
        <Box as="section">
          <CloseDetailPage
            hasPrev={
              <HiChevronLeft
                className="cursor-pointer"
                onClick={() => navigate("../deposit")}
              />
            }
            title={
              <Text
                as="span"
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
              >
                {method?.toUpperCase()}
              </Text>
            }
          />

          {/* <Flex alignItems={`center`} justifyContent={`center`} mb="30px">
            <Image
              src={`${COMMON_WEB_PATH}/paymentIcon/${method?.toLowerCase()}pay.png`}
              className="!w-[50px] !h-[50px] object-cover"
            />
          </Flex> */}
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            minH="150px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {i18n("deposit_order_sent")}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {i18n("deposit_order_hint")}
            </AlertDescription>
          </Alert>
          <Divider borderColor="gray.300" my="10px" />

          <Box as="section">
            <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={"green.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("your_deposit_order_information")}
            </Text>
          </Box>
          <Stack mt="10px" spacing="20px">
            {form_self_list.map((item) => {
              return (
                <Box>
                  <Text as="p" fontSize="sm" mb="0">
                    {item.label}
                  </Text>
                  <Skeleton isLoaded={!apiLoading}>
                    <Flex
                      alignItems="center"
                      fontSize="lg"
                      fontWeight={`semibold`}
                      gap="5px"
                    >
                      <Text as="p" mb="0">
                        {item.value}
                      </Text>
                      <CopyIcon copyLink={item.value} />
                    </Flex>
                  </Skeleton>
                </Box>
              );
            })}
          </Stack>
          <Divider borderColor="gray.300" my="10px" />
          <Box as="section">
            <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={"green.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("company_bank_account_information")}
            </Text>
          </Box>
          <Stack mt="10px" spacing="20px">
            {form_list.map((item) => {
              return (
                <Box>
                  <Text as="p" fontSize="sm" mb="0">
                    {i18n(`company_${item.label}`)}
                  </Text>
                  <Skeleton isLoaded={!apiLoading}>
                    <Flex
                      alignItems="center"
                      fontSize="lg"
                      fontWeight={`semibold`}
                      gap="5px"
                    >
                      <Text as="p" mb="0">
                        {data?.[item.label]}
                      </Text>
                      <CopyIcon copyLink={data?.[item.label]} />
                    </Flex>
                  </Skeleton>
                </Box>
              );
            })}
          </Stack>
          <Divider borderColor="gray.300" my="10px" />
          <Alert mb="10px" status="warning">
            <AlertIcon />
            {i18n("deposit_order_hint")}
          </Alert>
          <Box as="section">
            <Button
              type="button"
              onClick={handleSubmit}
              variant={`brandPrimary`}
              w="100%"
              isLoading={buttonLoading}
              size="lg"
            >
              {i18n("contact_service")}
            </Button>
          </Box>
        </Box>
      </ChakraBox>
    </DetailWrapper>
  );
};

export default DepositStepVip;
