import React, { useEffect, useState } from "react";
import {
  getDepositTransaction,
  getSendPointsTransaction,
  getTransactionLog,
} from "../../../api/getApi";
import { Box, Flex } from "@chakra-ui/react";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import NotFound from "../../../components/ProgressBlock/NotFound";
import RecordDashboard from "./recordDashboard";
import Paginator from "./paginator";
import { waitTime } from "../../../utils/waitTime";

const SendPointsTransaction = ({ datePicker } = {}) => {
  const { str_date, end_date } = datePicker || {};
  const [record, setRecord] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    from: null,
    to: null,
    total: null,
  });
  const [apiCalling, setApiCalling] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setApiCalling(true);
      await getTransactionLog({
        paramsData: {
          start_time: str_date,
          end_time: end_date,
          limit: 25,
          current_page: currentPage,
        },
      }).then((res) => {
        const { current_page, last_page, from, to, total, data } = res?.data;
        //   setRecord(data?.data?.data);
        setPagination({
          current_page,
          last_page,
          from,
          to,
          total,
        });
        setRecord(data);
      });

      await waitTime(1000);
      setApiCalling(false);
    };
    fetchData();
  }, [datePicker, currentPage]);

  return (
    <Flex w="100%" direction={`column`} gap={4} spacing={4}>
      <LoadingBetRecord commonLoading={apiCalling}>
        {record?.length === 0 ? (
          <NotFound />
        ) : (
          record?.map((item, index) => {
            return (
              <RecordDashboard
                single={index % 2 === 0}
                method={"SEND POINTS"}
                props={{
                  ...item,
                  deposit_number: item.transhash,
                  creation_date: item.created_at,
                  transfer_point: item.transfer_point,
                  transfer_after: item.transfer_after,
                  status: 2,
                  action: item.action,
                  counterpart: item.counterpart,
                }}
              />
            );
          })
        )}
      </LoadingBetRecord>
      <Box>
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
        />
      </Box>
    </Flex>
  );
};

export default SendPointsTransaction;
