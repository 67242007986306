import Cookies from "js-cookie";

const WalletCurrencyReducers = (
  state = Cookies.get("wallet_currency") ||
    process.env.REACT_APP_DEFAULT_CURRENCY,
  action
) => {
  switch (action.type) {
    case "storeWalletCurrency":
      return action.payload;
    default:
      return state;
  }
};

export default WalletCurrencyReducers;
