import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { closeColorMode } from "../../../redux/action/member/memberAction";
import ThemeTab from "./themeTab";

const ColorModePage = () => {
  const dispatch = useDispatch();

  const onClose = async () => {
    await dispatch(closeColorMode());
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={true}
      p="5px"
      variant={`brandPrimary`}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent h={250}>
        <ModalHeader>顏色樣式設置</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={200}>
          <Box
            as="section"
            display="flex"
            flexDirection={`column`}
            gap="5px"
            borderRadius={`common`}
            p="8px"
          >
            <ThemeTab />
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ColorModePage;
