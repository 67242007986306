import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  closeOpenFreezeModal,
  openChat,
} from "../../redux/action/member/memberAction";
import Cookies from "js-cookie";

const StatusModal = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const isOpenFreezeModal = useSelector((state) => state.isOpenFreezeModal);
  const dispatch = useDispatch();

  const { status } = memberData || {};

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpenFreezeModal) {
      setOpen(true);
    }
    if (Cookies.get(`status_2`) == 1) {
      return;
    }
    if (status == 2) {
      setOpen(true);
    }
  }, [status, isOpenFreezeModal]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        dispatch(closeOpenFreezeModal());
      }}
      size={`sm`}
      isOpen={open}
      variant={`brandPrimary`}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n("notice_you")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{i18n("notice_content")}</Text>
          <Box fontWeight="bold">
            <Text>{i18n("1.withdraw")}</Text>
            <Text>{i18n("2.deposit")}</Text>
            <Text>{i18n("3.playGame")}</Text>
            <Text>{i18n("4.getReward")}</Text>
            <Text>{i18n("5.roulette")}</Text>
          </Box>
          <Text
            onClick={() => {
              dispatch(openChat());
            }}
            as="u"
            cursor="pointer"
            color="blue.500"
          >
            {i18n("question")}
          </Text>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex gap="10px" alignItems={`center`} justifyContent={`center`}>
            <Button
              onClick={() => {
                setOpen(false);
                dispatch(closeOpenFreezeModal());
              }}
              variant={`brandPrimary`}
            >
              {" "}
              {i18n("iknow")}
            </Button>
            <Checkbox
              my="10px"
              variant={`brandPrimary`}
              defaultChecked={Cookies.get(`status_2`) == 1}
              onChange={() => {
                if (Cookies.get(`status_2`) == 1) {
                  Cookies.remove(`status_2`);
                } else {
                  Cookies.set(`status_2`, 1, {
                    expires: 999,
                  });
                }
              }}
            >
              <Text fontSize="xs">{i18n("noRemind")}</Text>
            </Checkbox>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StatusModal;
