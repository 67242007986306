import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { RiCustomerService2Fill } from "react-icons/ri";
import ResultPage from "../../../components/ResultPage/ResultPage";
import { useDispatch } from "react-redux";
import { openChat } from "../../../redux/action/member/memberAction";

const AlreadyBind = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <ResultPage
      buttonProps={{
        content: i18n_common("contactCustomerServiceNow"),
        onClick: () => {
          dispatch(openChat());
        },
      }}
      resultContent={`${i18n("contactCustomerService")}`}
      resultText={i18n("youAlreadyBindCard")}
      pageType="service"
      pageTitle={i18n("alreadyBindCard")}
      hasPrev={true} //是否有上一頁 把hasPrev設為true傳給ResultPage
    />
  );
};

export default AlreadyBind;
