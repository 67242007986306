import { parseSomething } from "../utils/parseSomething";

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        red: {
          brand: {
            300: "#FFA3A3",
            500: "#FF6666",
            600: "#F04D4D",
            700: "#D43232",
            900: "#A32222",
          },
          special: {
            1: "#8fa8aa",
            gold: "#FF6666",
          },
          "game-item": {
            mask: "rgba(255, 102, 102, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "right-promotion-box": {
            to: "#ffb3b3",
            from: "#ffe1e1",
            border: "#F04D4D",
            hover_to: "#FF6666",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#ff8c8c",
          },
        },
        blue: {
          brand: {
            300: "#89C0F5",
            500: "#67A9E0",
            600: "#4F91CC",
            700: "#3B7AB8",
            900: "#2A5E95",
          },
          special: {
            1: "#8fa8aa",
            gold: "#89C0F5",
          },
          "game-item": {
            mask: "rgba(137, 192, 245, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "right-promotion-box": {
            to: "#b3dfff",
            from: "#e1f2ff",
            border: "#4F91CC",
            hover_to: "#67A9E0",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#8dc9ff",
          },
        },
        green: {
          brand: {
            300: "#B7FF64",
            500: "#A2E04A",
            600: "#8FCC39",
            700: "#7DBA29",
            900: "#5E8A1C",
          },
          special: {
            1: "#8fa8aa",
            gold: "#B7FF64",
          },
          "game-item": {
            mask: "rgba(183, 255, 100, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "right-promotion-box": {
            to: "#bfffa3",
            from: "#e3ffbf",
            border: "#5E8A1C",
            hover_to: "#7DBA29",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#a3e07f",
          },
        },
        purple: {
          brand: {
            300: "#E3C3FF",
            500: "#C299FF",
            600: "#A366FF",
            700: "#8C4DF0",
            900: "#7327D4",
          },
          special: {
            1: "#8fa8aa",
            gold: "#C299FF",
          },
          "game-item": {
            mask: "rgba(178, 102, 255, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "right-promotion-box": {
            to: "#d9b7ff",
            from: "#f3e1ff",
            border: "#8C4DF0",
            hover_to: "#A366FF",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#b184f0",
          },
        },
        yellow: {
          brand: {
            300: "#FFAA5E",
            500: "#fec00e",
            600: "#e9b00e",
            700: "#e9b00e",
            900: "#bd8e08",
          },
          special: {
            1: "#8fa8aa",
            gold: "#ffc700",
          },
          "game-item": {
            mask: "rgba(254, 192, 14, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "right-promotion-box": {
            to: "#ffc881",
            from: "#ffe5bf",
            border: "#866b25",
            hover_to: "#ff892e",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#ffbc44",
          },
        },
        "deep-blue": {
          dark: {
            100: "#232A37",
            150: "#232937",
            200: "#171B27",
            300: "#0F0D1A",
            400: "#110F1E",
            500: "#0B0916",
            600: "#090714",
            700: "#4F5C78",
          },
          brand: {
            100: "#95C8FE",
            300: "#567cf5",
            500: "#3a66f3",
            600: "#1e50f1",
            700: "#0e41e3",
            900: "#0a30aa",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(58, 102, 243, 0.4)",
          },
          leaderBoard: {
            left: "#242D4C",
            right: "#33406B",
            border: "#0f1428",
          },
          "second-brand": {
            500: "#7392f6",
            700: "#0c38c7",
          },
          "right-promotion-box": {
            to: "#567cf5",
            from: "#7392f6",
            border: "#1e50f1",
            hover_to: "#3a66f3",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#567cf5",
          },
        },
        "light-green": {
          dark: {
            100: "#191C33",
            200: "#110F1C",
            300: "#0F0D1A",
            400: "#110F1E",
            500: "#0B0916",
            600: "#090714",
          },
          brand: {
            300: "#A4F3CF",
            500: "#53DEA1",
            600: "#45C38A",
            700: "#42BA84",
            900: "#319764",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#8fa8aa",
            gold: "#53DEA1",
          },
          "game-item": {
            mask: "rgba(83, 222, 161, 0.4)",
          },
          "second-brand": {
            500: "#b7ff64",
            700: "#09d7eb",
          },
          "header-gradient": {
            to: "#52dcab",
            from: "#7ef5d3",
          },
          "right-promotion-box": {
            to: "#A4F3CF",
            from: "#D6F7E9",
            border: "#42BA84",
            hover_to: "#53DEA1",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#78E8B2",
          },
        },
      }
    : parseSomething(window.web_style_config);
