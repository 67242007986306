import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import useDeviceType from "../../hook/useDeviceType";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useEffect, useMemo, useRef, useState } from "react";
import GameBoxMore from "./components/gameBoxMore";
import { enterPlayGame } from "../../api/postApi";
import { isEmptyObject } from "../../utils/isEmptyObject";
import {
  storeGameConfig,
  storeGameLink,
  openFullScreen,
  closeVipBar,
  startGameLoading,
  endGameLoading,
} from "../../redux/action/game/gameAction";
import { Base64 } from "js-base64";
import { BiSolidError } from "react-icons/bi";
import useGetNowGame from "../../hook/useGetNowGame";
import { useTranslation } from "react-i18next";
import useGameIsHasDescription from "./gameDescription/gameIsHasDescription";
import {
  openChat,
  openGameDescriptionPop,
} from "../../redux/action/member/memberAction";
import Cookies from "js-cookie";
import NoticeTransfer from "./components/noticeTransfer";
import i18next from "i18next";
import { MdOutlineScreenShare } from "react-icons/md";
import useTransferMode from "../../hook/useTransferMode";
import ToolTab from "./components/toolTab";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import SupportCurrency from "./components/supportCurrency";
import useCurrentCurrencyIsCrypto from "../../hook/useCurrentCurrencyIsCrypto";
import { languageMapping } from "../../i18n/config";
import FreezeWrapper from "../StatusModal/FreezeWrapper";

const GameBoxWrapper = ({ children, isOpenFullScreen }) => {
  return (
    <Box
      h={{
        base: isOpenFullScreen ? "calc(100vh - 47.5px)" : "100vh",
        lg: isOpenFullScreen ? "calc(100vh - 47.5px)" : "100%",
      }}
      borderBottomRadius={{
        md: "xl",
      }}
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      color={`white`}
      w="100%"
      bg="gamebox.base"
    >
      {children}
    </Box>
  );
};

const GameBox = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);

  const dispatch = useDispatch();
  const gameLink = useSelector((state) => state.isGameLink);
  const isOpenFullScreen = useSelector((state) => state.isOpenFullScreen);
  const gameConfig = useSelector((state) => state.isGameConfig);
  const gameTrigger = useSelector((state) => state.isTriggerGameLink);
  const gameLoading = useSelector((state) => state.isGameLoading);
  const isAccessToolOpen = useSelector((state) => state.isAccessTool);
  const walletCurrency = useSelector((state) => state.isWalletCurrency);

  const isMobile = useDeviceType() === "Mobile";

  const [showBar, setShowBar] = useState(true);
  const [toolbarHint, setToolbarHint] = useState(true);
  const [showGameBoxMore, setShowGameBoxMore] = useState(false);
  const [showAlertPop, setShowAlertPop] = useState(false);
  const [testing, setTesting] = useState(false);
  const [triggerTransferPop, setTriggerTransferPop] = useState(false);
  const [isNotSupportIframe, setIsNotSupportIframe] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isTransferModePop, setIsTransferModePop] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [cryptoModal, setCryptoModal] = useState(false);
  const [cryptoButtonTrigger, setCryptoButtonTrigger] = useState(false);
  const [isHtmlCode, setIsHtmlCode] = useState(false);

  const isTransferMode = useTransferMode();

  const walletCurrencyNow = useSelector((state) => state.isWalletCurrency); //從redux裡取isWalletCurrency的值 存到WalletCurrencyNow
  const gameCurrencyNow = useSelector((state) => state.isGameCurrency); //從redux裡取isWalletCurrency的值 存到gameCurrencyNow
  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();

  const confirmBg = useColorModeValue("dark.200");

  useEffect(() => {
    if (isTransferMode) {
      setIsTransferModePop(true);
    } else {
      setIsTransferModePop(false);
    }
  }, [isTransferMode]);

  const nowGameConfig = useGetNowGame();

  useEffect(() => {
    if (isEmptyObject(gameConfig)) {
      dispatch(storeGameConfig(nowGameConfig));
    }
  }, [nowGameConfig]);

  useEffect(() => {
    setIsHtmlCode(false);

    if (currentCurrencyIsCrypto) {
      setCryptoModal(true);
    }
  }, [gameConfig?.gameUid, currentCurrencyIsCrypto]);

  useEffect(() => {
    if (firstRender) {
      dispatch(closeVipBar());
      setFirstRender(false);
      return;
    }
    if (cryptoModal) {
      return;
    }
    dispatch(startGameLoading());
    setShowAlertPop(false);
    if (!isEmptyObject(gameConfig) && walletCurrencyNow) {
      enterPlayGame({
        gameUid: gameConfig.gameUid,
        gameCode: gameConfig.gameCode,
        isMobile: isMobile ? 2 : 1,
        lang: languageMapping(i18next.language),
        gameCurrencyNow: currentCurrencyIsCrypto
          ? gameCurrencyNow
          : walletCurrencyNow,
        walletCurrencyNow: walletCurrencyNow,
      })
        .then((data) => {
          setIsNotSupportIframe(data.not_supported_iframe);
          if (data.not_supported_iframe) {
            const newWindow = window.open("", "_blank");

            const game_link = Base64.decode(data.url);
            newWindow.location.href = game_link;
          }
          dispatch({ type: "GET_BALANCE" });

          if (!data.not_supported_iframe) {
            if (
              gameConfig?.game_type === "LIVE" ||
              gameConfig?.game_type === "SPORT" ||
              gameConfig?.game_type === "LOTTERY"
            ) {
              dispatch(openFullScreen());
            }
          }
          setShowAlertPop(false);
          setIsPortrait(data.portrait); //這裡從後端拿到的portrait判斷是直式還是橫式

          if (data.is_html_code) {
            setIsHtmlCode(true);
          } else {
            setIsHtmlCode(false);
          }

          dispatch(storeGameLink(Base64.decode(data.url)));
        })
        .catch((err) => {
          if (err.name === "CanceledError") {
            return;
          }
          if (
            err.response.data.message == "遊戲不支援此幣別" &&
            currentCurrencyIsCrypto
          ) {
            setCryptoModal(true);
          }
          setShowAlertPop(true);
        })
        .finally(() => {
          dispatch(endGameLoading());
        });
    }
  }, [
    gameConfig,
    i18next.language,
    gameTrigger,
    walletCurrencyNow,
    gameCurrencyNow,
    firstRender,
    cryptoModal,
    cryptoButtonTrigger,
  ]); //把currentCurrency加進去，如果幣別改變，遊戲頁面會重新刷新

  const deviceType = useDeviceType();

  const { to } = useParams();

  const gameIsHasDescription = useGameIsHasDescription({ game_platform: to });

  const gameBoxComponents = useMemo(() => {
    if (cryptoModal) {
      return (
        <Box
          w="300px"
          py="20px"
          borderRadius={`common`}
          bg={"dark.200"}
          px="20px"
        >
          <Stack spacing={`3`}>
            {currentCurrencyIsCrypto && (
              <SupportCurrency
                selectProps={{
                  bg: "dark.300",
                  border: "",
                }}
              />
            )}
            <Divider />
            <Button
              variant={`brandPrimary`}
              size="md"
              type="button"
              onClick={() => {
                setCryptoButtonTrigger(!cryptoButtonTrigger);
                setCryptoModal(false);
              }}
            >
              {i18n("openGame")}
            </Button>
          </Stack>
        </Box>
      );
    }
    if (gameLoading) {
      return (
        <Stack spacing={`3`}>
          <Spinner variant={`brandPrimary`} size="xl" />
          <Text textAlign={`center`} as="p" color="white">
            {" "}
            {i18n("loading")}
          </Text>
        </Stack>
      );
    }
    // if (isTransferModePop && !isMobile) {
    //   return (
    //     <Flex
    //       direction={`column`}
    //       alignItems={`center`}
    //       gap="20px"
    //       color="light.200"
    //     >
    //       <IoGameController className="text-[100px] text-yellow-500" />
    //       <Box as="section" display="grid" gap="10px" className="grid-cols-2">
    //         <Button
    //           variant={`brandPrimary`}
    //           size="md"
    //           type="button"
    //           onClick={() => {
    //             if (gameLoading) return;
    //             if (isNotSupportIframe) {
    //               window.open(gameLink, "_blank");
    //             } else {
    //               setIsTransferModePop(false);
    //             }
    //           }}
    //         >
    //           {i18n("openGame")}
    //         </Button>
    //         <Button
    //           variant={`brandPrimary`}
    //           size="md"
    //           type="button"
    //           onClick={() => {
    //             dispatch(openVipBar());
    //             dispatch(triggerGameBoxTransfer());
    //           }}
    //         >
    //           {i18n("transfer")}
    //         </Button>
    //       </Box>
    //     </Flex>
    //   );
    // }
    if (isNotSupportIframe) {
      return (
        <Flex
          direction={`column`}
          alignItems={`center`}
          gap="20px"
          color="light.200"
        >
          <MdOutlineScreenShare className="text-[100px] text-yellow-500" />
          <Text as="span" fontSize="2xl">
            {i18n("gameNotSupportIframe")}
          </Text>
          <Button
            variant={`brandPrimary`}
            size="md"
            type="button"
            onClick={() => {
              window.open(gameLink, "_blank");
            }}
          >
            {i18n("openGame")}
          </Button>
        </Flex>
      );
    }
    if (showAlertPop) {
      return (
        <Flex
          direction={`column`}
          alignItems={`center`}
          gap="20px"
          color="light.200"
        >
          <BiSolidError className="text-[100px] text-red-500" />
          <Text textAlign={`center`} as="span" fontSize="2xl">
            {i18n("The game link is abnormal")}
          </Text>
          <Button
            variant={`brandPrimary`}
            size="md"
            type="button"
            onClick={() => {
              dispatch(openChat());
            }}
          >
            {i18n("contactCustomerServiceNow")}
          </Button>
        </Flex>
      );
    }
    return (
      <>
        {testing && (
          <Box
            as="section"
            position="absolute"
            w="100%"
            h="100%"
            top="0px"
            left="0px"
            display="flex"
            alignItems={`center`}
            justifyContent={`center`}
            bg="black"
          >
            <Text as="span" fontSize="4xl" color="light.200">
              {i18n("gameIsTestingPleaseDontPlay")}
            </Text>
          </Box>
        )}

        <iframe
          src={gameLoading ? "" : isHtmlCode ? undefined : gameLink}
          srcdoc={gameLoading ? "" : isHtmlCode ? gameLink : undefined}
          className={`lg:h-full webkit-height ${
            isOpenFullScreen ? "md:!h-[calc(100vh-47.5px)]" : ""
          } ${!isOpenFullScreen && "w-[330px] mx-auto"} ${
            isPortrait ? "!w-[70%]" : "" // 條件渲染，如果是直式，設定寬度為 70%
          } w-full bg-[#272727] md:rounded-b-xl`}
        ></iframe>
      </>
    );
  }, [
    gameLoading,
    isTransferModePop,
    isMobile,
    isNotSupportIframe,
    showAlertPop,
    cryptoModal,
    currentCurrencyIsCrypto,
    isOpenFullScreen,
    isHtmlCode,
  ]);

  useEffect(() => {
    if (gameIsHasDescription && !isMobile && Cookies.get(`notice_${to}`) != 1) {
      dispatch(openGameDescriptionPop({ game: to }));
    }
  }, [gameIsHasDescription, isMobile]);

  useEffect(() => {
    setTimeout(() => {
      setToolbarHint(false);
    }, 3000);
  }, []);

  return (
    <Box
      direction={`column`}
      overflowX="hidden"
      w="100%"
      minH={{
        xl: "calc(100% - 52px)",
        lg: "calc(100% - 52px)",
        md: "calc(100% - 52px)",
        // base: "calc(100vh)",
      }}
      h={{
        xl: "calc(100% - 52px)",
        lg: "calc(100% - 52px)",
        md: "calc(100% - 52px)",
        // base: "calc(100vh)",
      }}
      display={{
        base: "flex",
      }}
      position={{
        base: "relative",
        lg: "static",
      }}
      zIndex={`1001`}
      className="webkit-height"
    >
      <meta name="apple-mobile-web-app-capable" content="yes" />

      {triggerTransferPop && isTransferMode && !isEmptyObject(gameConfig) ? (
        <NoticeTransfer
          triggerTransferPop={triggerTransferPop}
          setTriggerTransferPop={setTriggerTransferPop}
        />
      ) : null}
      <Box
        h={{
          lg: isOpenFullScreen ? "calc(100vh - 47.5px)" : "100%",
          md: isOpenFullScreen ? "calc(100vh - 47.5px)" : "100%",
        }}
        borderBottomRadius={{
          md: "xl",
        }}
        display={`flex`}
        alignItems={`center`}
        justifyContent={`center`}
        color={`white`}
        w="100%"
        bg="gamebox.base"
        className="webkit-height"
      >
        <FreezeWrapper>{gameBoxComponents}</FreezeWrapper>
      </Box>
      <ToolTab />

      {isAccessToolOpen && (
        <GameBoxMore setShowGameBoxMore={setShowGameBoxMore} />
      )}
    </Box>
  );
};

export default GameBox;
