import {
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NotFound from "../../../components/ProgressBlock/NotFound";
import { formatNumber } from "../../../utils/formatNumber";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import useGetFullGamePlatform from "../../../hook/useGetFullGamePlatform";
import useVipInfo from "../../../hook/useVipInfo";

const BonusWallet = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`reward.${key}`);

  const bonusWallet = useSelector((state) => state.isBonus);

  const getPlatformPath = useGetFullGamePlatform();

  const { current_symbol } = useVipInfo();

  const process_bonus_wallet = useMemo(() => {
    return Object?.keys(bonusWallet)?.map((item) => {
      return {
        bonus: bonusWallet[item],
        platform: item,
      };
    });
  }, [bonusWallet]);
  return (
    <Modal
      variant={`brandPrimary`}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>BONUS 錢包</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent={`space-between`} alignItems={`center`}>
            <Text fontSize="sm" fontWeight={`semibold`}>
              遊戲平台
            </Text>
            <Text fontSize="sm" fontWeight={`semibold`}>
              BONUS
            </Text>
          </Flex>
          <Divider my="5px" />
          {process_bonus_wallet?.length === 0 ? (
            <NotFound />
          ) : (
            process_bonus_wallet?.map((item, index) => {
              const { platform, bonus } = item || [];
              return (
                <Flex
                  key={item?.platform}
                  justifyContent={`space-between`}
                  mb={2}
                  alignItems={`center`}
                >
                  <Flex alignItems={`center`} gap="5px">
                    <Text fontWeight={`bold`}>{platform}</Text>
                    <Link
                      to={`/${getPlatformPath[platform]}/category/${platform}/AllGames`}
                    >
                      <Icon
                        cursor="pointer"
                        color="special.1"
                        as={HiChevronRight}
                      />
                    </Link>
                  </Flex>
                  <Text fontWeight={`bold`}>
                    {formatNumber(bonus)}
                    {current_symbol}
                  </Text>
                </Flex>
              );
            })
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant={`brandPrimary`} size="sm" onClick={onClose}>
            關閉
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BonusWallet;
