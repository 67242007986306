import { useTranslation } from "react-i18next";
import { COMMON_ALT } from "../../../constant";
import { appName } from "../../../config";

const Iphone = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  return (
    <>
      <img
        // src={PWAhint}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt={COMMON_ALT}
      />
      <p>
        {i18n("You can add an icon to the")} iPhone{" "}
        {i18n("home screen to access")} {appName}.
      </p>
      <p>{i18n("Hint")}</p>
      <img
        // src={AddToHome_en}
        className="w-full object-cover rounded-[5px]"
        alt={COMMON_ALT}
      />
    </>
  );
};

export default Iphone;
