import { useMemo, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GameCatalog from "../../components/GameCatalog";
import SupplierList from "../../components/GameSelect/SupplierList";
import TopTab from "../Home/components/topTab";
import MobileSearch from "../Search/mobile-search";
import InfiniteScroll from "react-infinite-scroller";
import useWindowWidth from "../../hook/useWindowWidth";
import useDeviceType from "../../hook/useDeviceType";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import NotFound from "../../components/ProgressBlock/NotFound";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import GAMEITEM from "../../components/GAMEITEM/GAMEITEM";
import { filterType } from "../../hook/useGameListFilter";
import useCasinoType from "../../hook/useCasinoType";
import {
  Box,
  Flex,
  Grid,
  Text,
  Spinner,
  useBreakpointValue,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../animateConstant";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GamePlatformPage = () => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const { gamePlatform } = useParams();

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const windowWidth = useWindowWidth();
  const isMobile = useDeviceType() === "Mobile";

  const casinoType = useCasinoType();

  const { platform, sortedByPlatformGames, allGames = [] } = isHandleAllGame;

  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const resultGameList = useMemo(() => {
    if (gamePlatform) {
      if (gamePlatform === "all") {
        return filterType({
          gameList: allGames,
          casinoType: casinoType,
        });
      } else {
        return filterType({
          gameList: sortedByPlatformGames?.[gamePlatform],
          casinoType: casinoType,
        });
      }
    }
  }, [sortedByPlatformGames, gamePlatform]);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
  }, [gamePlatform]);

  const ITEMS_PER_PAGE = useBreakpointValue({
    base: 12,
    md: 25,
    lg: 35,
    xl: 32,
  });

  useEffect(() => {
    setDisplayedItems(resultGameList?.slice(0, ITEMS_PER_PAGE));
  }, [resultGameList, ITEMS_PER_PAGE]);

  const loadMore = useCallback(() => {
    setTimeout(() => {
      const moreItems = resultGameList?.slice(
        0,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
      if (moreItems?.length >= displayedItems?.length) {
        setDisplayedItems(moreItems);
        setIsLoading(false);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 300);
  }, [currentPage, displayedItems?.length]);

  const hasMoreItems = useMemo(() => {
    return displayedItems?.length < resultGameList?.length;
  }, [displayedItems?.length]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [isLoading]);

  return (
    <>
      <Flex direction="column" gap={4} display={{ md: "none" }}>
        <TopTab />
        <Box
          mt={{
            md: "0px",
            base: "10px",
          }}
        >
          <MobileSearch />
        </Box>
      </Flex>
      <Flex mb="8px" h="30px" mdH="56px" alignItems="center">
        <Text
          mx="4"
          fontWeight="semibold"
          fontSize="xl"
          color="dark.100"
          _dark={{ color: "light.100" }}
        >
          {gamePlatform === "all" ? i18n_common(gamePlatform) : gamePlatform}
        </Text>
      </Flex>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreItems}
        useWindow={isMobile}
        loader={<LoadingBlock commonLoading={true} />}
      >
        <LoadingBlock commonLoading={isLoading}>
          {displayedItems?.length === 0 && <NotFound />}
          <ChakraBox {...gameListFromBottom}>
            <Grid
              templateColumns={{
                base: "repeat(3, minmax(0, 1fr))",
                md: "repeat(4, minmax(0, 1fr))",
                lg: "repeat(7, minmax(0, 1fr))",
                xl: "repeat(8, minmax(0, 1fr))",
                "2xl": "repeat(10, minmax(0, 1fr))",
              }}
              gapX={{ md: "16px" }}
              gapY="24px"
              gap={4}
              px="common-x"
            >
              {displayedItems?.map((item, index) => {
                const gameName =
                  item?.[
                    `gameName${languageTransfer({
                      lang: i18next.language,
                    })}`
                  ];
                const gameNameEn = item?.gameNameEn;
                const platform = item?.game_platform;
                const gameSubType = item?.game_subtype;

                const gameCode = item?.gameCode;
                const gameType = item?.gameType;
                return (
                  <GAMEITEM
                    gameProps={{
                      gameName,
                      platform,
                      gameCode,
                      gameType,
                      gameNameEn,
                      gameSubType,
                    }}
                    originProps={item}
                    itemClass={`!h-[155px]`}
                    index={index}
                  />
                );
              })}
            </Grid>
          </ChakraBox>
        </LoadingBlock>
      </InfiniteScroll>
      <Box px="common-x" mb="20px">
        <SupplierList LIST={platform} />
      </Box>
    </>
  );
};

export default GamePlatformPage;
