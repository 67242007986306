import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import { Link } from "react-router-dom";
import LazyImage from "../../../components/Loading/lazyImage";
import { COMMON_WEB_PATH } from "../../../constant";
import { appName } from "../../../config";
import LoadingLine from "../../../components/Loading/LoadingLine";
import { useTranslation } from "react-i18next";
import { FaCircleChevronRight } from "react-icons/fa6";

const classic_list = [
  {
    link: "live-casino",
  },
  {
    link: "sports",
  },
  {
    link: "casino",
  },
];

const ClassicType = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const titleColor = useColorModeValue("dark.100", "light.100");
  const textColor = useColorModeValue("light.100", "light.100");

  return (
    <>
      <Flex justifyContent={`space-between`} mb="10px">
        <Flex alignItems={`center`}>
          <LoadingLine className={`min-w-[130px] rounded-full`}>
            <Heading
              as="h3"
              color={titleColor}
              fontSize={{
                base: "lg",
                sm: "2xl",
              }}
              mb="0px"
              fontWeight={`medium`}
            >
              {i18n("classic-type")}
            </Heading>
          </LoadingLine>
        </Flex>
      </Flex>
      <Box>
        <Splide
          className="h-full w-full"
          options={{
            type: "slide",
            rewind: true,
            perPage: 3,
            gap: 20,
            autoplay: true,
            arrows: false,
            pagination: false,
            breakpoints: {
              640: {
                perPage: 1,
              },
              768: {
                perPage: 2,
              },
              1440: {
                perPage: 2,
              },
            },
          }}
        >
          {classic_list.map((item) => {
            const { link } = item || {};
            return (
              <SplideSlide>
                <Link to={link}>
                  <Box
                    overflow="hidden"
                    h="140px"
                    width="100%"
                    borderRadius={`common`}
                  >
                    {/* <LazyImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${COMMON_WEB_PATH}/b69-home/classic-type/${link}.webp`;
                      }}
                      src={`${COMMON_WEB_PATH}/b69-home/classic-type/${link}.webp`}
                      closeInverted
                      fetchPriority="high"
                      alt={appName}
                      className={`object-cover sm:h-[280px] h-[200px] !w-full rounded-[8px] object-cover `}
                    /> */}
                    <Box
                      backgroundImage={`url(${COMMON_WEB_PATH}/b69-home/classic-type/${link}.webp)`}
                      h="100%"
                      bgPos={`center`}
                      bgRepeat={`no-repeat`}
                      bgSize={`contain`}
                      display={`flex`}
                      flexDirection={`column`}
                      alignItems={`center`}
                      justifyContent={`flex-end`}
                      py="10px"
                      role="group"
                      transition={`all 0.3s`}
                      _hover={{
                        transform: `scale(1.1)`,
                      }}
                    >
                      <Box>
                        <Flex
                          alignItems={`center`}
                          gap="5px"
                          transition={`all 0.3s`}
                          //   _groupHover={{
                          //     transform: `scale(1.1)`,
                          //   }}
                        >
                          <Text color={textColor} fontWeight={`semibold`}>
                            {i18n(link)}
                          </Text>
                          <Icon
                            transition={`all 0.3s`}
                            _groupHover={{
                              transform: `translateX(10px)`,
                            }}
                            color={textColor}
                            as={FaCircleChevronRight}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </>
  );
};

export default ClassicType;
