import { Box, Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import Carousel from "../../components/Carousel";
import MarqueeList from "../../components/marquee/marqueeList";
import GameTypeSelector from "../../components/v2Layout/GameTypeSelector/GameTypeSelector";
import SubListSelector from "../../components/v2Layout/SubListSelector/SubListSelector";
import JackpotWrapper from "../../components/v2Layout/Jackpot/JackpotWrapper";
import PlatformSelector from "../../components/v2Layout/PlatformSelector/PlatformSelector";
import MobileSearchV2 from "../Search/mobile-search_v2";
import LiveGameSelector from "../../components/v2Layout/GameSelector/LiveGameSelector";
import Seo from "./seo";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import SportsGameSelector from "../../components/v3Layout/GameSelector/SportsGameSelector";

const LotteryV3 = ({ gameMainTypeFromProps }) => {
  return (
    <Fragment>
      <Seo />
      <Box as="section" pb={{ md: "0", base: "home-space" }} px="common-y">
        <MarqueeListV2 />
      </Box>

      <Box
        mt={{
          md: "0px",
          base: "10px",
        }}
      >
        <MobileSearchV2 listHidden />
      </Box>
      <Flex>
        <Box py="common-y" pl="common-y" pr="5px">
          <SportsGameSelector gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
        <Box
          overflowY="scroll"
          flex={1}
          // h={{
          //   base: "80vh",
          //   md: "auto",
          // }}
        >
          {/* <Box
            mt={{
              md: "0px",
              base: "10px",
            }}
          >
            <MobileSearchV2 listHidden />
          </Box> */}
          <Box py="common-y" px="common-y">
            <SubListSelector
              forceShowAll
              gameMainTypeFromProps={gameMainTypeFromProps}
            />
          </Box>
          <Box py="home-space" px="common-y">
            {/* <SubListSelector /> */}
            <LiveGameSelector gameMainTypeFromProps={gameMainTypeFromProps} />
          </Box>
          {/* <Box py="common-y" px="common-y">
            <PlatformSelector gameMainTypeFromProps={gameMainTypeFromProps} />
          </Box> */}
        </Box>
      </Flex>
    </Fragment>
  );
};

export default LotteryV3;
