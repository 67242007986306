import React, { useEffect, useMemo, useState } from "react";
import MiniCard from "../../Vip/components/miniCard";
import { useTranslation } from "react-i18next";
import NowGame from "./nowGame";
import useTransferMode from "../../../hook/useTransferMode";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import CreditPage from "../../../utils/credit/credit_page";
const LevelDetail = () => {
  const { t } = useTranslation();
  const i18n_rewards = (key) => t(`reward.${key}`);

  const isTransferMode = useTransferMode();

  const textColor = useColorModeValue("dark.100", "light.100");

  return (
    <Flex direction={`column`} gap="10px">
      <NowGame />
      <CreditPage>
        <Text color={textColor} as="p">
          {i18n_rewards("My Vip")}
        </Text>
        <MiniCard />
      </CreditPage>
    </Flex>
  );
};

export default LevelDetail;
