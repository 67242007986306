import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const brandPrimary = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "gray.200",
    background: "white",
    borderRadius: "common",
    color: "black",
    // Let's also provide dark mode alternatives
    _dark: {
      background: "dark.200",
      borderColor: "gray.700",
      color: "white",
    },
    _focus: {
      borderColor: "brand.500",
    },
    _hover: {
      borderColor: "brand.500",
    },
  },
  addon: {
    border: "1px solid",
    borderColor: "gray.200",
    background: "gray.200",
    borderRadius: "full",
    color: "gray.500",

    _dark: {
      borderColor: "gray.600",
      background: "gray.600",
      color: "gray.400",
    },
  },
});

const v3SignBrandPrimary = definePartsStyle({
  field: {
    border: "1px solid",
    background: "#F0E8FB",
    borderRadius: "common",
    borderTop: "1px solid",
    borderColor: "#CABEDB",
    color: "black",
    // Let's also provide dark mode alternatives
    _dark: {
      background: "#F0E8FB",
      color: "brand.900",
    },
    _focus: {
      borderColor: "brand.500",
    },
    _hover: {
      borderColor: "brand.500",
    },
  },
  addon: {
    border: "1px solid",
    borderColor: "gray.200",
    background: "gray.200",
    borderRadius: "full",
    color: "gray.500",

    _dark: {
      borderColor: "gray.600",
      background: "gray.600",
      color: "gray.400",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: { brandPrimary, v3SignBrandPrimary },
});
