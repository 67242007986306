import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import NowGame from "./nowGame";
import MiniCard from "../../Vip/components/miniCard";
import RewardList from "../../Rewards/components/rewardList";
import { useColorModeValue } from "@chakra-ui/color-mode";

const RewardDetail = () => {
  const textColor = useColorModeValue("dark.100", "light.100");
  return (
    <Flex direction={`column`} gap="10px">
      <NowGame />
      <Text color={textColor} as="p">
        My Rewards
      </Text>
      <RewardList />
    </Flex>
  );
};

export default RewardDetail;
