import React from "react";
import { useTranslation } from "react-i18next";

const WS168 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`gameRules.ws168.${key}`, props);

  return (
    <div>
      <p>
        <div className="list-disc">
          <li>{i18n("bettingStart")}</li>
          <li>{i18n("bettingEnd")}</li>
          <li>{i18n("tiePayout")}</li>
          <li>{i18n("tieOutcome")}</li>
          <li>{i18n("matchCancelled")}</li>
          <li>{i18n("drawConditions")}</li>
          <li>{i18n("bettingIrrevocable")}</li>
          <li>{i18n("oddsDisplayed")}</li>
          <li>{i18n("bettingCancelled")}</li>
        </div>
        <br />
        <br />
        {i18n("additionalTerms")}
        <br />
        {i18n("agreement")}
      </p>
    </div>
  );
};

export default WS168;
