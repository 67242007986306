import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const LoaderGameList = ({ children, commonLoading, className }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  //   const loadingStatus = true;

  return (
    <React.Fragment>
      {loadingStatus || commonLoading ? (
        <Flex direction={`column`} my="5px" p="5px" minHeight={`180px`}>
          <Skeleton
            variant="brandPrimary"
            borderRadius={`common`}
            width={`100%`}
            className={`${className}`}
            pt={{
              base: "105%",
              md: "120%",
            }}
          ></Skeleton>
          <Skeleton
            mt="12px"
            width={`50px`}
            height={`7px`}
            display={`flex`}
            alignItems={`self-end`}
            borderRadius={`common`}
            variant="brandPrimary"
          ></Skeleton>
          <Skeleton
            mt="12px"
            width={`100px`}
            height={`7px`}
            display={`flex`}
            alignItems={`self-end`}
            borderRadius={`common`}
            variant="brandPrimary"
          ></Skeleton>
        </Flex>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}{" "}
    </React.Fragment>
  );
};

export default LoaderGameList;
