import {
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../../components/InputComponents/customInput";
import { BiSolidDollarCircle } from "react-icons/bi";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { waitTime } from "../../../utils/waitTime";

const CryptoModal = ({ isOpen, onClose, data, setData }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const walletCurrency = useSelector((state) => state.isWalletCurrency);

  const currencyList = useMemo(() => {
    return Object.keys(isCurrencyExchange)
      .filter((item) => isCurrencyExchange?.[item]?.is_crypto != 1)
      .filter((item) => isCurrencyExchange?.[item]?.status_front === 1);
  }, [isCurrencyExchange]);

  const [currentAmount, setCurrentAmount] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const sliderMax = useMemo(() => {
    const currency_config = isCurrencyExchange?.[walletCurrency];
    const current_currency_amount =
      Number(currency_config?.exchange_amount) || 0;
    const current_currency_exchange =
      Number(currency_config?.exchange_rate?.[currentCurrency]) || 0;

    const result =
      formatNumber(current_currency_amount * current_currency_exchange) || 0;

    return result;
  }, [isCurrencyExchange, walletCurrency, currentCurrency]);

  const conversionToCrypto = useMemo(() => {
    const currency_config = isCurrencyExchange?.[walletCurrency];
    const current_currency_exchange =
      Number(currency_config?.exchange_rate?.[currentCurrency]) || 0;

    const result =
      formatNumber(currentAmount / current_currency_exchange, {
        format_to_eight: true,
      }) || 0;

    return result;
  }, [currentAmount, currentCurrency, isCurrencyExchange, walletCurrency]);

  const currentCurrencySymbol = useCurrencySymbol({
    origin: true,
    custom_currency: currentCurrency,
  });

  const handleSubmit = async () => {
    setButtonLoading(true);
    await setData({
      ...data,
      amount: currentAmount,
    });
    await waitTime(500);
    setButtonLoading(false);
    onClose();
  };

  const handleClose = () => {
    setCurrentAmount(0);
    setCurrentCurrency("");
    setData({
      ...data,
      amount: 0,
    });
    onClose();
  };

  return (
    <Modal
      variant={`brandPrimary`}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={handleClose}
      size="sm"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n("pleaseSelectWithdrawCurrency")}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Stack spacing={"10px"}>
            <Flex direction={`column`} gap="5px">
              <Text>{i18n("selectCurrency")}</Text>
              <RadioGroup
                onChange={(v) => {
                  setCurrentCurrency(v);
                }}
                value={currentCurrency}
              >
                <Stack direction="row">
                  {currencyList?.map((option) => (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Flex>
            <Flex direction={`column`} gap="5px">
              <Text>
                {i18n("inputAmount")}
                {currentCurrency && `(${currentCurrencySymbol})`}：
              </Text>
              <CustomInput
                props={{
                  icon: <BiSolidDollarCircle color="gray.300" />,
                  type: "number",
                  onChange: (value) => {
                    setCurrentAmount(value);
                  },
                  value: currentAmount,
                  disabled: !currentCurrency,
                  percision: 2,
                  max: sliderMax,
                  min: 0,
                }}
              />
              <Slider
                mt="10px"
                onChange={(v) => {
                  setCurrentAmount(v);
                }}
                disabled={!currentCurrency}
                focusThumbOnChange={false}
                aria-label="slider-ex-1"
                value={currentAmount}
                defaultValue={currentAmount}
                max={sliderMax}
              >
                <SliderTrack>
                  <SliderFilledTrack bg="brand.500" />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
            {currentAmount && (
              <Flex direction={`column`} gap="5px">
                <Text color="brand.500" fontSize={`sm`}>
                  {i18n("convertTo")}
                  {walletCurrency} ≈ {conversionToCrypto}
                </Text>
              </Flex>
            )}
            <Divider />
            <Text color="red.500">{i18n_header("alert")}</Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Stack direction={`row`} spacing={`10px`}>
            <Button
              isDisabled={buttonLoading}
              isLoading={buttonLoading}
              variant={`brandPrimary`}
              size="sm"
              onClick={handleSubmit}
            >
              {i18n("confirmAndConvert")}
            </Button>
            <Button
              isDisabled={buttonLoading}
              isLoading={buttonLoading}
              size="sm"
              onClick={handleClose}
            >
              {i18n("cancel")}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CryptoModal;
