import {
  isValidMotionProp,
  motion,
  useAnimation,
  useMotionValue,
} from "framer-motion";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import RouletteDashboard from "../../images/Roulette/roulette-dashboard.webp";
import RouletteTarget from "../../images/Roulette/roulette-target.webp";
import RouletteLight from "../../images/Roulette/roulette-light.webp";
import RouletteButton from "../../images/Roulette/roulette-button.webp";
import RouletteStar from "../../images/Roulette/roulette-start.webp";
import RouletteTab from "../../images/Roulette/roulette-tab.webp";

import {
  Box,
  Button,
  Icon,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Base64 } from "js-base64";
import { MdKeyboardArrowRight } from "react-icons/md";
import useCurrencySymbol from "../../hook/useCurrencySymbol";
import Sound from "./utils/sound";
import RouletteReward from "./roulette_reward";
import { openOpenFreezeModal } from "../../redux/action/member/memberAction";
import i18next from "i18next";
import useCheckLogin from "../../hook/useCheckLogin";
import { languageMapping } from "../../i18n/config";

const prize = [
  "100",
  "200",
  "300",
  "50000",
  "123123",
  "1442354",
  "1332",
  "100",
  "200",
  "300",
  "50000",
  "123123",
  "1442354",
  "1332",
  "300",
  "50000",
];
const shakeAnimation = {
  rotate: [0, -7, 0],
  scale: [1, 1.1, 1],
  transition: {
    duration: 2.5,
    ease: "easeInOut",
    type: "tween",
    repeat: Infinity,
    repeatType: "reverse",
  },
};

const rotateV = {
  start: ({ rotate }) => ({
    rotate: rotate,
    transition: { type: "tween", duration: 4.0, ease: [0.3, 0.1, 0.15, 1] },
  }),
  stop: ({ endDeg }) => ({
    rotate: endDeg,
    transition: { type: "tween", duration: 0 },
  }),
};

const vPulse = {
  scale: [0.95, 1.1],
  transition: { duration: 0.6, yoyo: Infinity },
};

const pointerAnimate = {
  start: {
    scaleX: 1.1,
    scaleY: 1.05,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  stop: {
    scaleX: 1,
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const url = `${window.location.origin}/api/roulettes`;
// const url = `${API_WEB_PATH}/api/roulettes`;
const headers = {
  "Content-Type": "application/json",
};

const RouletteNew = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_roulette = (key) => t(`roulette.${key}`);

  const isWalletCurrency = useSelector((state) => state.isWalletCurrency);
  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [rouletteDetail, setRouletteDetail] = useState({
    awardDetail: "",
    count: 0,
    currency: "",
    roulettes_copywriting: [
      {
        sub_title: "",
        description: "",
        memo: "",
        language: "en_US",
      },
    ],
  });
  const [isAnimationStart, setIsAnimationStart] = useState(false);
  const [lightTrigger, setLightTrigger] = useState(false);
  const [rewardPosition, setRewardPosition] = useState(0);
  const [audioRouletting, setAudioRouletting] = useState(false);
  const [openRewardModal, setOpenRewardModal] = useState(false);
  const [claimButton, setClaimButton] = useState(true);
  const [reward, setReward] = useState({
    reward: 0,
    symbol: "",
  });
  const [tabList, setTabList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const { isLogin } = useCheckLogin();

  const award_detail = useMemo(() => {
    try {
      const { awardDetail } = rouletteDetail;
      const encode = Base64.decode(awardDetail);
      const parse_detail = JSON.parse(encode);
      const format_detail = parse_detail.map((item) => {
        return item?.awardBonus === 0
          ? item?.rouletteTitle || "-"
          : item?.awardBonus;
      });

      return [...format_detail];
    } catch (err) {
      console.error(err);
    }
  }, [rouletteDetail]);

  const found_roulette_copywriting = useMemo(() => {
    const lng = i18next.language;
    const { roulettes_copywriting } = rouletteDetail || {};
    return (
      roulettes_copywriting?.find(
        (item) => item.language === languageMapping(lng)
      ) ||
      roulettes_copywriting?.[0] ||
      {}
    );
  }, [rouletteDetail, i18next.language]);

  const lightClass = useMemo(() => {
    return lightTrigger
      ? {
          transform: `rotate(${360 / 16}deg)`,
        }
      : {};
  }, [lightTrigger]);

  const pulseControl = useAnimation();
  const rotateControl = useAnimation();
  const pointerControl = useAnimation();
  const pointerLightControl = useAnimation();

  const currentSymbol = useCurrencySymbol({
    custom_currency: rouletteDetail?.currency,
  });

  const spinCount = 10;
  const offset = useMemo(() => {
    return (360 / 16) * rewardPosition;
  }, [rewardPosition]);
  const endValue = useMotionValue(0);

  useEffect(() => {
    if (isAnimationStart) {
      setAudioRouletting(true);
      rotateControl.start("start");
      pulseControl.stop();

      setTimeout(() => {
        //指針動畫
        pointerControl.start("start");
        pointerLightControl.start({
          x: 200,
          skewX: -25,
          scale: 3.4,
          transition: {
            duration: 1,
            ease: "easeInOut",
          },
        });
        setTimeout(() => {
          pointerControl.start("stop");
        }, 500);
        setTimeout(() => {
          pointerLightControl.stop();
          pointerLightControl.set({
            x: 0,
          });
        }, 1000);
      }, 4000);

      setTimeout(() => {
        setOpenRewardModal(true);
      }, 5500);

      setTimeout(() => {
        rotateControl.start("stop");
        setAudioRouletting(false);
        setClaimButton(false);
        pulseControl.start(vPulse);
        setRewardPosition(0);
        endValue.set(0);
      }, 8300);
    }
  }, [offset, endValue, isAnimationStart]);

  const onAnimationStart = async () => {
    if (!isLogin) {
      navigate("/signin");
      return;
    }
    if (memberData?.status == 2) {
      dispatch(openOpenFreezeModal());
      return;
    }
    if (rouletteDetail?.count <= 0) {
      return;
    }
    if (!isAnimationStart) {
      await axios({
        method: "post",
        url: url,
        headers: headers,
        data: {
          method: "spinRoulette",
          oauth: Cookies.get("token"),
          uid: rouletteDetail?.uid,
        },
      }).then((res) => {
        const reward_is_zero = res.data.point == 0;

        const reward = reward_is_zero ? res.data.reward_name : res.data.point;
        const find_reward_index = award_detail.findIndex((item) => {
          return item == reward;
        });
        const offset = (360 / 16) * find_reward_index;
        endValue.set(360 * spinCount - offset);
        setRewardPosition(find_reward_index);
        setReward({
          reward: reward,
          symbol: currentSymbol,
        });
      });
      setIsAnimationStart(true);
    }

    setTimeout(() => {
      setIsAnimationStart(false);
    }, 8300);
  };

  useEffect(() => {
    pulseControl.start(vPulse);
  }, []);

  useEffect(() => {
    const periodically = () => {
      setLightTrigger((lightTrigger) => !lightTrigger);
    };

    const apiInterval = setInterval(periodically, 1000);

    return () => clearInterval(apiInterval);
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: url,
      headers: headers,
      data: {
        method: "getRoulette",
        oauth: Cookies.get("token"),
        currency: isWalletCurrency,
      },
    }).then((res) => {
      const list = res?.data?.list || [];
      setTabList(list);
      setRouletteDetail(res?.data?.list?.[0]);
    });
  }, []);

  return (
    <CommonOpacityBackground
      className={`!bg-[rgb(0,0,0,0.3)]`}
      disabledFunction
    >
      <ChakraBox
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        // 0A0D0F
        minH={{
          md: "248px",
        }}
        maxH={{
          md: "calc(100vh - 60px)",
        }}
        h="100%"
        w={{
          base: "100%",
          md: "500px",
        }}
        borderRadius={`common`}
        display={"flex"}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        flexDirection={`column`}
        visibility={openRewardModal ? "hidden" : "visible"}
        className="bg-black"
      >
        <CloseDetailPage
          title={i18n("LuckyRoulette")}
          className="!mb-0 px-[10px]" //傳className參數內容給CloseDetailPage
          titleClassName="text-white"
          customClose={
            <IoCloseOutline
              className="cursor-pointer hidden md:block"
              onClick={() => {
                navigate(-1);
              }}
            />
          }
        />
        <section className="overflow-y-scroll no-scrollbar md:min-h-[248px] bg-[#1a132c] md:max-h-[calc(100vh-92px)] h-full">
          <>
            <Box
              as="section"
              w="100%"
              position="relative"
              overflowX="hidden"
              overflowY={`scroll`}
              className="no-scrollbar"
              h="100%"
            >
              <Box
                backgroundImage={`url(${RouletteStar})`}
                textAlign={`center`}
                position="relative"
                z={10}
                backgroundSize="100% auto"
                h="8.625rem"
                backgroundPosition="center"
                p="1.5rem 0 1.25rem"
              >
                <Text
                  fontWeight={900}
                  fontSize="2.5rem"
                  backgroundImage={`linear-gradient(to bottom, #ffffff, #A27BEB)`}
                  filter={`drop-shadow(1px 1px 0 #7F4CD6)`}
                  backgroundClip={`text`}
                >
                  {i18n_roulette("SPIN TO WIN")}
                </Text>
                <Box
                  mb="20px"
                  px="20px"
                  display="flex"
                  w="100%"
                  justifyContent={`center`}
                >
                  <Box
                    w="12.625rem"
                    p="2px"
                    borderRadius={`common`}
                    display="flex"
                    justifyContent={`center`}
                    gap="2px"
                  >
                    {tabList?.map((item, index) => {
                      return (
                        <Button
                          w="62px"
                          h="62px"
                          display="flex"
                          alignItems={`center`}
                          justifyContent={`center`}
                          bg={
                            activeTab === index ? "game-item.mask" : "#261D3E"
                          }
                          onClick={() => {
                            if (isAnimationStart) return;
                            setActiveTab(index);
                            setRouletteDetail(item);
                          }}
                        >
                          <Image src={RouletteTab} w="40px" />
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <ChakraBox
                initial={{ rotate: 360, scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
                h="21.75rem"
                mt="15px"
                position="relative"
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
                id="roulette-container"
                style={{
                  userSelect: "none",
                }}
              >
                <Box position="relative" w="21.75rem" h="21.75rem">
                  <ChakraBox
                    custom={{
                      rotate: [0, endValue.current],
                      endDeg: endValue.current,
                    }}
                    animate={rotateControl}
                    variants={rotateV}
                    id="roulette-wrapper"
                    position="absolute"
                    w="100%"
                    h="100%"
                    left="0px"
                    top="0px"
                  >
                    <Box
                      position="absolute"
                      w="21.75rem"
                      h="21.75rem"
                      left="0px"
                      top="0px"
                    >
                      <Image
                        src={RouletteDashboard}
                        w="21.75rem"
                        h="21.75rem"
                        position="absolute"
                        top="0"
                        left="0"
                      />
                      <Image
                        src={RouletteLight}
                        position="absolute"
                        top="0"
                        left="0"
                        w="21.75rem"
                        h="21.75rem"
                        style={lightClass}
                      />
                      {award_detail?.map((item, index) => {
                        return (
                          <Box
                            position="absolute"
                            w="5.5rem"
                            top="50%"
                            left="50%"
                            display="flex"
                            alignItems={`center`}
                            justifyContent={`flex-end`}
                            pr="8px"
                            color="#fff"
                            h="24px"
                            ml="3.875rem"
                            mt="-0.75rem"
                            style={{
                              transform: `rotate(${index * 22.5}deg)`,
                              transformOrigin: "-3.8125rem center",
                            }}
                          >
                            <Text
                              textShadow="1px 1px #000"
                              fontSize={
                                typeof item === "number" ? "18px" : "12px"
                              }
                              fontWeight="bold"
                              className="text-overflow"
                            >
                              {item}
                              {typeof item === "number" && currentSymbol}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>
                  </ChakraBox>
                  <ChakraBox
                    animate={pointerControl}
                    variants={pointerAnimate}
                    position="absolute"
                    z="5"
                    h="5.59375rem"
                    right="-1.90625rem"
                    top="8.0625rem"
                    w="10rem"
                    transformOrigin={`left center`}
                    id="pointer"
                  >
                    <Box
                      id="light-wrap"
                      overflow="hidden"
                      position="absolute"
                      w="6.875rem"
                      h="3.75rem"
                      top=".9375rem"
                    >
                      <ChakraBox
                        animate={pointerLightControl}
                        position="absolute"
                        top="1.125rem"
                        left="-1.875rem"
                        w=".9375rem"
                        h="1.25rem"
                        zIndex={6}
                        overflow={`hidden`}
                        bg="-webkit-gradient(linear,left top,right top,color-stop(0%,rgba(255,255,255,0)),color-stop(50%,rgba(255,255,255,.2)),color-stop(100%,rgba(255,255,255,0)))"
                      ></ChakraBox>
                    </Box>
                    <Image src={RouletteTarget} w="100%" h="100%" />
                  </ChakraBox>
                </Box>
                <ChakraBox
                  initial={{ rotate: 0 }}
                  animate={isAnimationStart ? {} : shakeAnimation}
                  whileHover={
                    isAnimationStart
                      ? {}
                      : {
                          scale: 1.1,
                          duration: 0.5,
                        }
                  }
                  position="absolute"
                  w="6.5rem"
                  h="6.5rem"
                  top="7.625rem"
                  left="50%"
                  zIndex="11"
                  ml="-3.25rem"
                  cursor={isAnimationStart ? "not-allowed" : "pointer"}
                  onClick={() => {
                    onAnimationStart();
                  }}
                >
                  <Image
                    src={RouletteButton}
                    position="absolute"
                    w="5.5rem"
                    h="3.5rem"
                    top="1.5625rem"
                    left="0.5rem"
                  />
                </ChakraBox>
              </ChakraBox>
              <Text
                fontWeight={900}
                fontSize="2.5rem"
                backgroundImage={`linear-gradient(to bottom, #ffffff, #A27BEB)`}
                filter={`drop-shadow(1px 1px 0 #7F4CD6)`}
                backgroundClip={`text`}
                textAlign={`center`}
              >
                {found_roulette_copywriting?.sub_title || ""}
              </Text>
              <Text
                fontWeight={700}
                fontSize="1.5rem"
                color="special.1"
                textAlign={`center`}
              >
                {found_roulette_copywriting?.description || ""}
              </Text>
              <Box
                mt="30px"
                display="flex"
                justifyContent={`flex-end`}
                alignItems={`center`}
                px="2rem"
              >
                <Box
                  bg="#261D3E"
                  py="7px"
                  px="12px"
                  borderRadius={`5px`}
                  display="flex"
                  gap="5px"
                  alignItems={`center`}
                  color="white"
                >
                  <Text fontWeight={700}>
                    {i18n_roulette("spin_count")}: {rouletteDetail?.count}
                  </Text>
                </Box>
              </Box>
            </Box>
          </>
        </section>
      </ChakraBox>
      {openRewardModal && (
        <RouletteReward claimButton={claimButton} reward={reward} />
      )}
      <Sound
        id="roulette-ing"
        startPlayAudio={audioRouletting}
        startTime={2}
        endTime={10}
      />
    </CommonOpacityBackground>
  );
};

export default RouletteNew;
