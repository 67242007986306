import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCryptoInFiat = () => {
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const { currency_code } = isMemberInfo || {};

  const [cryptoInFiatStatus, setCryptoInFiatStatus] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");

  useEffect(() => {
    if (currency_code == null || currency_code == "") {
      setCryptoInFiatStatus(false);
    } else {
      setCryptoInFiatStatus(true);
    }
    setCurrencyCode(currency_code);
  }, [isMemberInfo]);
  return {
    currency_code: currencyCode,
    cryptoInFiatStatus: cryptoInFiatStatus,
  };
};

export default useCryptoInFiat;
