import { Spinner } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const LoadingIcon = ({ children, commonLoading, size }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  return loadingStatus || commonLoading ? (
    <Spinner variant={"brandPrimary"} size={size ? size : "sm"}>
      {/* {children} */}
    </Spinner>
  ) : (
    children
  );
};

export default LoadingIcon;
