import axios from "axios";
import Cookies from "js-cookie";
import store from "../redux/store";
import { isDetectedInvalidAuth } from "../redux/action/member/memberAction";

const requestTimestamps = {};

const createApi = (
  baseUrl,
  { isFormData = false, canUseRepeatApi = false } = {}
) => {
  // "http://127.0.0.1:8000"
  const api = axios.create({
    baseURL: `${
      process.env.REACT_APP_SECRET_TYPE === "local"
        ? process.env.REACT_APP_API_URL
        : window.location.origin
      // ? window.location.origin
      // : API_WEB_PATH
      //: "http://localhost" 暫先用本地端api
    }/api/${baseUrl}`,
    headers: {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      Authorization: Cookies.get("token"),
    },
  });
  api.interceptors.request.use((config) => {
    const requestKey = `${config.url}_${JSON.stringify(config.params)}`;

    config.headers.Currency = store.getState()?.isWalletCurrency;
    config.headers[`currency-code`] = store.getState()?.isWalletCurrency;
    //在 HTTP 請求中，header 是用來提供請求的操作、描述等資訊。它們定義了請求的參數，例如指定回應的格式、進行身份驗證等。
    //config.headers.Currency 是設定 HTTP 請求的 Currency header。這個 header 被用來告訴伺服器你希望在回應中使用哪種貨幣。store.getState()?.isNowCurrency 是從 Redux store 中獲取當前的貨幣設定，並將其設定為 Currency header 的值。

    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (
      requestTimestamps[requestKey] === currentTimestamp &&
      !canUseRepeatApi
    ) {
      const cancelSource = axios.CancelToken.source();
      config.cancelToken = cancelSource.token;
      cancelSource.cancel(`取消一樣的請求`);
      config.cancelToken.promise.catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
        }
      });
    } else {
      requestTimestamps[requestKey] = currentTimestamp;
    }
    config.headers.Authorization = `Bearer ${Cookies.get("token")}`;
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (store.getState().isCallbackInvalid) {
        return;
      }
      if (!axios.isCancel(error)) {
        store.dispatch({ type: "API_ERROR", error: error });
      }
      if (error?.response?.data?.message === "Invalid Authorization") {
        store.dispatch(isDetectedInvalidAuth());
        store.dispatch({ type: "USER_LOGOUT" });
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export const api = createApi("");
export const userApi = createApi("member");
export const recordApi = createApi("record");
export const giftApi = createApi("gift");
export const transferApi = createApi("transfers");

export const transferRepeatApi = createApi("transfers", {
  canUseRepeatApi: true,
});

export const repeatApi = createApi("", {
  //取消一樣的請求
  canUseRepeatApi: true,
});

export const formDataApi = createApi("", {
  isFormData: true,
});

export const eventApi = createApi("event");
