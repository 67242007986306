import React, { useCallback, useMemo, useState } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { LANGUAGES, LANGUAGES_MENU } from "../../../i18n/config";
import i18next from "i18next";
import { AiFillCheckCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { closeLanguagePop } from "../../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const LanguagePage = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const i18n_common = (key) => t(`common.${key}`);

  const boxBg = useColorModeValue("light.100", "dark.100");
  const itemHoverBg = useColorModeValue("light.300", "dark.300");
  const textActiveColor = useColorModeValue("dark.100", "light.100");

  const website_language = window.web_language || [];

  const memberData = useSelector((state) => state.isMemberInfo);
  const { test } = memberData || {};

  const language_filter = useMemo(() => {
    return LANGUAGES_MENU?.filter((item) => {
      if (test == 1) {
        return item;
      } else {
        return website_language?.includes(item.value);
      }
    });
  }, [LANGUAGES_MENU, test, website_language]);

  const onClose = async () => {
    await dispatch(closeLanguagePop());
  };

  const dispatch = useDispatch();
  return (
    <Modal
      variant={`brandPrimary`}
      isCentered
      onClose={onClose}
      isOpen={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent h={550}>
        <ModalHeader>Language</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={500}>
          <Box
            as="section"
            display="flex"
            flexDirection={`column`}
            gap="5px"
            borderRadius={`common`}
            py="8px"
          >
            {language_filter?.map((item, index) => {
              return (
                <Box
                  onClick={async (event) => {
                    event.stopPropagation();
                    await i18next.changeLanguage(item.value);
                    // const targetPath = item.value === "tw" ? "" : item.value;
                    const targetPath = item.value;

                    window.location.assign("/" + targetPath);
                  }}
                  key={`${item.language}-${index}`}
                  cursor={`pointer`}
                  borderRadius={`common`}
                  p="10px"
                  display="flex"
                  alignItems={`center`}
                  justifyContent={`space-between`}
                  bg={i18next.language === item.value ? itemHoverBg : boxBg}
                  _hover={{
                    bg: itemHoverBg,
                  }}
                >
                  <Flex alignItems={`center`} gap="5px">
                    {/* <Flag name={item.flag} className="!w-[20px]" height="15" /> */}
                    <Text
                      as="span"
                      fontWeight={`medium`}
                      color={
                        lang === item.value ? textActiveColor : "special.1"
                      }
                    >
                      {item.text}
                    </Text>
                  </Flex>
                  {i18next.language === item.value && (
                    <Icon
                      fontSize="2xl"
                      as={AiFillCheckCircle}
                      color="green.500"
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LanguagePage;
