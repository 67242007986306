import { Box, Spinner, useColorModeValue } from "@chakra-ui/react";
import useCheckLogin from "../../hook/useCheckLogin";

const LoadingPage = ({ children }) => {
  const { isLogin, isLoading } = useCheckLogin();

  const bgColor = useColorModeValue("rgb(255,255,255,0.6)", "rgb(0,0,0,0.6)");

  return (
    isLoading && (
      <Box
        position="fixed"
        top="0"
        left="0"
        display="flex"
        alignItems={"center"}
        justifyContent={`center`}
        w="100vw"
        h="100vh"
        backdropFilter={`blur(12px)`}
        zIndex={99999999}
        bg={bgColor}
      >
        <Spinner variant={`brandPrimary`} size={"xl"}></Spinner>
      </Box>
    )
  );
};

export default LoadingPage;
