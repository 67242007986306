import React, { useEffect, useMemo, useRef, useState } from "react";

import useDeviceType from "../../hook/useDeviceType";
import i18next from "i18next";
import GAMEITEM from "../GAMEITEM/GAMEITEM";
import PROMOTIONITEM from "../GAMEITEM/PROMOTIONITEM";
import { languageTransfer } from "../../utils/languageTransfer";
import { useSelector } from "react-redux";
import LoaderGameList from "../Loading/LoadingGameList";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Grid } from "@splidejs/splide-extension-grid";
import { ErrorBoundary } from "react-error-boundary";
import { useInView } from "react-intersection-observer";
import { CURRENCY_MENU } from "../../i18n/config";
import { Box, Flex } from "@chakra-ui/react";
import useCurrentCurrencyIsCrypto from "../../hook/useCurrentCurrencyIsCrypto";
import useCheckLogin from "../../hook/useCheckLogin";
import GAMEITEMV3 from "../GAMEITEM/GAMEITEM_v3";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const SwiperGameListV3 = React.forwardRef((props, ref) => {
  const {
    list = [], //遊戲list
    listType, //list類型
    itemClass, //item自定義class
    swiperProps, //放swiper的api
    isHot, //是否為熱門遊戲
    forceShow,
    customGameList,
    fullImageLayout, //是否為全圖模式
    customFixedWidth, //自定義寬度
    customHotTag, //自定義熱門標籤
  } = props;

  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  const { ref: containerRef, inView } = useInView({
    threshold: 0.75,
    rootMargin: "0px 0px 0px 0px",
  });

  useEffect(() => {
    if (listType === "promotion" || listType === "sponsor") {
      if (inView) {
        ref.current.splide.Components.AutoScroll.play();
      } else {
        ref.current.splide.Components.AutoScroll.pause();
      }
    }
  }, [inView]);

  const gamelist = useSelector((state) => state.isHandleAllGame); // 從Redux store取isHandleAllGame
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const currentCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isNowCurrency的值 用來判斷幣別

  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();

  const { isLogin } = useCheckLogin();

  const { allGamePlatform = [] } = isHandleAllGame;

  const {
    spaceBetween = 12,
    customPerView = "normal-layout",
    swiperClassName = "",
  } = swiperProps || {};

  const handleDrag = () => {
    const list = document.querySelector(".splide__list");
    list.classList.add("will-change-transform");
  };

  const handleDragged = () => {
    const list = document.querySelector(".splide__list");
    list.classList.remove("will-change-transform");
  };

  const customLayoutView = useMemo(() => {
    switch (customPerView) {
      case "normal-layout":
        return {
          640: 3,
          768: 5,
          1024: 6,
          1440: 7,
          1800: 10,
          2200: 10,
          "4xl": 9,
          "5xl": 10,
        };
      case "new-game-layout":
        return {
          640: 3,
          768: 5,
          1024: 6,
          1440: 7,
          1800: 9,
          2200: 9,
          "4xl": 7,
          "5xl": 10,
        };
      case "popular-layout":
        return {
          640: 3,
          768: 5,
          1024: 6,
          1440: 7,
          1800: 9,
          2200: 10,
          "4xl": 8,
          "5xl": 10,
        };
      case "live-layout":
        return {
          640: 1,
          768: 3,
          1024: 3,
          1440: 3,
          2200: 4,
        };
      case "promotion-layout":
        return {
          640: 1,
          768: 2,
          1024: 3,
          1440: 3,
          1800: 3,
          2200: 4,
          "4xl": 4,
          "5xl": 4,
        };
      case "confirm-layout":
        return {
          640: 3,
          768: 3,
          1024: 3,
          1440: 3,
          1800: 3,
          2200: 3,
          "4xl": 3,
          "5xl": 3,
        };
      case "game-box-layout":
        return {
          640: 3,
          768: 5,
          1024: 6,
          1440: 7,
          1800: 8,
          2200: 8,
        };
      case "profile-layout":
        return {
          640: 3,
          768: 3,
          1024: 3,
          1440: 3,
          1800: 3,
          2200: 3,
        };
      default:
        return {
          640: 3,
          768: 5,
          1024: 6,
          1440: 7,
          1800: 10,
          2200: 10,
          "4xl": 8,
          "5xl": 10,
        };
    }
  }, [customPerView]);

  const renderedSlides = useMemo(() => {
    if (loadingStatus) {
      return Array.from({ length: 12 }, (_, i) => (
        <SplideSlide key={`loading-${i}`}>
          <LoaderGameList
            className={`${itemClass} lg:h-[185px] md:h-[160px] h-[130px]`}
          />
        </SplideSlide>
      ));
    }
    // 過濾掉維護中的遊戲
    const filteredList = list.filter((item) => {
      const platformInfo = allGamePlatform.find(
        (p) => p.platform === item.game_platform
      );
      if (isLogin) {
        if (currentCurrencyIsCrypto) {
          return !platformInfo?.isUnderMaintenance;
        } else {
          return (
            !platformInfo?.isUnderMaintenance &&
            item.currencyCode?.includes(currentCurrency)
          );
        }
      } else {
        return true;
      }
    });

    // 根據過濾後的列表生成遊戲項目
    if (listType === "promotion") {
      return list.map((url, index) => (
        <SplideSlide key={`promotion-${index}`}>
          <PROMOTIONITEM itemClass={itemClass} originProps={url} />
        </SplideSlide>
      ));
    }
    return filteredList?.map((item, index) => {
      const gameName =
        item?.[`gameName${languageTransfer({ lang: i18next.language })}`];
      const gameNameEn = item?.gameNameEn;
      const platform = item?.game_platform;
      const gameSubType = item?.game_subtype;
      const gameCode = item?.gameCode;
      const gameType = item?.gameType;

      return (
        <SplideSlide key={`${platform}-${gameNameEn}-${index}`}>
          <GAMEITEMV3
            gameProps={{
              gameName,
              platform,
              gameCode,
              gameType,
              gameNameEn,
              gameSubType,
            }}
            forceShow={forceShow}
            itemIndex={index}
            isHot={isHot}
            customHotTag={customHotTag}
            specMask={customPerView === "live-layout"}
            originProps={item}
            itemClass={itemClass}
            fullImageLayout={fullImageLayout}
          />
        </SplideSlide>
      );
    });
  }, [
    loadingStatus,
    listType,
    list,
    itemClass,
    customPerView,
    allGamePlatform,
    currentCurrency,
    currentCurrencyIsCrypto,
  ]);

  const options = useMemo(() => {
    return {
      type: "slide",
      pagination: false,
      arrows: false,
      drag: "free",
      perPage: customFixedWidth ? false : customLayoutView?.["1440"],
      perMove: 1,
      gap: spaceBetween,
      lazyLoad: "nearby",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
      preloadPages: 1,
      keyboard: false,
      fixedWidth:
        customPerView === "live-layout"
          ? "260px"
          : customFixedWidth
          ? customFixedWidth
          : false,
      // mediaQuery: "min",
      breakpoints: customFixedWidth
        ? false
        : {
            640: {
              perPage: customLayoutView?.["640"],
              grid: {
                dimensions: [[2, 2]],
                gap: {
                  row: 10,
                  col: 10,
                },
              },
              fixedWidth: false,
            },
            768: {
              perPage: customLayoutView?.["768"],
            },
            1024: {
              perPage: customLayoutView?.["1024"],
            },
            1440: {
              perPage: customLayoutView?.["1440"],
            },
            1800: {
              perPage: customLayoutView?.["1800"],
            },
            2200: {
              perPage: customLayoutView?.["2200"],
            },
            2500: {
              perPage: customLayoutView?.["5xl"],
            },
          },
    };
  }, [customFixedWidth, customLayoutView, customPerView, spaceBetween]);
  return (
    <ErrorBoundary fallback={<div>&nbsp;</div>}>
      <Box ref={containerRef}>
        <Splide
          ref={ref}
          onDrag={handleDrag}
          onDragged={handleDragged}
          className={` h-full w-full ${swiperClassName}`}
          {...(listType === "promotion" || listType === "sponsor"
            ? { extensions: { AutoScroll } }
            : {})}
          {...(customPerView === "live-layout" ? { extensions: { Grid } } : {})}
          options={options}
        >
          {renderedSlides}
          {customGameList}
        </Splide>
      </Box>
    </ErrorBoundary>
  );
});

export default React.memo(SwiperGameListV3);
