import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { actionSignIn, actionSignUp } from "../../api/postApi";
import { waitTime } from "../../utils/waitTime";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaMobileAlt } from "react-icons/fa";
import { RiUser3Fill, RiUserSmileFill } from "react-icons/ri";
import { BiKey } from "react-icons/bi";
import GoogleLoginButton from "./components/googleLogin";
import TelegramLogin from "./components/telegramLogin";
import GoogleLoginV2 from "./components/GoogleLogin_v2";
import CreditPage from "../../utils/credit/credit_page";

const iconStyle = "text-gray-300";

const SigninForm = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`header.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_common_form = (key) => t(`settings.form.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const toast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user_name, password } = formData;

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSeePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    await actionSignIn({
      account: user_name,
      passwd: password,
    })
      .then((data) => {
        if (Cookies.get("token")) {
          Cookies.remove("token");
        }
        Cookies.set("token", data.token, { expires: 3 });

        dispatch({
          type: "INITIALIZE_API",
          navigate: navigate,
        });

        navigate("/");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={`7px`}>
        <FormControl isRequired>
          <FormLabel>{i18n_common_form("account")}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <RiUser3Fill className={iconStyle} />
            </InputLeftElement>
            <Input
              type="text"
              variant={`brandPrimary`}
              name="user_name"
              value={user_name}
              onChange={handleInputChange}
              placeholder={`${i18n_common("pleaseInput")} ${i18n_common_form(
                "account"
              )}`}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{i18n_common_form("password")}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiKey className={iconStyle} />
            </InputLeftElement>
            <Input
              type={showPassword ? "text" : "password"}
              variant={`brandPrimary`}
              name="password"
              value={password}
              onChange={handleInputChange}
              placeholder={`${i18n_common("pleaseInput")} ${i18n_common_form(
                "password"
              )}`}
            />
            <InputRightElement>
              {showPassword ? (
                <FaEyeSlash
                  className="cursor-pointer"
                  onClick={handleSeePassword}
                />
              ) : (
                <FaEye className="cursor-pointer" onClick={handleSeePassword} />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Divider my="10px" />
        <Flex direction="column" gap="15px" w="100%">
          <Button
            variant={`brandPrimary`}
            isLoading={buttonLoading}
            disabled={buttonLoading}
            py="20px"
            type="submit"
            w="100%"
          >
            {i18n("signin")}
          </Button>
          {/* <GoogleLoginButton
            setButtonLoading={setButtonLoading}
            buttonLoading={buttonLoading}
          /> */}
          {/* <CreditPage>
            <GoogleLoginV2 />
            <TelegramLogin
              setButtonLoading={setButtonLoading}
              buttonLoading={buttonLoading}
            />
          </CreditPage> */}
        </Flex>
      </VStack>
    </form>
  );
};

export default SigninForm;
