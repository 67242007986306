import React, { useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { Box, Button, Text } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";

const GoogleLoginV2 = () => {
  const buttonRef = useRef();

  useEffect(() => {
    window?.google?.accounts?.id?.renderButton(
      document.getElementById("google-sign-in-button"),
      {
        theme: "filled_blue",
        size: "large",
      }
    );
  }, []);

  return (
    <Box position="relative">
      <Button
        w={`100%`}
        display={`flex`}
        alignItems={`center`}
        fontWeight={`medium`}
        bg={`white`}
        py="20px"
        type="button"
        id="customBtn"
        border={`1px solid`}
        borderColor={`gray.300`}
        color={`gray.600`}
        className=""
        _hover={{
          bg: "gray.100",
        }}
        onClick={() => {
          if (buttonRef.current) {
            buttonRef.current.click();
          }
        }}
      >
        <FcGoogle className="text-xl" />
        <Text my="0" ml="5px">
          Google
        </Text>
      </Button>
      <Box
        ref={buttonRef}
        position="absolute"
        display="flex"
        justifyContent={`center`}
        id="google-sign-in-button"
        opacity={"0.001"}
        top="0px"
        left="0px"
        w="100%"
        zIndex="1"
        borderRadius={`common`}
        overflow="hidden"
      ></Box>
    </Box>
  );
};

export default GoogleLoginV2;
