import React from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { AiFillCheckCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import ResultPage from "../../components/ResultPage/ResultPage";
import QRCode from "qrcode.react";
import InputCopy from "../../components/CopyComponent/InputCopy";
import ReferQrcode from "../ReferCode/components/referQrcode";
import { Divider } from "@chakra-ui/react";

const DepositSuccess = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_deposit = (key) => t(`deposit.${key}`);

  // 从localStorage取得儲存的银行信息字符串
  const storedBankInfoString = localStorage.getItem("bankPaymentSelected");

  // 将存储的字符串解析为JavaScript对象
  const storedBankInfo = JSON.parse(storedBankInfoString);

  // 取銀行帳戶和銀行號碼
  const bankAcc = storedBankInfo?.bank_acc || "";
  const bankNum = storedBankInfo?.bank_num || "";
  const amount = localStorage.getItem("amounts");
  const randomNumber = localStorage.getItem("randomNumber");

  const navigate = useNavigate();

  return (
    <ResultPage
      buttonProps={{
        content: i18n("goToDepositData"),
        path: "../../profile/transaction",
      }}
      resultContent={`${i18n("youCanGo")} ${i18n("depositRoute")}，${i18n(
        "view"
      )}
  ${i18n("depositData")}`}
      resultText={i18n("depositSuccess")}
      pageType="success"
      pageTitle={i18n("depositSuccess")}
      CONTENT={
        <div>
          <div className="my-2 dark:text-light-white text-black">
            {i18n_deposit("bank_account")}
            <InputCopy copyLink={bankAcc} />
          </div>
          <div className="my-2 dark:text-light-white text-black">
            {i18n_deposit("bank_number")}
            <InputCopy copyLink={bankNum} />
          </div>
          <div className="my-2 dark:text-light-white text-black">
            {i18n_deposit("amount")}
            <InputCopy copyLink={amount} />
          </div>
          <div className="my-2 dark:text-light-white text-black">
            {i18n_deposit("bank_random_number")}{" "}
            <span className="text-red-500 text-sm ml-[3px]">
              ({i18n_deposit("hint")})
            </span>
            <InputCopy copyLink={randomNumber} />
          </div>
          <Divider />
          <div className="my-2 flex flex-col items-center dark:text-light-white text-black">
            QRCode
            <div className=" rounded-b-[5px] py-[20px] flex items-center justify-center">
              <div className="bg-base-light-white dark:bg-base-light-dark p-[5px] text-center">
                <ReferQrcode value={bankNum} />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DepositSuccess;
