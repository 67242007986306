import React from "react";
import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import SupplierList from "../../components/GameSelect/SupplierList";

const AllPlatform = () => {
  const { gamePlatform } = useParams();
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { platform, gameType, gameList, game_subtype } = isHandleAllGame;
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);
  return (
    <section className="bg-white p-4 max-w-screen-md mx-auto md:w-full">
      <CloseDetailPage />
      <section className="px-common-padding-x mb-[20px]">
        <SupplierList
          LIST={platform}
          customNavigate="/category/"
          className="flex flex-col gap-[10px]"
        />
      </section>
    </section>
  );
};

export default AllPlatform;
