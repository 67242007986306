import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { COMMON_WEB_PATH } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RiQuestionFill } from "react-icons/ri";
import CommonPopModal from "../../../components/Common/commonPopModal";
import { IoSearchCircleOutline } from "react-icons/io5";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import { getEventReward } from "../../../api/getApi";
import { waitTime } from "../../../utils/waitTime";
import useVipInfo from "../../../hook/useVipInfo";
import { notice } from "../../../redux/action/action";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../utils/formatNumber";
const RewardDashboard = ({
  props,
  gameConfirmMode,
  fetchingStatusLoading,
} = {}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`reward.dashboard.${key}`, { ...props });

  const cardBg = useColorModeValue("light.100", "dark.100");
  const titleColor = useColorModeValue("dark.100", "light.100");
  const textColor = useColorModeValue("dark.300", "light.300");
  const rewardTextColor = useColorModeValue("special.1", "special.gold");

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const dispatch = useDispatch();

  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const { current_symbol } = useVipInfo();
  const [openModal, setOpenModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    reward_title,
    reward_slogan,
    start_time,
    end_time,
    event_show_type,
    participating_platforms,
    event_code,
    reward_status,
    reward_detail,
  } = props;
  const reward_config = useMemo(() => {
    const { reward_condition } = props || {};

    const { rewards_type, currency, reward_limit, wagering } =
      reward_condition || {};
    const {
      attribute_subtype,
      attribute_type,
      attribute_value,
      attribute_rewards_specify_wallet,
      enable_rewards_limit,
    } = rewards_type || {};

    const {
      attribute_rewards_value,
      attribute_rewards_type,
      rewards_limit_value,
      rewards_limit_type,
    } = reward_limit || {};

    const { wagering_limit_type, wagering_limit_value, enable_wagering_limit } =
      wagering || {};

    const reward_multiple =
      attribute_rewards_type === "multiplier"
        ? attribute_rewards_value * 100
        : attribute_rewards_value;

    return {
      condition_text: `${i18n(attribute_type)} ${i18n(
        attribute_subtype
      )}(${attribute_subtype})${attribute_value}${currency}，${i18n(
        "can receive"
      )} ${reward_multiple} ${i18n(attribute_rewards_type, {
        current_symbol: currency,
      })} ${i18n("reward")}`,
      wagering_text: !enable_wagering_limit
        ? "-"
        : `${wagering_limit_value}${i18n(
            wagering_limit_type == "multiplier"
              ? "multiplier_2"
              : wagering_limit_type,
            {
              current_symbol: currency,
            }
          )}`,
      reward_limit: !enable_rewards_limit
        ? "-"
        : `${rewards_limit_value}${i18n(rewards_limit_type, {
            current_symbol: currency,
          })}`,
      attribute_rewards_specify_wallet: attribute_rewards_specify_wallet,
      attribute_type,
      current_condition: `${i18n("current", {
        type: i18n(attribute_type),
      })}`,
    };
  }, [props, isCurrencyExchange, current_symbol]);

  const buttonStatus = useMemo(() => {
    switch (reward_status) {
      case 0:
        return { disabled: true, text: i18n("un_complete"), hidden: false };
      case 1:
        return { disabled: false, text: i18n("receive"), hidden: false };
      case 2:
        return { disabled: true, text: i18n("received"), hidden: false };
      default:
        return { disabled: true, text: i18n("error"), hidden: true };
    }
  }, [reward_status]);

  const handleUseReward = async () => {
    setButtonLoading(true);
    try {
      await getEventReward({ event_uid: props.uid }).then((data) => {
        dispatch({ type: "UPDATE_EVENT_LIST" });
        dispatch({ type: "GET_MEMBER_DATA" });
        dispatch(
          notice({
            content: "Receive Success",
            type: "success",
          })
        );
      });
      await waitTime(1000);
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const {
    condition_text,
    wagering_text,
    reward_limit,
    attribute_rewards_specify_wallet,
    attribute_type,
    current_condition,
  } = reward_config || {};

  return (
    !buttonStatus?.hidden && (
      <Box
        display="flex"
        flexDirection={`column`}
        gap="30px"
        justifyContent={`space-between`}
        as="section"
        position="relative"
        p="common-x"
        borderRadius={`common`}
        borderLeft={"4px solid"}
        borderColor={reward_status == 2 ? "green.500" : "gray.400"}
        bg={cardBg}
        overflow="hidden"
        minW={gameConfirmMode ? "95%" : ""}
        backgroundImage={`${COMMON_WEB_PATH}/reward_icon/background_depoist.avif`}
        backgroundSize={`100% 100%`}
      >
        <Flex direction={`column`} gap="5px">
          <Flex alignItems={`center`} gap="5px">
            <Text
              as={reward_status == 2 ? "del" : "p"}
              fontWeight={`bold`}
              color={titleColor}
              fontSize="md"
            >
              {reward_slogan}
            </Text>

            <Icon
              cursor="pointer"
              _hover={{
                color: "yellow.400",
              }}
              onClick={() => {
                setOpenModal(true);
              }}
              color="yellow.500"
              fontSize="lg"
              as={RiQuestionFill}
            />
          </Flex>

          <Flex
            alignItems={`center`}
            gap="5px"
            justifyContent={`space-between`}
          >
            <Box
              borderRadius={`full`}
              display={`flex`}
              alignItems={`center`}
              justifyContent={`center`}
              px="10px"
              py="3px"
              fontSize="8px"
              fontWeight={`bold`}
              bg={reward_status == 2 ? "brand.500" : "gray.400"}
            >
              {reward_status == 2 ? i18n("completed") : i18n("un_completed")}
            </Box>
            <Flex alignItems={`center`} gap="3px">
              <Text
                as="p"
                color={`special.1`}
                fontWeight={`bold`}
                fontSize="10px"
              >
                {event_code}
              </Text>
              <CopyIcon fontSize="12px" copyLink={event_code} />
            </Flex>
          </Flex>
          <Flex direction={`column`} gap="2px">
            {reward_status != 2 ? (
              <>
                <Box maxW={`70%`}>
                  <Flex
                    color={textColor}
                    justifyContent={`space-between`}
                    fontSize="11px"
                    gap="10px"
                  >
                    <Text>{i18n("event_start")}</Text>
                    <Text>{start_time}</Text>
                  </Flex>

                  <Flex
                    color={textColor}
                    justifyContent={`space-between`}
                    fontSize="11px"
                    gap="10px"
                  >
                    <Text>{i18n("event_end")}</Text>
                    <Text>{end_time}</Text>
                  </Flex>
                  <Flex
                    color={textColor}
                    justifyContent={`space-between`}
                    fontSize="11px"
                    gap="10px"
                  >
                    <Text>{i18n("event_type")}</Text>
                    <Text>{i18n(attribute_type)}</Text>
                  </Flex>
                  <Flex
                    color={rewardTextColor}
                    justifyContent={`space-between`}
                    fontSize="11px"
                    gap="10px"
                  >
                    <Text>{current_condition}</Text>
                    <Text>
                      {formatNumber(
                        reward_detail?.[props.uid]?.[attribute_type]
                      ) || 0}
                      {props?.reward_condition?.currency}
                    </Text>
                  </Flex>
                </Box>
                <Divider my="5px" />
                <Text as="p" fontSize="sm" fontWeight={`medium`} mb="0px">
                  {condition_text}
                </Text>
                <Flex
                  alignItems={`center`}
                  color="green.500"
                  justifyContent={`space-between`}
                >
                  <Text as="p" fontSize="sm" fontWeight={`bold`} mb="0px">
                    ({i18n("current_reward_value")}:
                    {reward_detail?.[props.uid]?.[`reward_value`] || 0}
                    {props?.reward_condition?.currency})
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex
                  mt="10px"
                  alignItems={`center`}
                  color="green.500"
                  justifyContent={`space-between`}
                >
                  <Text as="p" fontSize="sm" fontWeight={`bold`} mb="0px">
                    {i18n("you_already")}
                    <Link
                      to="/profile/transaction?tab=event"
                      className="underline"
                    >
                      {i18n("click_go_to")}
                    </Link>
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Button
          isDisabled={
            buttonStatus?.disabled || buttonLoading || fetchingStatusLoading
          }
          isLoading={buttonLoading || fetchingStatusLoading}
          size="md"
          w="100%"
          variant={`brandPrimary`}
          onClick={handleUseReward}
        >
          {buttonStatus?.text}
        </Button>
        <Image
          src={`${COMMON_WEB_PATH}/reward_icon/${attribute_type?.toLowerCase()}.avif`}
          position="absolute"
          top="-8px"
          right="-21px"
          w="118px"
        />
        <CommonPopModal
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          size="sm"
          title={reward_title}
          children={
            <VStack spacing={`10px`}>
              <Flex
                w="100%"
                gap="30px"
                alignItems={`flex-start`}
                justifyContent={`space-between`}
              >
                <Text>{i18n("participating_platform")}：</Text>
                <Text>
                  {participating_platforms?.length === 0 ? (
                    "-"
                  ) : (
                    <Tooltip
                      hasArrow
                      isOpen={isOpen}
                      label={
                        <Box>
                          {participating_platforms?.map((item) => {
                            return ` ${item},`;
                          })}
                        </Box>
                      }
                      bg="gray.300"
                      color="black"
                    >
                      <Text
                        color="brand.500"
                        cursor="pointer"
                        className="underline"
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                        onClick={onToggle}
                      >
                        {i18n("view")}
                      </Text>
                    </Tooltip>
                  )}
                </Text>
              </Flex>
              <Flex
                w="100%"
                gap="30px"
                alignItems={`flex-start`}
                justifyContent={`space-between`}
              >
                <Text>{i18n("wagering")}：</Text>
                <Text>{wagering_text}</Text>
              </Flex>
              <Flex
                w="100%"
                gap="30px"
                alignItems={`flex-start`}
                justifyContent={`space-between`}
              >
                <Text>{i18n("max_reward_amount")}：</Text>
                <Text>{reward_limit}</Text>
              </Flex>
              <Flex
                w="100%"
                gap="30px"
                alignItems={`flex-start`}
                justifyContent={`space-between`}
              >
                <Text>{i18n("send_reward_to_game_platform")}：</Text>
                <Text>{attribute_rewards_specify_wallet || "-"}</Text>
              </Flex>
            </VStack>
          }
        />
      </Box>
    )
  );
};

export default RewardDashboard;
