import React from "react";
import CurrencySettings from "../../Settings/components/CurrencySettings";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const SupportCurrency = ({ selectProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);

  return (
    <Box display="flex" alignItems={`center`} gap="5px" px="5px">
      <Text w="100%" as="p" mb="0" color="special.1" fontSize="sm">
        {i18n("support_currency")}
      </Text>
      <CurrencySettings selectProps={selectProps} />
    </Box>
  );
};

export default SupportCurrency;
