import { IoCloseOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import useDeviceType from "../../hook/useDeviceType";
import { CloseButton } from "@chakra-ui/react";
import { useMemo } from "react";

const CloseComponent = ({ delay, setProps }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPage = usePreviousPagePath();

  const deviceType = useDeviceType();

  const pathArray = location.pathname.split("/");

  const profileIndex = pathArray.indexOf("profile");

  const backRoute = useMemo(() => {
    const path = pathArray.slice(0, profileIndex).join("/");
    return path === "" ? "/" : path;
  }, [pathArray, profileIndex]);

  return (
    <CloseButton
      onClick={() => {
        localStorage.clear();
        if (
          deviceType === "Mobile" &&
          !location.pathname.includes("/profile")
        ) {
          navigate("../../");
          return;
        } else {
          navigate(backRoute);
        }
      }}
      size="lg"
    />
  );
};

export default CloseComponent;
