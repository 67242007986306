import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const IconCircleWrapper = ({ children, divProps, className }) => {
  const iconHoverBg = useColorModeValue("light.300", "brand.600");
  const iconBg = useColorModeValue("light.200", "brand.500");
  const iconText = useColorModeValue("brand.500", "dark.100");
  return (
    <Box
      position={"relative"}
      width="38px"
      height="38px"
      _active={{
        transform: "scale(0.95)",
      }}
      _hover={{
        bg: iconHoverBg,
      }}
      cursor={`pointer`}
      bg={iconBg}
      color={iconText}
      borderRadius={`full`}
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      className={`${className}`}
      {...divProps}
    >
      {children}
    </Box>
  );
};

export default IconCircleWrapper;
