const iconStyle = "text-2xl mr-[10px]";

// let queryParams = new URLSearchParams(window?.location.search || "");

export const COMMON_ALT = process.env.REACT_APP_WEB_NAME || "DEMO";
export const COMMON_LOADING = 1500;
export const COMMON_WEB_PATH = process.env.REACT_APP_AWS_PATH;
export const BACK_DASHBOARD = process.env.REACT_APP_BACKEND_DASHBOARD_URL;
export const ROULETTE_PATH = "https://boom69.com/roulette/index.html";
export const DATA2_PATH = process.env.REACT_APP_I18N_PATH;
export const WEB_LAYOUT = process.env.REACT_APP_WEB_LAYOUT;

export const API_RELOAD = 50000;
export const DEPOSIT_AMOUNT_MIN = 10000;
export const WITHDRAW_AMOUNT_MIN = 600000;
export const BALANCE_RELOAD = 10000;

export const COMMON_FIXED_STYLE =
  "fixed top-0 left-0 w-full h-full overflow-scroll no-scrollbar z-[9999]";

export const CURRENCY_SYMBOL = {
  IDR: "Rp",
  VND: "₫",
  PHP: "₱",
  USD: "$",
  JPY: "¥",
  USDT: "₮",
  ETH: "Ξ",
};

export const DAILY_REWARDS = [
  "Seven-day login bonus",
  "Conditions for each day of login:",
  "Day 1: Log in to the platform to receive the bonus.",
  "Day 2: Place accumulated bets of 10 BRL to receive the bonus.",
  "Day 3: Log in to the platform to receive the bonus.",
  "Day 4: Make a top-up of any amount to receive the bonus.",
  "Day 5: Log in to the platform to receive the bonus.",
  "Day 6: Place accumulated bets of 20 BRL to receive the bonus.",
  "Day 7: Redeem the reward upon logging into the platform on the day, win up to 100 BRL and deposit bonus items.",
  "Bet the login bonus 10 times to make a withdrawal.",
];
