import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotice } from "../../redux/action/action";
import { IoCloseOutline } from "react-icons/io5";
import { useToast } from "@chakra-ui/react";

// const ProgressBlock = ({}) => {
//   const toast = useToast();

//   const dispatch = useDispatch();
//   const progressProps = useSelector((state) => state.isNotice);
//   const { content, title, type, timer, show } = progressProps;

//   useEffect(() => {
//     if (show) {
//       dispatch(clearNotice());
//     }
//   }, [show]);

//   return (
//     show &&
//     toast({
//       id: "progress",
//       title: title,
//       description: content,
//       status: type
//         ? type
//         : type === "success"
//         ? "success"
//         : type === "warning"
//         ? "warning"
//         : "error",
//       duration: 2000,
//       isClosable: true,
//     })
//   );
// };

// export default ProgressBlock;
const ProgressBlock = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const progressProps = useSelector((state) => state.isNotice);
  const { content, title, type, timer, show } = progressProps;

  useEffect(() => {
    if (show) {
      toast({
        id: "progress",
        title: title,
        description: content,
        status: type || "error",
        duration: timer || 2000,
        isClosable: true,
      });
      dispatch(clearNotice());
    }
  }, [show, toast, title, content, type, timer, dispatch]);

  return null;
};

export default ProgressBlock;
