import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import LazyImage from "../../components/Loading/lazyImage";
import { getEventList, getEventReward } from "../../api/getApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDeviceType from "../../hook/useDeviceType";
import i18next from "i18next";
import { checkEventStatus } from "../../api/postApi";
import { useSelector } from "react-redux";
import { waitTime } from "../../utils/waitTime";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import useProcessEventList from "../../hook/useProcessEventList";
import { useTranslation } from "react-i18next";
import GetEventImagePath from "../../hook/useEventImagePath";
import { DATA2_PATH } from "../../constant";
import { languageMapping } from "../../i18n/config";
import useCheckLogin from "../../hook/useCheckLogin";
import "../../styles/jodit.css";
import { FaArrowLeftLong } from "react-icons/fa6";
const EventDetail = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`promotions.${key}`);

  const isMobile = useDeviceType() === "Mobile";

  const { isLogin } = useCheckLogin();

  const eventList = useSelector((state) => state.isEventList);

  const bgColor = useColorModeValue("light.200", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");
  const conditionBg = useColorModeValue("light.200", "dark.100");

  const processEventList = useProcessEventList({ event_list: eventList });

  const [checking, setChecking] = useState(false);

  const { uid } = useParams();

  const navigate = useNavigate();

  const eventDetail = useMemo(() => {
    return processEventList.find((item) => item.uid == uid);
  }, [uid, processEventList]);

  const {
    events_copywriting,
    start_time,
    end_time,
    is_active,
    participating_platforms,
    event_code,
    reward_title,
    reward_slogan,
    reward_sub_title,
    reward_content,
    is_between_time,
  } = eventDetail || {};

  const condition = useMemo(() => {
    return {
      participating_platforms: !participating_platforms
        ? "-"
        : participating_platforms?.map((item, index) => {
            return `${item}${
              index === participating_platforms.length - 1 ? "" : "、"
            }`;
          }),
      event_code: !event_code ? "-" : event_code,
    };
  }, [uid]);

  const handleGetEventReward = () => {
    if (isLogin) {
      navigate("profile/rewards");
    } else {
      navigate("signin");
    }
  };

  return (
    <motion.main className="px-common-padding-x py-common-padding-y">
      <Flex mb="20px">
        <Link to="/promotion">
          <Flex alignItems={"center"} gap="10px">
            <Icon as={FaArrowLeftLong} cursor="pointer" fontSize="2xl" />
          </Flex>
        </Link>
      </Flex>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={"15px"}
      >
        <Box
          as="section"
          borderRadius={`common`}
          w="100%"
          overflow="hidden"
          bg={bgColor}
        >
          <Box
            h={{
              base: "200px",
              md: "290px",
            }}
            w="100%"
            borderRadius={`common`}
          >
            <Image
              w="100%"
              h="100%"
              objectFit={`cover`}
              // src={GetEventImagePath(uid)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_${
                  isMobile ? "mobile" : "pc"
                }_image.png`;
              }}
              src={`${DATA2_PATH}/event/${uid}/${languageMapping(
                i18next.language
              )}/${uid}_${languageMapping(i18next.language)}_${
                isMobile ? "mobile" : "pc"
              }_image.png`}
            />
          </Box>
          <Box
            as="section"
            w="100%"
            borderRadius={`common`}
            minH="145px"
            bg={bgColor}
            position="relative"
            my="15px"
            display="flex"
            flexDirection={`column`}
            alignItems={`center`}
            gap="15px"
            fontWeight={`medium`}
          >
            {/* //如果有start_date end_date就顯示 沒有就不顯示 */}
            <Box
              display="flex"
              flexDirection={`column`}
              gap="5px"
              alignItems={`center`}
            >
              <Text
                as="h4"
                color={textColor}
                fontSize="2xl"
                fontWeight={`semibold`}
                textAlign={`center`}
              >
                {reward_title}
              </Text>
              <Text
                as="h5"
                color={textColor}
                fontSize="lg"
                fontWeight={`semibold`}
              >
                {reward_sub_title}
              </Text>
              <Box
                display="flex"
                flexDirection={`column`}
                gap="5px"
                alignItems={`center`}
              >
                <Text as="span" color="brand.500">
                  {i18n("event_time")}
                </Text>
                <Text as="span" color="brand.500">
                  {start_time} ~ {end_time}
                </Text>
              </Box>
            </Box>
            <Divider />
            <Box
              as="section"
              dangerouslySetInnerHTML={{ __html: reward_content }}
              color={textColor}
              w="100%"
              py="15px"
              px="20px"
              className="p-4 md:p-8 jodit-wysiwyg"
            ></Box>
            {is_between_time && (
              <Flex alignItems={`center`} gap="10px">
                <Button
                  onClick={handleGetEventReward}
                  isLoading={checking}
                  // disabled={checking || currentEventStatus?.disabled}
                  variant={`brandPrimary`}
                  type="button"
                >
                  {i18n("go_to_reward")}
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
        <Text
          as="h5"
          color={textColor}
          fontSize="xl"
          fontWeight={`semibold`}
          textAlign={`center`}
          className="text-gold-text"
        >
          {i18n("reward_way")}
        </Text>
        <Flex direction={`column`} gap="15px" w="100%">
          {Object.keys(condition)?.map((item) => {
            return (
              <Box
                bg={conditionBg}
                borderRadius={`common`}
                w="100%"
                display="flex"
                fontSize="lg"
                gap="7px"
                p="20px"
              >
                <Text as="span" fontWeight={`semibold`} color={textColor}>
                  {i18n(item)}:
                </Text>
                <Text
                  as="span"
                  fontWeight={`semibold`}
                  className="text-gold-text"
                >
                  {condition[item]}
                </Text>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </motion.main>
  );
};

export default EventDetail;
