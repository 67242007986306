import { useEffect, useRef, useState } from "react";
import RouletteSound from "../../../images/Roulette/roulette-sound.mp3";

const Sound = ({ startTime, endTime, startPlayAudio = false }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (startPlayAudio) {
      playAudio();
    } else {
      stopAudio();
    }
  }, [startPlayAudio]);
  return (
    <>
      <audio
        ref={audioRef}
        src={`${RouletteSound}#t=${startTime},${endTime}`}
      />
    </>
  );
};

export default Sound;
