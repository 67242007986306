import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH } from "../../constant";
import { IoIosArrowDropright } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useVipInfo from "../../hook/useVipInfo";
import { openChat } from "../../redux/action/member/memberAction";
import useCheckLogin from "../../hook/useCheckLogin";

const right_card = [
  {
    label: "Withdrawal",
    link: "profile/withdraw",
    image: `${COMMON_WEB_PATH}/ui-image/recommend-bg.png`,
    icon: `${COMMON_WEB_PATH}/ui-image/recommend-icon.png`,
  },
  {
    label: "Reward Events",
    link: "profile/rewards",
    image: `${COMMON_WEB_PATH}/ui-image/reward-bg.png`,
    icon: `${COMMON_WEB_PATH}/ui-image/reward-icon.png`,
  },
  {
    label: "Deposit Bonus",
    link: "profile/deposit",
    image: `${COMMON_WEB_PATH}/ui-image/deposit-bg.png`,
    icon: `${COMMON_WEB_PATH}/ui-image/deposit-icon.png`,
  },
  {
    label: "VIP",
    link: "profile/vip",
    image: `${COMMON_WEB_PATH}/ui-image/vip-bg.png`,
    icon: `${COMMON_WEB_PATH}/ui-image/vip-icon.png`,
  },
];

const RightPromotion = () => {
  const { t } = useTranslation();
  const i18n_ranking = (key) => t(`ranking.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const { name } = memberData || {};

  const { current_level, current_wager } = useVipInfo();

  const textColor = useColorModeValue("light.100", "light.100");
  const boxBg = useColorModeValue("light.200", "dark.100");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLogin } = useCheckLogin();

  const right_button_card = [
    {
      label: "Deposit Now",
      link: "profile/deposit",
      image: `${COMMON_WEB_PATH}/ui-image/deposit-option.png`,
    },
    {
      label: "Online Service",
      link: "service-group",
      image: `${COMMON_WEB_PATH}/ui-image/online-option.png`,
      func: () => {
        dispatch(openChat());
      },
    },
    // {
    //   label: "VIP",
    //   link: "profile/vip",
    //   image: `${COMMON_WEB_PATH}/ui-image/vip2-option.png`,
    // },
  ];

  return (
    <Flex direction={`column`} p="common-y" pt="53px">
      <Grid gap="0px 10px" templateColumns="repeat(2, minmax(0, 1fr))">
        {right_card.map((item) => {
          return (
            <Link to={isLogin ? item.link : "signin"}>
              <Flex
                pb="15px"
                _hover={{
                  transform: "scale(1.05)",
                }}
                h="160px"
                w="140px"
                alignItems={`flex-end`}
                position="relative"
                className="transition duration-300"
              >
                <AbsoluteCenter w="140px" top={`30px`}>
                  <Image src={item.icon} />
                </AbsoluteCenter>
                <Box
                  w="155px"
                  h="140px"
                  cursor="pointer"
                  transition={`all 0.3s`}
                  backgroundPosition={`center`}
                  backgroundSize="100% 100%"
                  backgroundImage={`url(${item.image})`}
                  display="flex"
                  flexDirection={`column`}
                  justifyContent={`flex-end`}
                  pl="15px"
                  pr="10px"
                  py="30px"
                >
                  <Flex
                    alignItems={`center`}
                    fontSize="lg"
                    fontWeight={`bold`}
                    justifyContent={`space-between`}
                    color={textColor}
                  >
                    <Text w="90px" className="text-overflow">
                      {i18n_ranking(item.label)}
                    </Text>
                    <Icon fontSize="2xl" as={IoIosArrowDropright} />{" "}
                  </Flex>
                </Box>
              </Flex>
            </Link>
          );
        })}
      </Grid>
      <Flex mb="20px" direction={`column`} gap="15px">
        {right_button_card.map((item) => {
          return (
            <Link
              onClick={() => {
                if (item.func) {
                  item.func();
                }
              }}
              to={item.func ? "" : isLogin ? item.link : "signin"}
            >
              <Box
                w="100%"
                boxShadow={`lg`}
                h="70px"
                borderRadius="25px"
                border="2px solid"
                borderColor="right-promotion-box.border"
                bgGradient="linear(to-br, right-promotion-box.from, right-promotion-box.to)"
                cursor="pointer"
                _hover={{
                  transform: "scale(1.02)",
                }}
                transition={`all 0.3s`}
                position="relative"
                overflow="hidden"
                display="flex"
                alignItems={`center`}
                px="20px"
              >
                <Text
                  position="relative"
                  zIndex={1}
                  fontSize="xl"
                  fontWeight={`bold`}
                  color="black"
                  letterSpacing={`tighter`}
                >
                  {i18n_ranking(item.label)}
                </Text>
                <Image
                  position="absolute"
                  right="0"
                  w="150px"
                  src={item.image}
                />
              </Box>
            </Link>
          );
        })}
      </Flex>
      {/* <Box borderRadius={`common`} p="10px" bg={boxBg} mb="20px">
        <Grid
          templateColumns={`repeat(2, minmax(0, 1fr))`}
          borderRadius={`common`}
          p="7px"
          bg="brand.500"
          color="dark.100"
        >
          <Flex flexDirection={`column`} gap="5px">
            <Icon fontSize="lg" as={RiUserFill} />
            <Text fontWeight={`semibold`} fontSize="sm">
              我
            </Text>
          </Flex>
          <Flex
            flexDirection={`column`}
            alignItems="center"
            justifyContent={`center`}
            gap="5px"
          >
            <Icon fontSize="lg" as={FaRankingStar} />
            <Text fontWeight={`semibold`} fontSize="sm">
              No.66
            </Text>
          </Flex>
          <Flex
            flexDirection={`column`}
            alignItems="flex-end"
            justifyContent={`flex-end`}
            gap="5px"
          >
            <Icon fontSize="lg" as={FaCircleDollarToSlot} />
            <Text fontWeight={`semibold`} fontSize="sm">
              {formatNumber(current_wager) || 0}
            </Text>
          </Flex>
        </Grid>
        <Divider mt="10px" />
        <TableContainer my="10px">
          <Ranking />
        </TableContainer>
      </Box> */}
    </Flex>
  );
};

export default RightPromotion;
