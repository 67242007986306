import React from "react";
import GameCatalog from "./GameCatalog";
import { useSelector } from "react-redux";
import useSubTypeList from "../hook/useSubTypeList";
import useCasinoType from "../hook/useCasinoType";
import { filterType } from "../hook/useGameListFilter";
import GameCatalog_v2 from "./GameCatalog_v2";

const FavoriteGameCatalogV2 = ({ swiperProps, wrapperClassName }) => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame); //redux純取值用useSelector就好 不用dispatch
  const subList = useSubTypeList();

  const { platform, topGameList, newGameList, categorizedGames } =
    handleAllGame; //從handleAllGame中提取了 platform、gameList 和 gameType 属性。可以在组件中直接使用这些属性，而不必每次都通过handleAllGame 来訪問它们。
  const casinoType = useCasinoType();

  const favoriteGames = filterType({
    gameList: categorizedGames?.MyFavorite,
    casinoType: casinoType,
  });

  return (
    <GameCatalog_v2
      forceShow
      list={favoriteGames}
      wrapperClassName={`${
        favoriteGames?.length === 0 && "hidden"
      } ${wrapperClassName}`}
      mainTitle={"MyFavorite"}
      typeTitle={``}
      observeFalse
      swiperProps={swiperProps}
    />
  );
};

export default FavoriteGameCatalogV2;
