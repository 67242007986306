import { Box } from "@chakra-ui/react";
import React from "react";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";

const ScrollToTopIcon = ({ handleScrollTop }) => {
  return (
    <Box
      onClick={() => {
        handleScrollTop();
      }}
      zIndex={999}
      cursor="pointer"
      fontSize="3xl"
      // border="1px solid"
      // borderColor="brand.700"
      bg="brand.500"
      color="white"
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      borderRadius={`full`}
      w={{
        base: "44px",
        md: "55px",
      }}
      h={{
        base: "44px",
        md: "55px",
      }}
    >
      <AiOutlineVerticalAlignTop />
    </Box>
  );
};

export default ScrollToTopIcon;
