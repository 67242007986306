import { Box, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";

const Paginator = ({ pagination, setCurrentPage, currentPage }) => {
  const { current_page, last_page, from, to, total } = pagination || {};

  let [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const boxBg = useColorModeValue("light.300", "dark.100");
  const boxHoverBg = useColorModeValue("light.300", "dark.300");

  const totalPage = useMemo(() => {
    return Number(last_page);
  }, [pagination]);

  const no_data = useMemo(() => {
    if (!from && !to) {
      return true;
    }
  }, [pagination]);

  return no_data ? null : (
    <Box display="flex" alignItems="center" justifyContent={`center`}>
      <Flex bg={boxBg} borderRadius={`common`} overflow="hidden">
        <Box
          fontSize="xl"
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          bg={boxBg}
          w="44px"
          h="44px"
          cursor="pointer"
          _active={{
            bg: boxHoverBg,
          }}
          _hover={{
            bg: boxHoverBg,
          }}
          onClick={() => {
            if (currentPage === 1) {
              return;
            }
            searchParams.set("page", currentPage + 1);
            setSearchParams(searchParams);
            // setSearchParams({ page: currentPage - 1 });
            setCurrentPage(currentPage - 1);
          }}
        >
          <Icon as={MdOutlineKeyboardArrowLeft} />
        </Box>
        <Flex className="no-scrollbar" overflowX="scroll">
          {Array.from({ length: totalPage })?.map((item, index) => {
            return (
              <Box
                fontSize="lg"
                display={`flex`}
                alignItems={`center`}
                justifyContent={`center`}
                bg={page == index + 1 ? boxHoverBg : boxBg}
                minW="33px"
                maxW="33px"
                minH="44px"
                maxH="44px"
                cursor="pointer"
                _active={{
                  bg: boxHoverBg,
                }}
                _hover={{
                  bg: boxHoverBg,
                }}
                onClick={() => {
                  searchParams.set("page", index + 1);
                  setSearchParams(searchParams);
                  setCurrentPage(index + 1);
                }}
              >
                {index + 1}
              </Box>
            );
          })}
        </Flex>
        <Box
          fontSize="xl"
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          bg={boxBg}
          w="44px"
          h="44px"
          cursor="pointer"
          _active={{
            bg: boxHoverBg,
          }}
          _hover={{
            bg: boxHoverBg,
          }}
          onClick={() => {
            if (currentPage === totalPage) {
              return;
            }
            searchParams.set("page", currentPage + 1);
            setSearchParams(searchParams);

            setCurrentPage(currentPage + 1);
          }}
        >
          <Icon as={MdOutlineKeyboardArrowRight} />
        </Box>
      </Flex>
    </Box>
  );
};

export default Paginator;
