import React, { useEffect, useMemo, useState } from "react";
import { getBetLog } from "../../../api/getApi";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import NotFound from "../../../components/ProgressBlock/NotFound";
import HistoryBetLogDashboard from "./HistoryBetLogDashboard";
import { Box, Flex } from "@chakra-ui/react";
import Paginator from "../../Transaction/components/paginator";
import { waitTime } from "../../../utils/waitTime";
import { useSelector } from "react-redux";
import useRenameMultiPlatform from "../../../hook/useRenameMultiPlatform";

const HistoryBet = ({ datePicker, platformSelect }) => {
  const [gameRecord, setGameRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    from: null,
    to: null,
    total: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const { origin_platform } = useSelector((state) => state.isHandleAllGame);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getBetLog({
        paramsData: {
          str_date: datePicker.str_date,
          end_date: datePicker.end_date,
          platform: platformSelect === "All" ? "" : platformSelect,
          limit: 25,
          current_page: currentPage,
        },
      })
        .then((res) => {
          const { current_page, last_page, from, to, total, data } = res?.data;

          setPagination({
            current_page,
            last_page,
            from,
            to,
            total,
          });
          setGameRecord(data);
        })
        .finally(() => {});
      await waitTime(1000);
      setIsLoading(false);
    };
    fetchData();
  }, [datePicker, platformSelect, currentPage]);

  const process_game_record = useRenameMultiPlatform({
    origin_data: gameRecord,
  });

  return (
    <Flex w="100%" direction={`column`} gap={4} spacing={4}>
      <LoadingBetRecord commonLoading={isLoading}>
        {gameRecord?.length === 0 ? (
          <NotFound />
        ) : (
          process_game_record?.map((item, index) => {
            const {
              platform,
              memId,
              status,
              round_id,
              bet,
              net_win,
              settle_time,
            } = item;
            return (
              <HistoryBetLogDashboard
                status={status}
                index={`${round_id}-${index}`}
                gameName={platform}
                bet={bet}
                winLose={net_win}
                roundId={round_id}
                settleTime={settle_time}
              />
            );
          })
        )}
      </LoadingBetRecord>
      <Box>
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
        />
      </Box>
    </Flex>
  );
};

export default HistoryBet;
