import { Tag } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TransactionTag = ({ status } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`status.${key}`);

  const tagConfig = useMemo(() => {
    switch (status) {
      case 0:
        return {
          color: "gray",
          text: i18n("0"),
        };
      case 1:
        return {
          color: "yellow",
          text: i18n("1"),
        };
      case 2:
        return {
          color: "green",
          text: i18n("2"),
        };
      case 3:
        return {
          color: "red",
          text: i18n("99"),
        };
      case 99:
        return {
          color: "red",
          text: i18n("99"),
        };
      default:
        return {
          color: "red",
          text: i18n("error"),
        };
    }
  }, [status]);

  return (
    <Tag
      minW="70px"
      display="flex"
      justifyContent={`center`}
      colorScheme={tagConfig?.color}
      variant="solid"
    >
      {tagConfig?.text}
    </Tag>
  );
};

export default TransactionTag;
