import React, { useMemo } from "react";
import { CURRENCY_MENU } from "../../../i18n/config";
import { useSelector } from "react-redux";
import { Select } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const PaymentCurrencySettings = ({
  onCurrencyChange,
  selectedCurrency,
  filterFrontStatus,
}) => {
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const [searchParams, setSearchParams] = useSearchParams();

  const currencyList = useMemo(() => {
    if (filterFrontStatus) {
      return Object.keys(isCurrencyExchange).filter(
        (item) => isCurrencyExchange[item]?.status_front === 1
      );
    } else {
      return Object.keys(isCurrencyExchange);
    }
  }, [isCurrencyExchange, filterFrontStatus]);

  return (
    <Select
      variant={`brandPrimary`}
      value={selectedCurrency}
      onChange={(e) => {
        setSearchParams({ currency: e.target.value });
        onCurrencyChange(e.target.value);
      }}
      size="sm"
      w="150px"
    >
      {currencyList.map((option) => (
        <option key={option}>{option}</option>
      ))}
    </Select>
  );
};

export default PaymentCurrencySettings;
