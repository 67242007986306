import { Box, Flex, Text, chakra, shouldForwardProp } from "@chakra-ui/react";
import React from "react";
import Jackpot from "./Jackpot";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { useParams } from "react-router-dom";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const JackpotWrapper = ({ gameMainTypeFromProps }) => {
  const { gameMainType } = useParams();
  return (
    <ChakraBox
      key={gameMainTypeFromProps || gameMainType}
      {...gameListFromBottom}
      mt={{
        base: "0px",
        md: "30px",
      }}
    >
      <Flex alignItems={`center`} justifyContent={`center`}>
        <Box
          bgGradient="linear(to-b,v3-second-brand.500,v3-second-brand.900)"
          py="1px"
          px="45px"
          borderTopRadius={"25px"}
          borderTop="2px solid"
          borderX="2px solid"
          borderColor="leaderBoard.v3-border"
        >
          <Text fontSize="14px">JACKPOT</Text>
        </Box>
      </Flex>
      <Box
        display="flex"
        w="100%"
        alignItems={`center`}
        justifyContent={`center`}
      >
        <Jackpot />
      </Box>
    </ChakraBox>
  );
};

export default JackpotWrapper;
