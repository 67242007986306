import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Progress, Text } from "@chakra-ui/react";

import LazyImage from "../../../components/Loading/lazyImage";
import useVipInfo from "../../../hook/useVipInfo";
import { formatNumber } from "../../../utils/formatNumber";
import VipPromotionList from "./vipPromotionList";
import { COMMON_WEB_PATH } from "../../../constant";
import useNextVipLevelConfig from "../../../hook/useNextVipLevelConfig";

const MiniCard = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const vipList = useSelector((state) => state.isVipDetail);

  const navigate = useNavigate();
  const vipConfig = useVipInfo();
  const { current_level, current_wager, current_symbol } = vipConfig;

  const { next_level_criteria_data } = useNextVipLevelConfig({
    level: current_level + 1,
  });

  const wager_percent = useMemo(() => {
    const result =
      Number(current_wager / next_level_criteria_data?.criteria_amount) * 100;
    if (result > 100) return 100;
    return formatNumber(result, { ignore_crypto: true });
  }, [current_wager, next_level_criteria_data?.criteria_amount]);

  return (
    <Box
      as="section"
      display="flex"
      flexDirection={`column`}
      gap="10px"
      alignItems={`center`}
      justifyContent={`center`}
      p="15px"
      borderRadius={`common`}
      bgGradient={`linear(to-br, #697ABA,#2F2164)`}
      // className={`bg-gradient-to-br ${VipList[nowLevel]?.background}`}
    >
      <Flex w="100%" alignItems={`center`} justifyContent={`space-between`}>
        <Flex flexDirection={`column`}>
          <Text as="span" fontWeight={`medium`} color={`white`}>
            {i18n("nowBet")}.
          </Text>
          <Text
            as="span"
            fontWeight={`semibold`}
            color={`white`}
            fontSize="xl"
            letterSpacing={`wide`}
          >
            {formatNumber(current_wager, { point: false })} {current_symbol}
          </Text>
        </Flex>
        <Box w="44px" h="44px">
          <Image
            w="44px"
            src={`${COMMON_WEB_PATH}/vip/vip${current_level}_l.avif`}
            // borderRadius={"full"}
          />
        </Box>
      </Flex>
      <Progress
        mt="5px"
        w="100%"
        value={wager_percent}
        size="xs"
        variant={`brandPrimary`}
      />
      <Flex alignItems={`center`} justifyContent={`space-between`} w="100%">
        <Text as="span" color="green.200" fontWeight={`bold`}>
          {wager_percent}%
        </Text>
        <Text as="span" fontSize="sm" color="special.1">
          <Text
            as="span"
            onClick={() => {
              navigate(`profile/vip/${vipList?.[current_level + 1]?.level}`);
            }}
            color="green.200"
            fontWeight={`bold`}
            cursor={`pointer`}
            className="underline"
          >
            {`${vipList[current_level + 1]?.name?.toUpperCase()}`}
          </Text>
        </Text>
      </Flex>
      <VipPromotionList customLevel={1} className={`w-full`} />
    </Box>
  );
};

export default MiniCard;
