import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useBonusAmount = () => {
  const bonusWallet = useSelector((state) => state.isBonus);

  const total_bonus = useMemo(() => {
    return Object?.keys(bonusWallet)?.reduce((acc, item) => {
      return acc + bonusWallet[item];
    }, 0);
  }, [bonusWallet]);

  return total_bonus;
};

export default useBonusAmount;
