import React, { useMemo } from "react";

const useGetAgentName = () => {
  const hostname = window.location.hostname.split(".")[0];

  const expectedName = ["127", "localhost", "boom69", "creditwww"];
  const isExpectedName = useMemo(() => {
    if (expectedName.includes(hostname)) {
      return "";
    } else {
      return hostname;
    }
  }, [hostname]);
  return isExpectedName;
};

export default useGetAgentName;
