import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import { IoIosMoon, IoMdSunny } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/action/action";
import IconCircleWrapper from "../../../components/Icon/IconCircleWrapper";
import { Button, Flex, useColorMode } from "@chakra-ui/react";

const ColorSwitcher = ({ wordHidden, colorClassName }) => {
  const isDarkMode = useSelector((state) => state.isTheme === "dark");
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const toggleTheme = () => {
    const newTheme = isDarkMode ? "light" : "dark";
    dispatch(setTheme(newTheme));
    toggleColorMode();
    Cookies.set("color-mode", newTheme);
  };

  return (
    <Flex alignItems={`center`} gap="5px">
      <Flex
        alignItems={`center`}
        justifyContent={`space-between`}
        width={`100%`}
        gap="5px"
        className={`text-dark-gray-text dark:text-base-input-dark-text ${colorClassName}`}
      >
        {wordHidden || <span>Color Theme</span>}
        <Button
          borderRadius={`full`}
          px="0px"
          bg="none"
          variant={`brandPrimary`}
          fontSize={`xl`}
          onClick={toggleTheme}
        >
          <IconCircleWrapper className={`md:flex min-h-[38px] min-w-[38px]`}>
            {colorMode === "dark" ? <IoIosMoon /> : <IoMdSunny />}
          </IconCircleWrapper>
        </Button>
      </Flex>
    </Flex>
  );
};

export default ColorSwitcher;
