import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brandPrimary = defineStyle({
  background: "brand.500",
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "brand.600",
    _disabled: {
      background: "brand.600",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const brandGradientPrimary = defineStyle({
  bgGradient: "linear(to-b, brand.100, brand.700)",
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "brand.600",
    _disabled: {
      background: "brand.600",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const secondBrandPrimary = defineStyle({
  bg: "v3-second-brand.700",
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "v3-second-brand.600",
    _disabled: {
      background: "v3-second-brand.600",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const specialGoldPrimary = defineStyle({
  bg: "special.gold",
  color: "black",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "special.gold",
    _disabled: {
      background: "special.gold",
      color: "black",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    brandPrimary,
    brandGradientPrimary,
    specialGoldPrimary,
    secondBrandPrimary,
  },
});
