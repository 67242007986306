export const gameTypeColor = {
  SLOT: "#AEFC65",
  LIVE: "#FF659F",
  SPORT: "#99D6FF",
  TABLE: "#CCCCCC",
  EGAME: "#A16CD8",
  PROMOTION: "#FFBC6C",
  LOTTERY: "#3EAB9C",
  HOME: "#A49286",
  FH: "#7194DB",
  HOT: "#FC6D6D",
  BLOCKCHAIN: "#C857CE",
};
