import { Box, useColorModeValue } from "@chakra-ui/react";
import { stylesConfig } from "../../../helpers/stylesConfig";

const PromoCardPc = () => {
  const baseBg = useColorModeValue("light.100", "dark.100");

  return (
    <Box
      w={`400px`}
      minH={`130px`}
      display={{
        base: "none",
        sm: "block",
      }}
      borderTopRadius="common"
      style={{
        backgroundImage: `url(${stylesConfig.mainLogo})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      bg={baseBg}
    ></Box>
  );
};

export default PromoCardPc;
