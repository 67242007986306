import { AiFillCheckCircle } from "react-icons/ai";
import CloseDetailPage from "../CloseComponent/CloseDetailPage";
import DetailWrapper from "../detailWrapper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GoAlertFill } from "react-icons/go";
import { useMemo } from "react";
import QRCode from "qrcode.react";
import InputCopy from "../CopyComponent/InputCopy";
import { HiChevronLeft } from "react-icons/hi";
import {
  AbsoluteCenter,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const ResultPage = ({
  pageTitle,
  pageType = "success",
  resultText,
  resultContent,
  buttonProps,
  children,
  CONTENT,
  hasPrev = false,
} = {}) => {
  const { content, path, onClick } = buttonProps;

  const textColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();
  const style = useMemo(() => {
    switch (pageType) {
      case "success":
        return {
          circleStyle: "bg-green-500",
          iconStyle: "text-green-500 text-5xl",
        };
      case "service":
        return {
          circleStyle: "bg-yellow-500",
          iconStyle: "text-yellow-500 text-5xl",
        };
      case "warning":
        return {
          circleStyle: "bg-yellow-500",
          iconStyle: "text-yellow-500 text-5xl",
        };
      default:
        return {
          circleStyle: "bg-green-500",
          iconStyle: "text-green-500 text-5xl",
        };
    }
  }, [pageType]);

  const { circleStyle = "", iconStyle = "" } = style;
  return (
    <DetailWrapper
      closeAnimate
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[1] bg-gradient-to-br`}
    >
      <CloseDetailPage
        title={pageTitle}
        hasPrev={
          //hasPrev是用來接AlreadyBind.js傳來的 如果hasPrev=true的話就顯示回上一頁的icon
          hasPrev ? (
            hasPrev
          ) : (
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => {
                navigate("..");
                localStorage.clear();
              }}
            />
          )
        }
      />
      {children}
      <Flex
        as="article"
        direction={`column`}
        gap="15px"
        p="15px"
        pt="50px"
        fontWeight={`medium`}
      >
        <Flex
          alignItems={`center`}
          justifyContent={`center`}
          mb="40px"
          position="relative"
        >
          <AbsoluteCenter
            w="100px"
            h="100px"
            opacity={0.15}
            borderRadius="full"
            className={`${circleStyle}`}
          ></AbsoluteCenter>
          {pageType === "success" ? (
            <AiFillCheckCircle className={iconStyle} />
          ) : pageType === "service" ? (
            <RiCustomerService2Fill className={iconStyle} />
          ) : pageType === "warning" ? (
            <GoAlertFill className={iconStyle} />
          ) : null}
        </Flex>
        <Flex alignItems={`center`} justifyContent={`center`}>
          <Text fontSize="2xl" color={textColor}>
            {" "}
            {resultText}!!
          </Text>
        </Flex>
        <Flex
          alignItems={`center`}
          justifyContent={`center`}
          color="special.100"
        >
          <Text textAlign={`center`} as="span">
            {resultContent}
          </Text>
        </Flex>
        {CONTENT}
        <Button
          type="button"
          variant={`brandPrimary`}
          onClick={() => {
            localStorage.clear();
            navigate(path);
            if (onClick) {
              onClick();
            }
          }}
          size="lg"
          fontWeight={`medium`}
        >
          {content}
        </Button>
      </Flex>
    </DetailWrapper>
  );
};

export default ResultPage;
