import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Text, Heading, VStack } from "@chakra-ui/react";

const Privacy = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`privacy.${key}`);
  const privacyText = [
    "We are committed to protecting the privacy of our customers and providing a secure service platform. The data collected by this website will provide you with the best possible service. We will not sell or rent your personal information to third parties. Customer's personal information is processed using SSL128 encryption technology and stored in secure, non-public operating systems. Assistance partners who may have the opportunity to access customer's personal information must also comply with our privacy and confidentiality rules.",
    "Registered users must keep their account and password secure and not allow anyone else or any third party, including minors, to use or reuse their account to access the website and place bets. This website reserves the right to refuse payment of any rewards or bonuses resulting from such use, and you will be solely responsible for all losses incurred by any third party in your account.",
    "You may receive periodic emails/text messages to update you on information and services related to this website's products. These emails/text messages can help you quickly understand and navigate when the site launches marketing activities.",
    "Our system automatically records the visitor's network IP address, which is mainly used for website traffic statistics, and then improves the user's experience on the platform.",
    "This website reserves the right to make any changes to the privacy policy on its own. Any changes to this policy are binding and effective immediately.",
  ];

  return (
    <Box
      w="full"
      mt="10px"
      rounded="5px"
      p="10px"
      py="16px"
      bg="base.light.white"
      color="base.deep.dark"
      _dark={{ bg: "base.light.dark", color: "base.light.white" }}
      mb="15px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="15px"
      fontSize="medium"
    >
      <Heading
        my="2"
        mt="2"
        color="base.light.dark"
        _dark={{ color: "base.light.white" }}
        fontSize={{ base: "xl", md: "3xl" }}
        fontWeight="semibold"
      >
        {i18n("Privacy Protection")}
      </Heading>
      <VStack
        spacing={6}
        align="start"
        p={8}
        color="base.deep.dark"
        _dark={{ color: "base.light.white" }}
        w="full"
      >
        {privacyText.map((text, index) => (
          <Text key={index} mb="6">
            {i18n(text)}
          </Text>
        ))}
      </VStack>
    </Box>
  );
};

export default Privacy;
