import { applyMiddleware, compose, createStore } from "redux";
import {
  apiErrorMessage,
  currencyExchanger,
  getBalance,
  getMemberData,
  initializeApi,
  periodicDataUpdater,
  updateEventList,
  userLogout,
} from "./middleware";
import AllReducers from "./reducers/AllReducers";

import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import history from "./history";

const routerMiddleware = createRouterMiddleware(history);

const enhancers = [];
const middleware = [
  routerMiddleware,
  apiErrorMessage,
  periodicDataUpdater,
  initializeApi,
  getMemberData,
  getBalance,
  userLogout,
  currencyExchanger,
  updateEventList,
];

if (
  // process.env.REACT_APP_SECRET_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(AllReducers, composedEnhancers);

export default store;
