import { Button, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoLogoGameControllerB } from "react-icons/io";
import { useNavigate } from "react-router";
import { COMMON_WEB_PATH } from "../../constant";

const NotFound = ({ hiddenButton, customHint, icon }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`notFound.${key}`);

  const titleColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();
  return (
    <Flex
      direction={`column`}
      alignItems={`center`}
      justifyContent={`center`}
      py="20px"
      width="100%"
    >
      <Image src={`${COMMON_WEB_PATH}/ui-image/notfound.avif`} w="220px" />
      <Text
        fontWeight={`medium`}
        mb="10px"
        fontSize="16px"
        color={`brand.500`}
        letterSpacing={`tight`}
      >
        {/* {i18n("nothingFound")} */}
        Oops! No data yet!
      </Text>
      {/* {icon && (
        <Text className="text-[50px]  text-transaction-text dark:text-base-light-white mb-[10px]">
          {icon}
        </Text>
      )}
      <Text
        fontWeight={`medium`}
        textAlign={`center`}
        color={titleColor}
        letterSpacing={`tight`}
      >
        {customHint ? customHint : i18n("transactionHint")}
      </Text> */}
      {/* {hiddenButton || (
        <Button
          leftIcon={<IoLogoGameControllerB />}
          variant={`brandPrimary`}
          borderRadius={`full`}
          type="button"
          size={`lg`}
          onClick={() => navigate("/casino")}
        >
          {i18n("placeBetHint")}
        </Button>
      )} */}
    </Flex>
  );
};

export default NotFound;
