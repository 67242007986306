import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useCookiesUacName = () => {
  const [cookiesFromUac, setCookiesFromUac] = useState(
    Cookies.get("uac") || ""
  );

  let [searchParams, setSearchParams] = useSearchParams();

  const searchUac = searchParams.get("uac") || "";

  useEffect(() => {
    if (searchUac) {
      setCookiesFromUac(searchUac);
    }
  }, [searchUac]);
  return cookiesFromUac;
};

export default useCookiesUacName;
