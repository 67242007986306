import {
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopTab from "../Home/components/topTab";
import { useTranslation } from "react-i18next";
import { IoNewspaper, IoSettingsSharp } from "react-icons/io5";
import {
  MdCasino,
  MdEventAvailable,
  MdMore,
  MdSportsBasketball,
} from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { getNewsList } from "../../api/getApi";
import NotFound from "../../components/ProgressBlock/NotFound";
import i18next from "i18next";
import Paginator from "../Transaction/components/paginator";
import { Link, useSearchParams } from "react-router-dom";
import LoadingNews from "../../components/Loading/LoadingNews";
import { appName } from "../../config";
import { FaBitcoin } from "react-icons/fa";
import { languageMapping } from "../../i18n/config";

export const news_type_list = [
  {
    label: "system-fix",
    value: "crypto",
    bg: "purple.500",
    icon: FaBitcoin,
  },
  {
    label: "event-notice",
    value: "guide",
    bg: "orange.500",
    icon: MdEventAvailable,
  },
  {
    label: "system-update",
    value: "news",
    bg: "blue.500",
    icon: IoNewspaper,
  },
  {
    label: "important-notice",
    value: "sports",
    bg: "red.500",
    icon: MdSportsBasketball,
  },
  {
    label: "service-notice",
    value: "casino",
    bg: "green.500",
    icon: MdCasino,
  },
  {
    label: "black-list",
    value: "other",
    bg: "yellow.500",
    icon: MdMore,
  },
];

const Blog = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`blog.${key}`, { ...props });

  let [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");
  const page = searchParams.get("page");

  const textColor = useColorModeValue("dark.100", "light.100");
  const tagColor = useColorModeValue("light.100", "light.100");
  const bgColor = useColorModeValue("light.200", "dark.100");
  const bgHoverColor = useColorModeValue("light.300", "dark.300");

  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    from: null,
    to: null,
    total: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    getNewsList({
      paramsData: {
        page: page,
        type: type,
      },
    })
      .then((res) => {
        const { current_page, last_page, from, to, total, data } = res?.data;
        setPagination({
          current_page,
          last_page,
          from,
          to,
          total,
        });

        const response = res?.data?.data;
        const process_response = response.map((item, index) => {
          const { news_language, start_time, end_time, uid, type, route_link } =
            item;
          const find_language =
            news_language?.find((item) => {
              return item.language === languageMapping(i18next.language);
            }) || news_language[0];
          return {
            ...find_language,
            start_time,
            end_time,
            uid,
            type,
            route_link,
          };
        });
        setNewsList(process_response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [i18next, page, type]);

  return (
    <Box as="main">
      <Box
        as="section"
        display={{
          base: "block",
          md: "none",
        }}
      >
        <TopTab />
      </Box>
      <Box as="section" px="common-x" py="common-y">
        <Text as="h4" fontSize="2xl" color={textColor} fontWeight={`semibold`}>
          {i18n("title")}
        </Text>
        <Grid gap="20px" mt="16px" templateColumns="repeat(3, minmax(0, 1fr))">
          {news_type_list.map((item, index) => {
            return (
              <Box
                bg={bgColor}
                outline={type === item.value && "3px solid"}
                outlineColor={type === item.value && "brand.500"}
                outlineOffset={`-2px`}
                _hover={{
                  bg: bgHoverColor,
                }}
                className="transition duration-300"
                display="flex"
                gap="0px"
                borderRadius={`common`}
                overflow="hidden"
                cursor={`pointer`}
                role="group"
                onClick={() => {
                  setSearchParams({ page: 1, type: item?.value });
                }}
              >
                <Flex
                  alignItems={`center`}
                  justifyContent={`center`}
                  direction={`column`}
                  gap="10px"
                  bg={item.bg}
                  minW={{
                    base: "100%",
                    md: "120px",
                  }}
                  maxW={{
                    base: "100%",
                    md: "120px",
                  }}
                  minH={{
                    base: "100%",
                    md: "120px",
                  }}
                  maxH={{
                    base: "100%",
                    md: "120px",
                  }}
                  py="15px"
                  px="15px"
                >
                  <Icon as={item.icon} fontSize="45px" color="white" />
                  <Text
                    fontSize="14px"
                    display={{
                      base: "block",
                      md: "none",
                    }}
                    className="text-overflow"
                    color={textColor}
                  >
                    {i18n(item.label, { casino: appName })}
                  </Text>
                </Flex>
                <Box
                  h="100%"
                  display={{
                    base: "none",
                    md: "flex",
                  }}
                  alignItems={`center`}
                  justifyContent={`space-between`}
                  py="15px"
                  px="15px"
                  w="100%"
                >
                  <Text color={textColor}>
                    {i18n(item.label, { casino: appName })}
                  </Text>
                  <Icon
                    _groupHover={{
                      transform: "translateX(5px)",
                    }}
                    className="transition duration-300"
                    as={IoIosArrowForward}
                    fontSize="xl"
                  />
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Divider my="15px" />
        <Flex direction={`column`} gap="10px">
          <LoadingNews commonLoading={isLoading}>
            {newsList?.length === 0 ? (
              <NotFound />
            ) : (
              <Grid
                gap="15px"
                mt="16px"
                templateColumns="repeat(1, minmax(0, 1fr))"
              >
                {newsList.map((item, index) => {
                  const find_color = news_type_list?.find((news) => {
                    return news?.value === item?.type;
                  });
                  const { bg, label } = find_color || {};
                  return (
                    <Link
                      to={`/blog/detail${
                        `/${item.route_link}` || `/${item.uid}`
                      }`}
                    >
                      <Box
                        bg={bgColor}
                        _hover={{
                          bg: bgHoverColor,
                        }}
                        className="transition duration-300"
                        display="flex"
                        gap="10px"
                        borderRadius={`common`}
                        overflow="hidden"
                        cursor={`pointer`}
                        alignItems={`center`}
                        role="group"
                        py="20px"
                        px="15px"
                      >
                        <Box
                          borderRadius={`5px`}
                          py="3px"
                          px="10px"
                          minW="80px"
                          maxW="80px"
                          fontSize="xs"
                          display={`flex`}
                          justifyContent={`center`}
                          bg={bg}
                          h="25px"
                        >
                          <Text color={tagColor} className="text-overflow">
                            {i18n(label, { casino: appName })}
                          </Text>
                        </Box>
                        <Text
                          fontSize={{
                            base: "sm",
                            md: "md",
                          }}
                          className="text-overflow"
                        >
                          {item.title}
                        </Text>
                        <Icon
                          _groupHover={{
                            transform: "translateX(5px)",
                          }}
                          className="transition duration-300"
                          as={IoIosArrowForward}
                          fontSize="xl"
                        />
                      </Box>
                    </Link>
                  );
                })}
              </Grid>
            )}
          </LoadingNews>
        </Flex>
        <Divider my="10px" />
        <Box>
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagination={pagination}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Blog;
