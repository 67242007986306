import React, { useEffect, useState } from "react";
import { getBetRecord, getPlayerBetLog } from "../../../api/getApi";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import NotFound from "../../../components/ProgressBlock/NotFound";
import { formatNumber } from "../../../utils/formatNumber";
import Record from "./Record";
import { waitTime } from "../../../utils/waitTime";
import useRenameMultiPlatform from "../../../hook/useRenameMultiPlatform";

const TotalBet = ({ datePicker, platformSelect }) => {
  const [gameRecord, setGameRecord] = useState([]);
  const [gameOriginRecord, setGameOriginRecord] = useState({}); //這個是遊戲紀錄的state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); //這個是loading的state
      await getPlayerBetLog({
        //這個是獲取遊戲紀錄的api
        paramsData: {
          str_date: datePicker.str_date,
          end_date: datePicker.end_date,
          platform: platformSelect === "All" ? "" : platformSelect,
          limit: 25,
        }, //這些日期從datePicker中獲取
      }).then((data) => {
        const origin_res = data.data;
        const res = Object.keys(data.data);
        const filter_zero_bet = res?.filter((item) => {
          return origin_res?.[item]?.totalBet !== 0;
        });

        setGameOriginRecord(data?.data);
        setGameRecord(
          filter_zero_bet?.map((plat) => {
            return {
              ...origin_res[plat],
              platform: plat,
            };
          })
        );
      });
      await waitTime(1000);
      setIsLoading(false);
    };
    fetchData();
  }, [datePicker, platformSelect]); //useEffect第一个参数是一个回调函数，该函数包含了在组件渲染后执行的代码。第二个参数是一个数组，包含了影响此 useEffect 的依赖项。在这里，useEffect 会在 datePicker 对象发生变化时执行。

  const process_game_record = useRenameMultiPlatform({
    origin_data: gameRecord,
  });

  return (
    <LoadingBetRecord commonLoading={isLoading}>
      {process_game_record?.length === 0 ? (
        <NotFound />
      ) : (
        process_game_record?.map((item) => {
          const { platform, totalBet, totalWinOrLose } = item;
          return (
            <Record
              gameName={platform}
              jackpotWin={0}
              bet={totalBet}
              winLose={totalWinOrLose}
              index={platform}
            />
          );
        })
      )}
    </LoadingBetRecord>
  );
};

export default TotalBet;
