import { useNavigate } from "react-router";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { IoCloseOutline, IoSearch } from "react-icons/io5";
import { useEffect, useMemo, useState } from "react";
import GameCatalog from "../../components/GameCatalog";
import { useDispatch, useSelector } from "react-redux";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import NotFound from "../../components/ProgressBlock/NotFound";
import LazyImage from "../../components/Loading/lazyImage";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import { COMMON_WEB_PATH } from "../../constant";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import useDeviceType from "../../hook/useDeviceType";
import { storeGameConfig } from "../../redux/action/game/gameAction";
import { CloseMenu, notice } from "../../redux/action/action";
import FavoriteGameCatalog from "../../components/FavoriteGameCatalog";
import Cookies from "js-cookie";
import {
  Box,
  CloseButton,
  Flex,
  Grid,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import useCheckLogin from "../../hook/useCheckLogin";
import GameCatalogV2 from "../../components/GameCatalog_v2";
import FavoriteGameCatalogV2 from "../../components/FavoriteGameCatalog_v2";
import { popFromBottom } from "../../animateConstant";
import { Link } from "react-router-dom";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SearchV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const navigate = useNavigate();

  const isMobile = useDeviceType() === "Mobile";

  const boxBg = useColorModeValue("light.200", "dark.200");
  const baseBg = useColorModeValue("light.100", "dark.100");
  const boxHoverBg = useColorModeValue("light.300", "dark.200");
  const textColor = useColorModeValue("dark.100", "light.100");

  const GAMELIST = useSelector((state) => state.isGameList);
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const dispatch = useDispatch();
  const {
    newGameList,
    topGameList,
    randomlySelectedGames,
    allGames,
    allGamePlatform,
  } = handleAllGame;
  const allPlatform = useSelector(
    (state) => state.isHandleAllGame.allGamePlatform
  );

  const [imageLoaded, setImageLoaded] = useState(false);

  const { isLogin } = useCheckLogin();

  const handleInput = (e) => {
    setSearchLoading(true);
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search) {
      setTimeout(() => {
        setSearchLoading(false);
      }, 1000);
    } else {
      setSearchLoading(false);
    }
  }, [search]);

  const gamesGroup = useMemo(() => {
    if (search) {
      return allGames.filter((item) => {
        return item?.[`gameName${languageTransfer({ lang: i18next.language })}`]
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
  }, [search, i18next.language]);

  return (
    <CommonOpacityBackground
      className={"lg:backdrop-blur-none backdrop-blur-md pt-[200px]"}
      path={-1}
    >
      <ChakraBox
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...popFromBottom}
        // 0A0D0F
        minH={{
          md: "248px",
        }}
        maxH={{
          md: "calc(100vh-92px)",
        }}
        bg={baseBg}
        w={{
          base: "100%",
          md: "500px",
        }}
        h={{
          base: "100%",
        }}
        borderTopRadius={`24px`}
        borderBottomRadius={{
          md: `24px`,
        }}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        gap="15px"
        px="20px"
      >
        <Box pt="20px" display="flex" justifyContent={`space-between`}>
          <Text fontSize="20px" fontWeight={`medium`}>
            {i18n("Search")}
          </Text>
          <Link to={-1}>
            <Icon cursor={`pointer`} fontSize="30px" as={IoCloseOutline} />
          </Link>
        </Box>
        <Box as="section">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={IoSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              variant={`brandPrimary`}
              onChange={handleInput}
              placeholder={i18n("Search")}
            />
          </InputGroup>
        </Box>
        <Box
          as="section"
          overflowY={`scroll`}
          py="15px"
          className="no-scrollbar"
        >
          <LoadingBlock commonLoading={searchLoading}>
            {search ? (
              <>
                <Flex direction={`column`} gap="15px">
                  <Text
                    as="span"
                    fontSize={`md`}
                    fontWeight={`medium`}
                    color="special.1"
                  >
                    {i18n("Games")}
                  </Text>
                  <Box as="section" w="100%">
                    {gamesGroup.length === 0 ? (
                      <NotFound />
                    ) : (
                      <Grid
                        templateColumns="repeat(1, minmax(0, 1fr))"
                        gap="10px"
                      >
                        {gamesGroup.map((game, index) => {
                          const gameName =
                            game?.[
                              `gameName${languageTransfer({
                                lang: i18next.language,
                              })}`
                            ];
                          const gameCode = game?.gameCode;
                          const gamePlatform = game?.game_platform;
                          const gameSubType = game?.game_subtype;
                          const SubPlatformEnum = game?.SubPlatformEnum;
                          const platformConfig = allGamePlatform.find(
                            (item) => item.platform === gamePlatform
                          );
                          const platformIsMaintain =
                            platformConfig?.isUnderMaintenance === true;
                          return (
                            <Box
                              as="section"
                              onClick={() => {
                                if (!isLogin) {
                                  dispatch(
                                    notice(
                                      i18n("Please login first"),
                                      "error",
                                      15
                                    )
                                  );
                                  navigate("/signin");
                                  return;
                                }
                                if (platformIsMaintain) {
                                  return;
                                }
                                dispatch(storeGameConfig(game));
                                dispatch(CloseMenu());

                                if (isMobile) {
                                  navigate(
                                    `game-confirm/${gameName}/${gamePlatform}/${game.gameUid}`
                                  );
                                } else {
                                  navigate(
                                    `/gamebox/${gameName}/${gamePlatform}/${game.gameUid}`
                                  );
                                }
                              }}
                              bg={boxBg}
                              position="relative"
                              overflow="hidden"
                              _hover={{
                                bg: boxHoverBg,
                              }}
                              boxShadow="sm"
                              cursor={`pointer`}
                              borderRadius={`common`}
                              p="15px"
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap="15px"
                            >
                              {platformIsMaintain && (
                                <div className="absolute bg-[rgb(0,0,0,0.8)] z-[1] top-0 left-0 w-full h-full flex flex-col items-center justify-center">
                                  <span>{i18n("platformMaintain")}</span>
                                  <span className="text-sm tracking-tighter">
                                    {platformConfig?.maintenanceStartTime ||
                                      "-"}{" "}
                                    ~{platformConfig?.maintenanceEndTime || "-"}
                                  </span>
                                </div>
                              )}
                              <Skeleton isLoaded={imageLoaded}>
                                <Image
                                  onLoad={() => {
                                    setImageLoaded(true);
                                  }}
                                  w="100px"
                                  h="100px"
                                  src={`${COMMON_WEB_PATH}/game-icon-v2/en/${gamePlatform}/${gameCode}.webp`}
                                />
                              </Skeleton>
                              <Flex
                                direction={`column`}
                                gap="5px"
                                className="flex flex-col gap-[5px]"
                              >
                                <Flex alignItems={`center`} fontWeight={`bold`}>
                                  {gameName.split("").map((item) => {
                                    return (
                                      <Text
                                        as="span"
                                        fontSize="16px"
                                        color={
                                          search
                                            ?.toLowerCase()
                                            ?.includes(item.toLowerCase())
                                            ? "brand.500"
                                            : textColor
                                        }
                                      >
                                        {item === " " ? <>&nbsp;</> : item}
                                      </Text>
                                    );
                                  })}
                                </Flex>
                                <Flex>
                                  <Text as="span" color={textColor}>
                                    {SubPlatformEnum || gamePlatform} -{" "}
                                    {i18n(gameSubType)}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Box>
                          );
                        })}
                      </Grid>
                    )}
                  </Box>
                </Flex>
              </>
            ) : (
              <>
                <FavoriteGameCatalogV2
                  wrapperClassName={`!px-[0px]`}
                  swiperProps={{
                    customPerView: "confirm-layout",
                  }}
                />

                <GameCatalogV2
                  wrapperClassName={`!px-[0px]`}
                  list={topGameList}
                  mainTitle={`Top`}
                  swiperProps={{
                    customPerView: "confirm-layout",
                  }}
                />
                <GameCatalogV2
                  wrapperClassName={`!px-[0px]`}
                  list={newGameList}
                  mainTitle={`News`}
                  swiperProps={{
                    customPerView: "confirm-layout",
                  }}
                />
              </>
            )}
          </LoadingBlock>
        </Box>
      </ChakraBox>
    </CommonOpacityBackground>
  );
};

export default SearchV2;
