import { useEffect, useMemo, useState } from "react";
import WithdrawStepOne from "./WithdrawStepOne";
import { useSelector } from "react-redux";
import GetParams from "../../utils/getParams";
import { useLocation, useNavigate } from "react-router";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import DetailWrapper from "../../components/detailWrapper";
import { useTranslation } from "react-i18next";
import PleaseSetUserIDCard from "./pleaseSetUserIDCard";
import useSpecPath from "../../hook/useSpecPath";
import WithdrawNotice from "./components/withdrawNotice";
import { getDepositPaymentList } from "../../api/getApi";
import FromWithdrawHint from "./components/fromWithdrawHint";
import { customCryptoPassword } from "../../utils/customCryptoPassword";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { Link } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import BindCardPlease from "./components/bindCardPlease";
import FreezeWrapper from "../StatusModal/FreezeWrapper";

const Withdraw = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);
  const memberData = useSelector((state) => state.isMemberInfo);
  const { bankAccount } = memberData || {};

  const [washConfig, setWashConfig] = useState({
    washPoint: 0,
    betAmount: 0,
  });
  const method = GetParams("method");
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  const getSpecPath = useSpecPath({ specPath: "withdraw" });

  useEffect(() => {
    getDepositPaymentList().then((data) => {
      setWashConfig({
        washPoint: data.wash,
        betAmount: data.betAmount,
        unlockTime: data.wash_goods_unlock_bet_start_time,
        canWithdraw: data.canWithdraw,
      });
    });
  }, []);

  // 新增一個狀態來保存從 PaymentCurrencySettings 傳遞過來的幣別
  const [selectedCurrency, setSelectedCurrency] = useState(
    process.env.REACT_APP_DEFAULT_CURRENCY
  );

  // 處理幣別變更
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  const bound = useMemo(() => {
    if (isCurrencyExchange[selectedCurrency]?.is_crypto === 1) {
      return true;
    }
    if (!bankAccount || !bankAccount[selectedCurrency]) {
      return false;
    }
    const uid = bankAccount[selectedCurrency]?.uid;
    if (uid) {
      return true;
    } else {
      return false;
    } //判斷選的幣別selectedCurrency是否已綁定 如果有uid就是已綁定
  }, [selectedCurrency, bankAccount, isCurrencyExchange]);
  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n("withdraw")} />

      <FreezeWrapper>
        <FromWithdrawHint
          washConfig={washConfig}
          onCurrencyChange={handleCurrencyChange}
          selectedCurrency={selectedCurrency}
        />
        {!bound ? (
          <BindCardPlease selectedCurrency={selectedCurrency} />
        ) : (
          <WithdrawStepOne
            washConfig={washConfig}
            selectedCurrency={selectedCurrency}
          />
        )}
      </FreezeWrapper>

      {/* <Link to={`${getSpecPath}deposit`}>
        <Text
          textAlign={`center`}
          my="10px"
          color="special.100"
          cursor={`pointer`}
        >
          {i18n("deposit")}
        </Text>
      </Link> */}
    </DetailWrapper>
  );
};

export default Withdraw;
