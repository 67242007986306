import { HiChevronLeft } from "react-icons/hi";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import CustomInput from "../../components/InputComponents/customInput";
import { AiFillDollarCircle } from "react-icons/ai";
import { useMemo, useState } from "react";
import useCurrencyList from "../../hook/useCurrencyList";
import { FaArrowRight, FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { waitTime } from "../../utils/waitTime";
import VerifyPage from "../../components/VerifyPage";

const iconStyle = "text-gray-300";

const SendPoints = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`settings.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const isMemberInfo = useSelector((state) => state.isMemberInfo);

  const currencyList = useCurrencyList({
    dontFilterCrypto: true,
    filterStatus: true,
  });

  const navigate = useNavigate();

  const [data, setData] = useState({
    currency: "",
    memId: "",
  });
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { currency, memId } = data || {};

  const currency_balance = useMemo(() => {
    const exchange_amount = isCurrencyExchange?.[currency]?.exchange_amount;

    return exchange_amount;
  }, [isCurrencyExchange, currency]);

  const handleNextStep = async () => {
    setButtonLoading(true);
    await waitTime(1000);
    setButtonLoading(false);
    navigate("send-points-confirm");
  };

  return (
    <>
      <DetailWrapper
        closeAnimate
        wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
      >
        <CloseDetailPage title={i18n("send-points")} />
        <VerifyPage verify={isMemberInfo?.is_turnover == 1}>
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {i18n("member_pointer_transfer")}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {i18n("member_pointer_transfer_hint")}
            </AlertDescription>
          </Alert>
          <Flex mt="10px" gap="10px" direction="column">
            <CustomInput
              props={{
                name: "currency",
                label: i18n("pleaseSelectCurrency"),
                type: "select",
                value: currency,
                onChange: async (e) => {
                  setLoadingBalance(true);
                  setData({ ...data, [e.target.name]: e.target.value });
                  localStorage.setItem("currency", e.target.value);
                  await waitTime(1000);
                  setLoadingBalance(false);
                },
                options: [
                  { label: "", value: "" },
                  ...currencyList?.map((item) => ({
                    label: item,
                    value: item,
                  })),
                ],
              }}
            />
            {currency ? (
              loadingBalance ? (
                <Spinner variant={`brandPrimary`} size="sm"></Spinner>
              ) : (
                <Text color="special.gold">
                  {i18n("current_currency_balance", { currency })}
                  {currency_balance}
                </Text>
              )
            ) : null}
            <CustomInput
              props={{
                name: "memId",
                label: i18n("please_input_player_id"),
                type: "text",
                icon: <FaUserCircle className={iconStyle} />,
                value: memId,
                onChange: (e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                  localStorage.setItem("memId", e.target.value);
                },
              }}
            />
            <Divider my="10px" />
            <Button
              variant={`brandPrimary`}
              rightIcon={<FaArrowRight />}
              isDisabled={!currency || !memId}
              isLoading={buttonLoading}
              onClick={handleNextStep}
              size="md"
            >
              {i18n_common("nextStep")}
            </Button>
          </Flex>
        </VerifyPage>
      </DetailWrapper>
    </>
  );
};

export default SendPoints;
