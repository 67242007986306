import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { removeCookies } from "./removeCookies";
import { actionGoogleLogin } from "../../../api/postApi";
import useCookiesUacName from "./useCookiesUacName";
import useCookiesAgentName from "./useCookiesAgentName";
import { MD5 } from "crypto-js";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GoogleNotification = () => {
  const [user, setUser] = useState({
    name: "",
    userId: "",
    profileImg: "",
    email: "",
  });

  const referCodeParams = useCookiesUacName();
  const cookiesFromAgent = useCookiesAgentName();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    const randomNumber = Math.random().toString().slice(2, 14);
    const { name, sub, picture, email } = userObject;
    setUser({
      name,
      userId: sub,
      profileImg: picture,
      email,
    });
    actionGoogleLogin({
      user_id: sub,
      email: email,
      unlimit_code: referCodeParams,
      invited: cookiesFromAgent,
      s_auth: randomNumber,
      r_auth: MD5(`Tgs${randomNumber}mov9191Login`).toString(),
      nick_name: name,
    })
      .then((data) => {
        if (data.message === "Sign up success.") {
          removeCookies();
          actionGoogleLogin({
            user_id: sub,
            email: email,
            unlimit_code: referCodeParams,
            invited: cookiesFromAgent,
            s_auth: randomNumber,
            r_auth: MD5(`Tgs${randomNumber}mov9191Login`).toString(),
            nick_name: name,
          }).then((data) => {
            if (Cookies.get("token")) {
              Cookies.remove("token");
            }
            Cookies.set("token", data.token, { expires: 3 });
            dispatch({
              type: "INITIALIZE_API",
              navigate: navigate,
              tmpToken: data.tmpToken,
            });
            navigate("/");
          });
        } else {
          if (Cookies.get("token")) {
            Cookies.remove("token");
          }
          Cookies.set("token", data.token, { expires: 3 });
          dispatch({
            type: "INITIALIZE_API",
            navigate: navigate,
            tmpToken: data.tmpToken,
          });
          navigate("/");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window?.google?.accounts?.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_KEY,
      callback: handleResponse,
    });
    // if (Cookies.get("token") !== undefined) {
    //   window.google?.accounts?.id.cancel();
    //   return;
    // }
    // window.google.accounts.id.prompt();
  }, []);
  return <div></div>;
};

export default GoogleNotification;
