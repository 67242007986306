import { motion, useWillChange, isValidMotionProp } from "framer-motion";
import { useNavigate } from "react-router";

import { useEffect, useState } from "react";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  chakra,
  Button,
  shouldForwardProp,
  useColorModeValue,
  Icon,
  Image,
  Text,
  AbsoluteCenter,
} from "@chakra-ui/react";
import PromoCardPc from "./components/promoCard_pc";
import PromoCardMobile from "./components/promoCard_mobile";
import { Link, NavLink } from "react-router-dom";
import SigninForm from "./signinForm";
import useCheckLogin from "../../hook/useCheckLogin";
import { resetInvalidAuth } from "../../redux/action/member/memberAction";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { MdOutlineArrowBack } from "react-icons/md";
import SigninFormV2 from "./signinForm_v2";
import { popFromBottom } from "../../animateConstant";
import CreditPage from "../../utils/credit/credit_page";
import SigninFormV3 from "./signinForm_v3";
import { IoClose } from "react-icons/io5";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SigninV3 = () => {
  const { t } = useTranslation();
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const navigate = useNavigate();
  const previousPage = usePreviousPagePath();

  const baseBg = useColorModeValue("light.100", "dark.100");
  const mainBg = useColorModeValue("light.200", "dark.200");

  const dispatch = useDispatch();

  const willChange = useWillChange();

  const { isLogin } = useCheckLogin();

  useEffect(() => {
    dispatch(resetInvalidAuth());

    if (isLogin) {
      navigate("/");
    }
  }, []);

  return (
    <ChakraBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ willChange }}
      display="flex"
      flexDirection={`column`}
      justifyContent={`start`}
      alignItems="center"
      zIndex="10000"
      py={{
        base: "20vh",
        md: "20vh",
      }}
      px="10px"
      width={{
        base: "100%",
      }}
      height={{
        base: "100%",
      }}
      overflowY={`scroll`}
      position="fixed"
      top={`0px`}
      left={`0px`}
      className={` backdrop-blur-md no-scrollbar`}
      // lg:backdrop-blur-none
    >
      <Box
        width={{
          md: "400px",
          base: "100%",
        }}
        height={{
          base: "auto",
          md: "auto",
        }}
        borderRadius={{
          base: "20px",
          md: "20px",
        }}
        // borderTopRadius={{
        //   md: "none",
        // }}
        // borderBottomRadius={{
        //   md: "common",
        // }}
        shadow="md"
        bg={"brand.700"}
        borderBottom="7px solid"
        borderColor="brand.900"
        pt="20px"
        position="relative"
        p="15px"
        pb="60px"
        // overflow="hidden"
      >
        <Box
          as={Link}
          zIndex={1}
          position="absolute"
          top="-10px"
          right="-10px"
          to={"../"}
        >
          <Box
            w="46px"
            h="46px"
            borderRadius={"full"}
            bgGradient={"linear(to-r,brand.500,brand.900)"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              bgGradient={"linear(to-r,red.600,red.700)"}
              borderRadius={"full"}
              border="1px solid"
              borderColor="leaderBoard.v3-border"
            >
              <Icon as={IoClose} color="light.100" fontSize="32px" />
            </Box>
          </Box>
        </Box>
        <AbsoluteCenter
          zIndex={1}
          px="50px"
          py="10px"
          bg="#C13193"
          borderRadius={"common"}
          borderBottom="4px solid"
          borderColor="#9A1257"
          top="-0%"
          fontWeight={"900"}
          fontSize="20px"
        >
          SIGN IN
        </AbsoluteCenter>
        <Box>
          <ChakraBox
            {...popFromBottom}
            width="100%"
            py="40px"
            px="30px"
            h={{
              base: "-webkit-fit-content",
              md: "auto",
            }}
            className="no-scrollbar"
            display="flex"
            flexDirection={`column`}
            borderTopLeftRadius={`120px`}
            bg="#F6F3FD"
            borderRadius={"common"}
            borderBottom="7px solid"
            borderColor="#C3A9D4"
          >
            <SigninFormV3 />
          </ChakraBox>
        </Box>
      </Box>
    </ChakraBox>
  );
};

export default SigninV3;
