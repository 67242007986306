export const languageTransfer = ({ lang = "zh_chs" }) => {
  switch (lang) {
    case "zh_chs":
      return "Zh";
    case "vi":
      return "Vi";
    case "tw":
      return "Tw";
    case "en":
      return "En";
    default:
      return "En";
  }
};
