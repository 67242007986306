import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const LoadingLine = ({
  className,
  children,
  commonLoading,
  customInverted,
  animate,
}) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  const isDarkMode = useSelector((state) => state.isTheme === "dark");

  return (
    <SkeletonText
      variant={animate ? false : `brandPrimary`}
      isLoaded={!loadingStatus || commonLoading}
      className={className}
      fitContent
      skeletonHeight="2"
      noOfLines={1}
    >
      {/* <Flex mt="12px" h="7px" alignItems={`end`} borderRadius={`full`}> */}
      {children}
      {/* </Flex> */}
    </SkeletonText>
  );
};

export default LoadingLine;
