import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaTriangleExclamation } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { openChat } from "../../redux/action/member/memberAction";

const FreezeWrapper = ({ children, className }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);
  const i18n_gamebox = (key) => t(`gamebox.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");

  const { status } = memberData || {};

  return status == 2 || status == 4 ? (
    <>
      <Alert
        {...className}
        status="info"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle color={textColor} mt={4} mb={1} fontSize="lg">
          {i18n_common("notice_you")}!!
        </AlertTitle>
        <AlertDescription color={textColor} maxWidth="sm">
          {i18n("freezeHint")}
          <Button
            mt="15px"
            type="button"
            variant={`brandPrimary`}
            onClick={() => {
              dispatch(openChat());
            }}
            size="md"
            fontWeight={`medium`}
          >
            {i18n_gamebox("contactCustomerServiceNow")}
          </Button>
        </AlertDescription>
      </Alert>
    </>
  ) : (
    children
  );
};

export default FreezeWrapper;
