import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useCheckLogin = () => {
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const isLoaderStatus = useSelector((state) => state.isLoaderStatus);
  const { uid } = isMemberInfo || {};

  const state = useMemo(() => {
    return {
      isLogin: !!uid,
      isLoading: isLoaderStatus,
    };
  }, [isMemberInfo, isLoaderStatus]);

  return state;
};

export default useCheckLogin;
