import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const CommonConfirmModal = ({
  modalOpen,
  setModalOpen,
  onConfirm,
  onClose = () => {},
  modalProps = {
    title: "",
    content: <p></p>,
    cancelButtonText: "No",
    okButtonText: "Yes",
    buttonLoading: false,
  },
}) => {
  const { title, content, cancelButtonText, okButtonText, buttonLoading } =
    modalProps;
  return (
    <Modal
      variant={`brandPrimary`}
      closeOnOverlayClick={false}
      isOpen={modalOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>

        <ModalFooter>
          <Button
            variant={"brandPrimary"}
            isLoading={buttonLoading}
            disabled={buttonLoading}
            onClick={(e) => {
              onConfirm(e);
            }}
            mr={3}
          >
            {okButtonText}
          </Button>
          <Button
            isLoading={buttonLoading}
            disabled={buttonLoading}
            onClick={onClose}
          >
            {cancelButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommonConfirmModal;
