import { Text } from "@chakra-ui/react";
import React from "react";

const SubText = ({ children }) => {
  return (
    <Text as="h5" fontSize="md" color="special.1" fontWeight={`medium`}>
      {children}
    </Text>
  );
};

export default SubText;
