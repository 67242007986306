export const loading = () => {
  return {
    type: "loading",
  };
};
export const loadingDestroy = () => {
  return {
    type: "loadingDestroy",
  };
};
export const notice = ({ content, type, timer, title } = {}) => {
  return {
    type: "notice",
    payload: {
      title: title,
      content: content,
      type: type,
      timer: timer,
      show: true,
    },
  };
};
export const clearNotice = () => {
  return {
    type: "clearNotice",
  };
};
export const goToDetailPage = () => {
  return {
    type: "goToDetailPage",
  };
};
export const goOutDetailPage = () => {
  return {
    type: "goOutDetailPage",
  };
};
export const openMenu = () => {
  return {
    type: "openMenu",
  };
};
export const CloseMenu = () => {
  return {
    type: "CloseMenu",
  };
};
export const selectMenu = (menu) => {
  return {
    type: "selectMenu",
    payload: menu,
  };
};

export const UPDATE_DATA_PERIODICALLY = () => {
  return {
    type: "UPDATE_DATA_PERIODICALLY",
  };
};

export const INITIALIZE_API = () => {
  return {
    type: "INITIALIZE_API",
  };
};
export const setTheme = (theme) => {
  return {
    type: "setTheme",
    payload: theme,
  };
};
export const stickyLeft = () => {
  return {
    type: "stickyLeft",
  };
};

export const globalTrigger = () => {
  return {
    type: "globalTrigger",
  };
};

export const controlSubMenu = (page) => {
  return {
    type: "controlSubMenu",
    payload: page,
  };
};
