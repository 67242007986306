import React, { useMemo } from "react";

export const hiddenSelectIcon = ({ iconName, casinoType }) => {
  let style = "";
  if (casinoType === "sports" || casinoType === "lottery") {
    if (
      iconName === "Home" ||
      iconName === "AllGames" ||
      iconName === "MyFavorite" ||
      iconName === "ClickedGames" ||
      iconName === "News" ||
      iconName === "Top"
    ) {
      style = "";
    } else {
      style = "hidden";
    }
  }
  return style;
};
