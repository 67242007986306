const GameVipBarReducers = (state = false, action) => {
  switch (action.type) {
    case "openVipBar":
      return (state = true);
    case "closeVipBar":
      return (state = false);
    default:
      return state;
  }
};
export default GameVipBarReducers;
