import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { REPLACE_AIR } from "../../replace_str_constant";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../redux/action/game/gameAction";
import LazyImage from "../Loading/lazyImage";
import LoadingLine from "../Loading/LoadingLine";
import { COMMON_WEB_PATH } from "../../constant";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { CloseMenu, globalTrigger, notice } from "../../redux/action/action";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import { BsTools } from "react-icons/bs";
import { BiSolidTag } from "react-icons/bi";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Flex,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiUser3Fill } from "react-icons/ri";
import useCheckLogin from "../../hook/useCheckLogin";

const GAMEITEM = ({
  originProps,
  gameProps,
  customWidth,
  itemClass,
  specMask,
  isHot,
  itemIndex,
  forceShow,
  fullImageLayout, // 是否使用全圖模式
}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const { gameCode, platform, gameName, gameSubType } = gameProps;
  const { gameNameEn, SubPlatformEnum } = originProps;
  const isMobile = useDeviceType() === "Mobile";

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const onlinePlayer = useSelector((state) => state.isGameOnlinePlayer);
  const { game } = onlinePlayer || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const itemBg = useColorModeValue("light.100", "dark.100");
  const textBgColor = useColorModeValue("light.200", "dark.100");

  const { isLogin } = useCheckLogin();

  const { allGamePlatform = [] } = isHandleAllGame;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === "game-confirm");

    if (currentPathIndex === -1) return "";

    return `${path.slice(0, currentPathIndex).join("/")}/`;
  }, [location.pathname]);

  const currentPlayer = useMemo(() => {
    return game?.[originProps.gameUid] || 0;
  }, [game]);

  const getPlatformIsMaintain = useMemo(() => {
    const findPlatform = allGamePlatform.find(
      (item) => item.platform === platform
    );
    const { maintenanceEndTime, maintenanceStartTime } = findPlatform || {};
    const isMaintain = findPlatform?.isUnderMaintenance;
    if (isMaintain) {
      return {
        state: true,
        component: (
          <div className="bg-[rgb(0,0,0,0.7)] absolute top-0 left-0 w-full h-full z-[2] rounded-[5px] flex flex-col gap-[10px] justify-center items-center text-base-light-white">
            <BsTools className="text-4xl" />
            <div className="flex flex-col items-center justify-center text-xs font-medium">
              {maintenanceStartTime ? (
                <>
                  <span>{maintenanceStartTime}</span>
                  <span>~</span>
                  <span>{maintenanceEndTime}</span>
                </>
              ) : (
                <>
                  <span>{i18n_common("platformMaintain")}</span>
                </>
              )}
            </div>
          </div>
        ),
      };
    }
  }, [platform]);
  const handleEnterGame = useCallback(() => {
    if (!isLogin) {
      dispatch(
        notice({
          title: i18n_common("Please login first"),
          type: "error",
        })
      );
      navigate("signin");
      return;
    }
    if (getPlatformIsMaintain?.state) return;
    dispatch(storeGameConfig(originProps));
    dispatch(clearGameLink());
    dispatch(CloseMenu());
    dispatch(startGameLoading());

    const game_name = gameNameEn.replace(/ /g, "-");
    const game_platform = originProps.game_platform.replace(/ /g, "-");

    if (isMobile) {
      navigate(
        `${getPrevPath}game-confirm/${game_name}/${originProps.game_platform}/${originProps.gameUid}`
      );
    } else {
      navigate(`/gamebox/${game_name}/${game_platform}/${originProps.gameUid}`);
    }
  }, [originProps, gameNameEn]);
  return (
    <Flex
      role="group"
      direction={`column`}
      position={`relative`}
      cursor={
        getPlatformIsMaintain?.state && !forceShow ? `not-allowed` : `pointer`
      }
      bg={itemBg}
      borderRadius={`common`}
      onClick={handleEnterGame}
      boxShadow={`base`}
      my="2px"
      hover={{
        background: {
          md: getPlatformIsMaintain?.state
            ? `none`
            : specMask
            ? `rgba(0,0,0,0.1)`
            : `brand.500`,
        },
      }}
    >
      {currentPlayer > 0 && (
        <Box
          position="absolute"
          left="5px"
          top="5px"
          borderRadius={`full`}
          py="3px"
          px="10px"
          zIndex={1}
          bg={itemBg}
          color={textColor}
          display="flex"
          alignItems={`center`}
          gap="3px"
          fontSize="12px"
        >
          <Icon as={RiUser3Fill} />
          {currentPlayer}
        </Box>
      )}
      {getPlatformIsMaintain?.component}
      {isHot && itemIndex + 1 < 11 ? (
        <Box>
          <BiSolidTag className="rotate-[-90deg] text-[55px] text-[#E40A13] absolute top-[-5px] right-[-7px] z-[1]" />
          <Flex
            position={`absolute`}
            top={`0px`}
            right={`0px`}
            gap="-5px"
            zIndex={`1`}
            width={`41px`}
            height={`43px`}
            direction={`column`}
            alignItems={`center`}
            justifyContent={`center`}
            fontWeight={`bold`}
            color="white"
          >
            {" "}
            <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
              Top
            </Text>
            <Text as="span">10</Text>
          </Flex>
        </Box>
      ) : null}
      <Box
        pt={{
          base: fullImageLayout ? "130%" : "105%",
          md: fullImageLayout ? "150%" : "120%",
        }}
        position="relative"
      >
        <Box
          position="absolute"
          top="0px"
          left="0px"
          height="100%"
          width="100%"
          overflow="hidden"
          isolation={`isolate`}
        >
          <Box
            position="absolute"
            top="0px"
            left="0px"
            height="100%"
            width="100%"
            overflow="hidden"
            borderRadius={"common"}
          >
            <LazyImage
              src={`${COMMON_WEB_PATH}/game-icon-v2/en/${platform}/${REPLACE_AIR(
                gameCode
              )}.webp`}
              alt={gameNameEn}
              closeInverted
              className={`w-full h-full !object-cover ${
                specMask ? "object-scale-down" : "object-cover"
              }`}
            />
          </Box>
          <Box
            position={`absolute`}
            top="0px"
            left="0px"
            height="100%"
            width="100%"
            borderRadius={`common`}
            display={{
              base: "none",
              md: "flex",
            }}
            alignItems={`center`}
            justifyContent={`center`}
            opacity={0}
            className="transition duration-300"
            _groupHover={{
              md: {
                opacity: 1,
              },
            }}
            bg={specMask ? "rgba(0,0,0,0.6)" : "game-item.mask"}
          >
            <Button
              variant={`brandPrimary`}
              type="button"
              border={`1px solid #ECBE8C`}
              display={`flex`}
              alignItems={`center`}
              justifyContent={`center`}
              fontWeight={`bold`}
              onClick={handleEnterGame}
            >
              <Text as="span">PLAY</Text>
            </Button>
          </Box>
        </Box>
      </Box>

      {fullImageLayout || (
        <Flex
          _groupHover={{
            background: {
              md: `brand.500`,
            },
          }}
          borderBottomRadius={`common`}
          direction={`column`}
          bg={textBgColor}
          p="5px"
        >
          <LoadingLine className="max-w-[100px] min-w-[100px]">
            <Text
              _groupHover={{
                color: {
                  md: `white`,
                },
              }}
              mb="0px"
              width={`100%`}
              fontWeight={`medium`}
              color={textColor}
              className="text-overflow"
            >
              {originProps?.[
                `gameName${languageTransfer({ lang: i18next.language })}`
              ] || "-"}
            </Text>
          </LoadingLine>
          <LoadingLine className="max-w-full min-w-full">
            <Text
              fontSize={`sm`}
              fontWeight={`medium`}
              mb="0px"
              width="100%"
              _groupHover={{
                color: {
                  md: `white`,
                },
              }}
              color={`light.350`}
              className="text-overflow"
            >
              {SubPlatformEnum || platform} - {i18n_common(gameSubType)}
            </Text>
          </LoadingLine>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(GAMEITEM);
