import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { headerMenu } from "../Menu/helper/headerMenu";
import { Link, useNavigate } from "react-router-dom";
import { COMMON_WEB_PATH } from "../../constant";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openChat } from "../../redux/action/member/memberAction";
import { community_link } from "./utils/communication_link";

const BoxProps = {
  mb: "15",
  as: "h4",
  fontWeight: "semibold",
};

const parseSomething = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
};

const allow_domain_url = parseSomething(window.allow_domain_url);

const isCredit = process.env.REACT_APP_CREDIT == 1;

const top_games = [
  {
    label: "Game Lobby",
    link: "/",
  },
  {
    label: "Promotion",
    link: "/promotion",
    hidden: isCredit,
  },
  {
    label: "VIP",
    link: "profile/vip",
    hidden: isCredit,
  },
];

const TermAndInfoNew = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const { facebook, instagram, youtube, twitter, tiktok } = community_link;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const support = [
    {
      label: "Online Service",
      link: "/",
      onClick: () => {
        dispatch(openChat());
      },
      hidden: isCredit,
    },
    {
      label: "Help Center",
      link: "/policies/help-center",
      onClick: () => {
        navigate("/policies/help-center");
      },
    },
    {
      label: "About Us",
      link: "/policies/about-us",
      onClick: () => {
        navigate("/policies/about-us");
      },
    },
    {
      label: "Service",
      link: "/policies/terms-and-service",
      onClick: () => {
        navigate("/policies/terms-and-service");
        // setTimeout(() => {
        //   window.open(
        //     `${window.location.origin}/policies/Service.pdf`,
        //     "_blank"
        //   );
        // }, 0);
      },
    },
    {
      label: "Private",
      link: "/policies/privacy",
      onClick: () => {
        setTimeout(() => {
          window.open(
            `${window.location.origin}/policies/Privacy.pdf`,
            "_blank"
          );
        }, 0);
      },
    },
  ];

  return (
    <Box
      as="section"
      gap={{
        md: "20px",
        base: "40px",
      }}
      display="flex"
      flexDirection={{
        md: "row",
        base: "column",
      }}
      w="100%"
      my="40px"
      justifyContent={`space-between`}
    >
      <Grid
        templateColumns={{
          md: "repeat(3, minmax(0, 1fr))",
          base: "repeat(3, minmax(0, 1fr))",
        }}
        w="100%"
        gap={["5%", "40px"]}
      >
        <Box>
          <Text {...BoxProps}>{i18n("ENTERTAIN")}</Text>
          <Flex direction={`column`} gap="10px">
            {top_games
              ?.filter((item) => {
                return !item.hidden;
              })
              ?.map((item) => {
                return (
                  <Link to={`${item.link}`}>
                    <Text
                      as="h5"
                      cursor="pointer"
                      fontSize="sm"
                      color="special.1"
                      fontWeight={`medium`}
                      _hover={{
                        color: "brand.500",
                      }}
                    >
                      {i18n(item.label)}
                    </Text>
                  </Link>
                );
              })}
          </Flex>
        </Box>
        <Box>
          <Text {...BoxProps}>{i18n("SUPPORT")}</Text>
          <Flex direction={`column`} gap="10px">
            {support
              ?.filter((item) => {
                return !item.hidden;
              })
              ?.map((item) => {
                return (
                  <Box onClick={item.onClick}>
                    <Text
                      as="h5"
                      cursor="pointer"
                      fontSize="sm"
                      color="special.1"
                      fontWeight={`medium`}
                      _hover={{
                        color: "brand.500",
                      }}
                    >
                      {i18n(item.label)}
                    </Text>
                  </Box>
                );
              })}
          </Flex>
        </Box>
        <Box>
          <Text {...BoxProps}>{i18n("CONTACT US")}</Text>
          <Flex direction={`column`} gap="10px">
            {allow_domain_url?.map((item) => {
              return (
                <Text
                  cursor="pointer"
                  fontSize="sm"
                  color="special.1"
                  fontWeight={`medium`}
                  _hover={{
                    color: "brand.500",
                  }}
                >
                  <a href={item} target="_blank">
                    {item}
                  </a>
                </Text>
              );
            })}
          </Flex>
        </Box>
      </Grid>
      <Box>
        <Text {...BoxProps}>{i18n("JOIN US COMMUNITY")}</Text>
        <Flex flexWrap={`wrap`} columnGap={`30px`} gap={["30px", "20px"]}>
          <a href={facebook}>
            <Image
              w="40px"
              h="40px"
              src={`${COMMON_WEB_PATH}/service-group/facebook.avif`}
            />
          </a>
          <a href={instagram}>
            <Image
              w="40px"
              h="40px"
              src={`${COMMON_WEB_PATH}/service-group/instagram.avif`}
            />
          </a>
          <a href={youtube}>
            <Image
              w="40px"
              h="40px"
              src={`${COMMON_WEB_PATH}/service-group/youtube.png`}
            />
          </a>
          <a href={twitter}>
            <Image
              w="40px"
              h="40px"
              src={`${COMMON_WEB_PATH}/service-group/twitter.png`}
            />
          </a>
          <a href={tiktok}>
            <Image
              w="40px"
              h="40px"
              bg="white"
              borderRadius={`full`}
              src={`${COMMON_WEB_PATH}/service-group/tiktok.png`}
            />
          </a>
        </Flex>
      </Box>
    </Box>
  );
};

export default TermAndInfoNew;
