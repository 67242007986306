export const shuffle = (array) => {
  for (let i = array?.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getRandomSlice = (arr) => {
  const fixedLength = 30;
  const maxStartIndex = Math.max(0, arr?.length - fixedLength);

  const startIndex = Math.floor(Math.random() * (maxStartIndex + 1));

  const endIndex = startIndex + fixedLength;

  return arr?.slice(startIndex, endIndex);
};
