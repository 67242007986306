import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const brandPrimary = definePartsStyle({
  // define the part you're going to style
  field: {
    border: "1px solid",
    borderColor: "gray.300",
    borderRadius: "common",
    bg: "light.100",
    _focus: {
      borderColor: "brand.500",
    },
    _hover: {
      borderColor: "brand.500",
    },
    _dark: {
      color: "light.100",
      borderColor: "dark.300",
      bg: "dark.100",
    },
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
});
