import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

const LoadingBank = ({ commonLoading, children, className, mapIndex = 2 }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  const cardBg = useColorModeValue("light.100", "dark.100");

  return commonLoading || loadingStatus
    ? Array.from({ length: mapIndex }, (_, i) => i).map((item) => {
        return (
          <Box mb="10px">
            <Box
              display="flex"
              gap="10px"
              alignItems={`center`}
              py="4"
              px="2"
              boxShadow="lg"
              borderRadius="common"
              bg={cardBg}
            >
              <SkeletonCircle size="10" />
              <SkeletonText
                w="200px"
                noOfLines={2}
                spacing="4"
                skeletonHeight="2"
              />
            </Box>
          </Box>
        );
      })
    : children;
};

export default LoadingBank;
