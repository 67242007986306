const marqueeListReducers = (state = [], action) => {
  switch (action.type) {
    case "storeMarqueeList":
      return action.payload;
    case "clearMarqueeList":
      return (state = []);
    default:
      return state;
  }
};
export default marqueeListReducers;
